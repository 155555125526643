import React, {useEffect, useRef, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from "react-query";
import {getPageImagePdfJs, getTagType, updateTags} from "./APIcalls";
import {tableConfig} from "./Config";
import Button from "react-bootstrap/Button";
import { AiOutlineDelete} from "react-icons/ai";
import {mode, getMousePos, transformRectangle, navigation} from "./Tools";
import {changeTable} from "./StructTreeActions";
import {loadingCanvas, drawTables, drawFigures} from "./Drawing";
import {ButtonGroup, Table} from "react-bootstrap";
import {checkHeaderCells, prepareTable, fixTableDimensions} from "./utils/UtilsTables";
import Instructions from "./utils/Instructions";
import {errorDrawing, WarningNotComplete} from "./utils/ErrorMessages";
import {VscCombine} from "react-icons/vsc";
import {resizingX, resizingY, startResizeX} from "./utils/UtilsResize";
import PageView from "./PageView";

const Tables = ({pdf, pdfInfo, stepSelected, setStep, nextStep, menuSize, setMenuSize, instructionSize, setInstructionSize, showPageMenu, setShowPageMenu}) => {
    const tables = useRef([]);
    const [tableI, setTableI] = useState(-1);
    const pageNum = useRef(1);
    const [imageReady, setImageReady] = useState(true);
    const modifications = useRef([]);

    // canvas table
    const canvasRef = useRef(null);
    const ctx = useRef(null);
    const canvasDrawing = useRef(false);

    // mouse position
    const curPos = useRef(0);
    const mouseMove = useRef(null);

    // Table
    const tableDimensions = useRef({});
    const tableElementSelected = useRef(false);
    const [renderTableElementSelected, setRenderTableElementSelected] = useState(0);
    const highlightCell = useRef(false);
    const headerCells = useRef("row");

    const scaling = 2;
    const queryClient = useQueryClient();

    const viewed = useRef([true]);
    const [showWarning, setShowWarning] = useState(false);

    const [zoomFactor, setZoomFactor] = useState(1);

    // resize start values
    const startResizeValueMenu = useRef(null);
    const startResizeValueInstruction = useRef(null);

    // init canvas
    useEffect(() => {
        if (canvasRef.current) {
            ctx.current = canvasRef.current.getContext('2d');
        }
        tablesQuery.refetch();
        viewed.current[tableI] = true;
    }, []);

    // changing step
    useEffect(() => {
        if (stepSelected !== -1) {
            const checkedAll = viewed.current.every(t => t || stepSelected < 4);
            updateStep(() => {
                if (checkedAll) setStep(stepSelected);
                else setShowWarning(true);
            });
        }
    }, [stepSelected]);

    // get the tables
    const tablesQuery = useQuery({
        queryKey: ["Tables", pdfInfo.fileid],
        queryFn: () => getTagType({pdfInfo: pdfInfo, tagType: "Table", pdf: pdf}),
        staleTime: 1000 * 60 * 5, // less fetching,
        enabled: pdfInfo.fileid != null,
        placeholderData: null,  // placeholder image
        onSuccess: data => {
            tables.current = data.map(table => {
                return {
                    id: table.id,
                    rectangle: table.rectangle,
                    processedTable: fixTableDimensions(prepareTable({tableArray: [], elements: table.children, modifications: modifications.current})),
                    text: table.text,
                    type: table.type,
                    children: table.children
                }
            });
            tables.current = tables.current.filter(table => table.processedTable.length > 0);
            if (tables.current.length > 0) {
                tables.current.forEach(table => checkHeaderCells({processedTable: table.processedTable}));
                pageNum.current = tables.current[0].rectangle.page;
                if (viewed.current.length === 1) {
                    viewed.current = Array.from({length: tables.current.length}, _ => false);
                }
                viewed.current[0] = true;
                if (mode(tables.current[0].processedTable[0].map(column => column.type)) === "TH") {
                    if (mode(tables.current[0].processedTable.map(column => column[0].type)) === "TH") {
                        headerCells.current = "both";
                    }
                    else {
                        headerCells.current = "row";
                    }
                }
                else {
                    headerCells.current = "column";
                }
                setTableI(0);
            }
        }
    });

    // get the image
    const pageImage = useQuery({
        queryKey: ["image", pdfInfo.fileid, pageNum.current],
        queryFn: () => getPageImagePdfJs({pdf: pdf, pageNum: pageNum.current, setImageReady: setImageReady}),
        staleTime: 1000 * 60 * 5, // less fetching,
        enabled: pdfInfo.fileid != null && pageNum.current > -1,
        placeholderData: null,  // placeholder image
        // onSuccess: () => drawing()
    });

    // update tables
    const tablesMutation = useMutation({
        mutationFn: ({pdfInfo, modifications}) => updateTags({pdfInfo: pdfInfo, modifications: modifications})
    })

    function onMouseDown(e) {
        // do nothing during loading
        if (tablesQuery.isLoading || pageImage.isLoading) return
        canvasDrawing.current = true;
        tableElementSelected.current = null;
        curPos.current = getMousePos({e: e, canvasRef: canvasRef.current});
        mouseMove.current = {startX: curPos.current.x, startY: curPos.current.y};
    }

    function onMouseMove(e) {
        // do nothing during loading
        if (tablesQuery.isLoading || pageImage.isLoading) return
        curPos.current = getMousePos({e: e, canvasRef: canvasRef.current})
        if (canvasDrawing.current) {
            mouseMove.current.endX = curPos.current.x;
            mouseMove.current.endY = curPos.current.y;
        }
        requestAnimationFrame(drawing)
    }

    function onMouseUp(e) {
        // do nothing during loading
        if (tablesQuery.isLoading || pageImage.isLoading) return
        canvasDrawing.current = false;
        processLine();
        mouseMove.current = null;
        tableElementSelected.current = false;
        drawing();
    }

    function onMouseClick(e) {
        // do nothing during loading
        if (tablesQuery.isLoading || pageImage.isLoading) return

        // get mouse position and check if a line selected
        curPos.current = getMousePos({e: e, canvasRef: canvasRef.current})

        // check if row line
        tableDimensions.current.rowY.every((rowLine, rowI) => {
            // check if row line selected
            if (rowLine - tableConfig.lineSelectionOffset < curPos.current.y && curPos.current.y < rowLine + tableConfig.lineSelectionOffset) {
                tableElementSelected.current = {rowLine: rowI};
                return false;
            }
            // check if row element selected
            if (rowLine + tableConfig.lineSelectionOffset < curPos.current.y && curPos.current.y < rowLine - tableConfig.lineSelectionOffset) {
                tableElementSelected.current = {rowI: rowI};
                return false;
            }
            // check if row selected
            if (rowI === 0 && rowLine - 25 < curPos.current.y && curPos.current.y < rowLine - tableConfig.lineSelectionOffset) {
                tableElementSelected.current = {rowI: -1};
                return false;
            }
            return true
        })

        //check if column line
        if (tableElementSelected.current.rowLine == null) {
            //check column
            tableDimensions.current.columnX.every((columnLine, columnI) => {
                // check if row line selected
                if (columnLine - tableConfig.lineSelectionOffset < curPos.current.x && curPos.current.x < columnLine + tableConfig.lineSelectionOffset) {
                    tableElementSelected.current = {columnLine: columnI};
                    return false;
                }
                // check if column element selected
                if (tableElementSelected.current.rowI != null && columnLine + tableConfig.lineSelectionOffset < curPos.current.x && curPos.current.x < columnLine - tableConfig.lineSelectionOffset) {
                    tableElementSelected.current.columnI = columnI;
                    return false;
                }
                // check if column selected
                if (tableElementSelected.current.rowI != null && columnI === 0 && columnLine - 25 < curPos.current.x && curPos.current.x < columnLine - tableConfig.lineSelectionOffset) {
                    tableElementSelected.current.columnI = -1;
                    return false;
                }
                return true
            })
        }
        setRenderTableElementSelected(prevState => prevState + 1);
        drawing();
    }

    function processLine() {
        const dx = Math.abs(mouseMove.current.endX - mouseMove.current.startX);
        const dy = Math.abs(mouseMove.current.endY - mouseMove.current.startY);
        const tableRect = transformRectangle({rect: tables.current[tableI].rectangle, imageSize: [pageImage.data.naturalWidth, pageImage.data.naturalHeight]})
        if (dx > dy) {
            // new row
            // check that line is valid
            let rowI = null;
            for (let i = 0; i < tableDimensions.current.rowY.length - 1; i++) {
                if (tableDimensions.current.rowY[i] < mouseMove.current.startY && mouseMove.current.startY < tableDimensions.current.rowY[i + 1] && !(tableDimensions.current.rowY[i] < mouseMove.current.endY && mouseMove.current.startY < tableDimensions.current.rowY[i + 1])) {
                    console.log("not a valid line")
                    break;
                }
                if (tableDimensions.current.rowY[i] < mouseMove.current.startY && mouseMove.current.startY < tableDimensions.current.rowY[i + 1] && tableDimensions.current.rowY[i] < mouseMove.current.endY && mouseMove.current.startY < tableDimensions.current.rowY[i + 1]) {
                    rowI = i;
                    break;
                }
            }

            // add the new row
            if (rowI != null) {
                // iterate over the cells of rowI
                let moveElements = [];
                for (let columnI = 0; columnI < tables.current[tableI].processedTable[rowI].length; columnI++) {
                    moveElements.push([]);
                    // iterate over the elements in a cell
                    tables.current[tableI].processedTable[rowI][columnI].elements.forEach((element, elementI) => {
                        // determine if cell is above line or below
                        const elementRect = transformRectangle({rect: element.rectangle, imageSize: [pageImage.data.naturalWidth, pageImage.data.naturalHeight]})
                        if (elementRect.y + elementRect.h / 2 < (mouseMove.current.startY + (mouseMove.current.endY - mouseMove.current.startY) * (elementRect.x + elementRect.w / 2 - mouseMove.current.startX) / (mouseMove.current.endX - mouseMove.current.startX))) {
                            // above line
                            moveElements[columnI].push(elementI);
                        }
                    });
                }
                if (!moveElements.every((r) => r.length === 0) && !moveElements.every((c, columnI) => c.length ===  tables.current[tableI].processedTable[rowI][columnI].elements.length)) {
                    tables.current[tableI].processedTable.splice(rowI, 0, []);
                    // move the elements
                    for (let columnI = 0; columnI < tables.current[tableI].processedTable[rowI + 1].length; columnI++) {
                        tables.current[tableI].processedTable[rowI][columnI] = {type: "TD", elements: []};
                        moveElements[columnI].forEach((moveI, i) => {
                            tables.current[tableI].processedTable[rowI][columnI].elements.push(tables.current[tableI].processedTable[rowI + 1][columnI].elements[moveI - i]);
                            tables.current[tableI].processedTable[rowI + 1][columnI].elements.splice(moveI - i, 1);
                        });
                    }
                }
            }

        }
        else {
            // new column
            // check that line is valid
            let columnI = null;
            for (let i = 0; i < tableDimensions.current.columnX.length - 1; i++) {
                if (tableDimensions.current.columnX[i] < mouseMove.current.startX && mouseMove.current.startX < tableDimensions.current.columnX[i + 1] && !(tableDimensions.current.columnX[i] < mouseMove.current.endX && mouseMove.current.startX < tableDimensions.current.columnX[i + 1])) {
                    console.log("not a valid line")
                    break;
                }
                if (tableDimensions.current.columnX[i] < mouseMove.current.startX && mouseMove.current.startX < tableDimensions.current.columnX[i + 1] && tableDimensions.current.columnX[i] < mouseMove.current.endX && mouseMove.current.startX < tableDimensions.current.columnX[i + 1]) {
                    columnI = i;
                    break;
                }
            }

            // add the new column
            if (columnI != null) {
                let moveElements = [];
                tables.current[tableI].processedTable.forEach((row, rowI) => {
                    moveElements.push([])
                    // iterate over the elements in a cell
                    tables.current[tableI].processedTable[rowI][columnI].elements.forEach((element, elementI) => {
                        // determine if cell is right or left of line
                        const elementRect = transformRectangle({rect: element.rectangle, imageSize: [pageImage.data.naturalWidth, pageImage.data.naturalHeight]})
                        if (elementRect.x + elementRect.w / 2 < (mouseMove.current.startX + (mouseMove.current.endX - mouseMove.current.startX) * (elementRect.y + elementRect.h / 2 - mouseMove.current.startY) / (mouseMove.current.endY - mouseMove.current.startY))) {
                            // above line
                            moveElements[rowI].push(elementI);
                        }
                    })
                })
                if (!moveElements.every((r) => r.length === 0) && !moveElements.every((r, rowI) => r.length ===  tables.current[tableI].processedTable[rowI][columnI].elements.length)) {
                    // move the elements
                    tables.current[tableI].processedTable.forEach((row, rowI) => {
                        row.splice(columnI, 0, []);
                        tables.current[tableI].processedTable[rowI][columnI] = {type: "TD", elements: []};
                        moveElements[rowI].forEach((moveI, i) => {
                            tables.current[tableI].processedTable[rowI][columnI].elements.push(tables.current[tableI].processedTable[rowI][columnI + 1].elements[moveI - i]);
                            tables.current[tableI].processedTable[rowI][columnI + 1].elements.splice(moveI - i, 1);
                        });
                    });
                }
            }

        }
        drawing();
        changeTableHeader({processedTable: tables.current[tableI].processedTable});
    }

    function changeTableHeader({processedTable}) {
        processedTable.forEach((row, rowI) => {
            row.forEach((column, columnI) => {
                if ((rowI === 0 && (headerCells.current === "row" || headerCells.current === "both")) ||
                    (columnI === 0 && (headerCells.current === "column" || headerCells.current === "both"))) {
                    column.type = "TH";
                }
                else {
                    column.type = "TD";
                }
            })
        })
        drawing();
        setRenderTableElementSelected(prevState => prevState + 1);
    }

    /**
     * Helper function for updating the step
     */
    function updateStep(onSuccessFunction) {
        if (tables.current) {
            tables.current.forEach(table => {
                if (table.processedTable != null) {
                    modifications.current.push(changeTable({
                        id: table.id,
                        rows: table.processedTable.length,
                        columns: table.processedTable[0].length,
                        cellIds: [].concat.apply([], table.processedTable.map(row => {
                            return row.map(cell => {
                                return [cell.elements.map(e => e.id)];
                            });
                        })),
                        cellTypes: [].concat.apply([], table.processedTable.map(row => {
                            return row.map(cell => {
                                return cell.type;
                            })
                        }))
                    }))
                }
            })
        }
        return tablesMutation.mutate({pdfInfo: pdfInfo, modifications: modifications.current}, {
            onSuccess: () => {
                queryClient.invalidateQueries(["Tables", pdfInfo.fileid]).then(() => {
                    if (onSuccessFunction != null) {
                        onSuccessFunction();
                    }
                });
            }
        });
    }


    /**
     * helper function for drawing
     */
    function drawing() {
        if (!tablesQuery.isLoading && tables.current.length > 0 && imageReady && ctx.current != null && pageImage.data != null) {
            try {
                canvasRef.current.height = pageImage.data.naturalHeight;
                canvasRef.current.width = pageImage.data.naturalWidth;
                tableDimensions.current = drawTables({
                    ctx: ctx.current,
                    image: pageImage.data,
                    table: tables.current[tableI],
                    tableElementSelected: tableElementSelected.current,
                    mouseMove: mouseMove.current,
                    highlightCell: highlightCell.current
                });
            }
            catch (e) {
                errorDrawing({errorMessage: e});
            }
        }
        else if (tablesQuery.isLoading || pageImage.isLoading) {
            loadingCanvas({ctx: ctx.current, canvasRef: canvasRef.current});
        }
        else if (imageReady && ctx.current != null && pageImage.data != null) {
            drawFigures({ctx: ctx.current, figure: null, image: pageImage.data});
        }
    }
    drawing();

    function changeTableI(i) {
        if (tableI !== i) {
            pageNum.current = tables.current[i].rectangle.page;
            if (mode(tables.current[0].processedTable[0].map(column => column.type)) === "TH") {
                if (mode(tables.current[0].processedTable.map(column => column[0].type)) === "TH") {
                    headerCells.current = "both";
                }
                else {
                    headerCells.current = "row";
                }
            }
            else {
                headerCells.current = "column";
            }
            setTableI(i);
        }
    }

    function deleteLine() {
        if (tableElementSelected.current.rowLine != null) {
            tables.current[tableI].processedTable[tableElementSelected.current.rowLine - 1].forEach((column, columnI) => {
                column.elements = column.elements.concat(tables.current[tableI].processedTable[tableElementSelected.current.rowLine][columnI].elements);
            })
            tables.current[tableI].processedTable.splice(tableElementSelected.current.rowLine, 1);
        }
        if (tableElementSelected.current.columnLine != null) {
            tables.current[tableI].processedTable.forEach(row => {
                row[tableElementSelected.current.columnLine - 1].elements = row[tableElementSelected.current.columnLine - 1].elements.concat(row[tableElementSelected.current.columnLine].elements);
                row.splice(tableElementSelected.current.columnLine, 1);
            })
        }
        tableElementSelected.current = false;
        setRenderTableElementSelected(prevState => prevState + 1);
        changeTableHeader({processedTable: tables.current[tableI].processedTable});
    }

    function resetTable() {
        const tableElements = tables.current[tableI].processedTable.map(row => row.map(column => column.elements));
        tables.current[tableI].processedTable = [[{elements: tableElements.flat(2), type: "TD"}]];
        changeTableHeader({processedTable: tables.current[tableI].processedTable});
    }

    function tableAsHtml({table}) {
        return <Table>
            <tbody>
                <tr key={-1}>
                    <td id="borderCell">
                        <div></div>
                    </td>
                    {table[0].map((column, columnI) => {
                        return <td key={[-1, columnI].toString()} id="borderCell">
                            <div>{String.fromCharCode(65 + columnI)}</div>
                        </td>
                    })}
                </tr>
                {table.map((row, rowI) => {
                    return <tr key={rowI}>
                        <td key={[rowI, -1].toString()} id="borderCell">
                            <div>{rowI + 1}</div>
                        </td>
                        {row.map( (column, columnI) => {
                            if (column.type === "TH") {
                                return <th key={[rowI, columnI].toString()} id="tableCell">
                                    <div
                                         onMouseEnter={() => {
                                             highlightCell.current = [rowI, columnI];
                                             requestAnimationFrame(drawing);
                                         }}
                                         onMouseLeave={() => {
                                             highlightCell.current = false;
                                             requestAnimationFrame(drawing);
                                         }}>{column.elements.map(e => e.text)}</div>
                                </th>
                            }
                            else {
                                return <td key={[rowI, columnI].toString()} id="tableCell">
                                    <div onMouseEnter={() => {
                                        highlightCell.current = [rowI, columnI];
                                        requestAnimationFrame(drawing);
                                    }}
                                         onMouseLeave={() => {
                                             highlightCell.current = false;
                                             requestAnimationFrame(drawing);
                                         }}>{column.elements.map(e => e.text)}</div>
                                </td>
                            }
                        })}
                    </tr>
                })}
            </tbody>
        </Table>
    }

    return (
        <div className="d-flex flex-row flex-fill" id="tables"
             onMouseUp={() => startResizeValueMenu.current = null}
             onMouseMove={(e) => resizingX(e, startResizeValueMenu.current, setMenuSize)}
             style={startResizeValueMenu.current != null ? {userSelect: 'none'}: null}>
            <WarningNotComplete
                showWarning={showWarning} setShowWarning={setShowWarning}
                titleMessage={"Have you checked all tables?"}
                bodyMessage={<>It seems that you have not checked the following tables: {viewed.current.map((e, ei) => e ? null : ei + 1).filter(e => e != null).join(", ")} <br /> Do you want to check them before you continue?</>}
                currentTask={4}
                setTask={setStep}
                taskSelected={stepSelected}/>
            <div className="d-flex flex-column" id="menu" style={{width: menuSize + "px"}}
                 onMouseUp={() => startResizeValueInstruction.current = null}
                 onMouseMove={(e) => resizingY(e, startResizeValueInstruction.current, setInstructionSize)}>
                <div id="resizeHandleX" onMouseDown={(e) => startResizeValueMenu.current = startResizeX(e)}></div>
                <Instructions
                    title="Step 4: Define Table Structure"
                    bodyText="In this step, you can define the table structure for all tables in your document.
                    The table below illustrates the assigned table structure of the current table.
                    You can draw lines on the page view to separate rows and columns or select them to delete them.
                    Additionally, define whether the first row and/or first column of the table are table headings."
                    buttons={[
                        ["Combine Rows/Columns", "Select a line in the page view and select Combine Rows/Columns to delete the line and combine the two rows/columns."],
                        ["Delete All Table Cells", "Remove all rows and columns."]
                    ]}
                    hints={[["Highlight Cell", "Hover over a cell in the table below to highlight it in the page view."]]}
                    nextStep={nextStep}
                    instructionSize={instructionSize}
                    startResizeValueInstruction={startResizeValueInstruction}
                />
                {navigation({
                    callback: changeTableI,
                    length: tables.current.length,
                    i: tableI,
                    title: "Tables",
                    viewed: viewed.current
                })}
                <div id="actionButtons" className="d-flex flex-row justify-content-evenly">
                    <Button variant="light" onClick={deleteLine}
                            disabled={!(tableElementSelected.current != null && (tableElementSelected.current.rowLine > 0 || tableElementSelected.current.columnLine > 0))}>
                        <VscCombine size="1.5em"/> <br/>
                        Combine Rows/Columns
                    </Button>
                    <Button variant="light" onClick={resetTable}>
                        <AiOutlineDelete size="1.5em"/> <br/>
                        Delete All Table Cells
                    </Button>
                </div>
                <div className="d-flex flex-column justify-content-center" id="tableView">
                    <h3>{tables.current.length > 0 ? <>Table {tableI + 1}/{tables.current.length}</> : "No table found in the document"}</h3>
                    {tables.current.length > 0 ? <div id="headingCells">
                        {"Heading Cells:  "}
                        <ButtonGroup>
                            <Button active={headerCells.current === "row"} onClick={() => {
                                headerCells.current = "row";
                                changeTableHeader(tables.current[tableI]);
                            }} variant="light">First Row</Button>
                            <Button active={headerCells.current === "column"} onClick={() => {
                                headerCells.current = "column";
                                changeTableHeader(tables.current[tableI]);
                            }} variant="light">First Column</Button>
                            <Button active={headerCells.current === "both"} onClick={() => {
                                headerCells.current = "both";
                                changeTableHeader(tables.current[tableI]);
                            }} variant="light">Both</Button>
                        </ButtonGroup>
                        </div> : null}
                    <div id="tableAsHtml">
                        {tables.current.length > 0 ? tableAsHtml({table: tables.current[tableI].processedTable}) : null}
                    </div>
                </div>
            </div>
            <PageView
                pageNum={pageNum.current}
                canvasRef={canvasRef}
                onMouseClick={onMouseClick}
                onMouseDown={onMouseDown}
                onMouseMove={onMouseMove}
                onMouseUp={onMouseUp}
                zoomFactor={zoomFactor}
                setZoomFactor={setZoomFactor}
                isLoading={tablesQuery.isLoading || pageImage.isLoading}
                showPageMenu={showPageMenu}
                setShowPageMenu={setShowPageMenu}
                pdf={pdf}
                pdfInfo={pdfInfo}
                updateStep={updateStep}
            />
        </div>
    );
};

export default Tables;
