const colorPlate = [
    '#0000FF',
    '#008000',
    '#FFA500',
    '#800080',
    '#00FFFF',
    '#FF00FF',
    '#333333',
    '#DAA520',
    '#FF69B4',
    '#008080',
    '#800000',
    '#FFFF00',
];


const imageScaling = 2;
const font = "30px Arial";

const bboxLineWidth = 3 * imageScaling;

const colors = {
    'H': colorPlate[0], 'H1': colorPlate[0], 'H2': colorPlate[0], 'H3': colorPlate[0], 'H4': colorPlate[0], 'H5': colorPlate[0], 'H6': colorPlate[0],
    'P': colorPlate[1],
    'Figure': colorPlate[2],
    'Formula': colorPlate[3],
    'Table': colorPlate[4],
    'L': colorPlate[5],
    'Artifact': colorPlate[6],
    'Caption': colorPlate[7],
    'Form': colorPlate[8],
    'Link': colorPlate[9],
    'Reference': colorPlate[10],
    'Not defined': colorPlate[11]
};

function addAlpha(color, opacity) {
    // coerce values so ti is between 0 and 1.
    let _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
}
let alpha = 0.3
const headingColors = {
    'Highlight': addAlpha("#ff0000", 0.2),
    'H': colorPlate[0],
    'H1': colorPlate[1],
    'H2': colorPlate[2],
    'H3': colorPlate[3],
    'H4': colorPlate[4],
    'H5': colorPlate[5],
    'H6': colorPlate[6],
    'H7': colorPlate[7],
    'H8': colorPlate[8],
    'H9': colorPlate[9]
}

const tagTypes = {
    "Artifact": "Artifact",
    "Caption": "Caption",
    "Figure": "Figure",
    "Formula": "Formula",
    'H': "Heading", "H1": "Heading", "H2": "Heading", "H3": "Heading", "H4": "Heading", "H5": "Heading", "H6": "Heading",
    "L": "List",
    "P": "Paragraph",
    "Table": "Table",
    // "Form": "Formular Field",
    // "Link": "Link",
    // "Reference": "Reference"
};

let canvasSize = [476 * imageScaling, 674 * imageScaling];

const readingOrderConfig = {
    imagePointRadius: 10 * imageScaling,
    readingOrderStartColor: "#00ff00",
    readingOrderColor: '#ff0000',
    artifactColor: '#989898',
    listElemHeight: 40,
    listElemLength: 500,
    listElemDistance: 10,
    listFontStyle: "16px sans-serif",
    listTextLength: 40
}

const regionConfig = {
    markedElements: 'rgba(0,255,0,0.3)',
    notMarkedElements: 'rgba(255,0,0,0.3)',
    clickOffset: 10,
    doNotShow: ['TR', 'TD', 'TH', 'LI', 'LBody', 'Lbl']
}

alpha = 0.2;
const tableConfig = {
    elementMargin: 3,
    lineSelectionOffset: 3 * imageScaling,
    rectSize: 5,
    headerColor: addAlpha(colorPlate[4], alpha),
    dataColor: addAlpha(colorPlate[5], alpha),
    highlightingColor: addAlpha(colorPlate[1], alpha),
    borderColor: "#ffffff",
    lineColor: "#ff0000",
    lineHighlightColor: "#00ff00"
}

const metaInformationConfig = {
    languages: {
        "af":{
            "de":"Afrikaans",
            "en":"Afrikaans",
            "native":"Afrikaans"
        },
        "sq":{
            "de":"Albanisch",
            "en":"Albanian",
            "native":"Shqip"
        },
        "ar":{
            "de":"Arabisch",
            "en":"Arabic",
            "native":"العربية"
        },
        "hy":{
            "de":"Armenisch",
            "en":"Armenian",
            "native":"Հայերեն"
        },
        "be":{
            "de":"Weissrussisch",
            "en":"Belarusian",
            "native":"Беларуская"
        },
        "bs":{
            "de":"Bosnisch",
            "en":"Bosnian",
            "native":"bosanski jezik"
        },
        "bg":{
            "de":"Bulgarisch",
            "en":"Bulgarian",
            "native":"български език"
        },
        "ca":{
            "de":"Katalanisch",
            "en":"Catalan; Valencian",
            "native":"Català"
        },
        "zh":{
            "de":"Chinesisch",
            "en":"Chinese",
            "native":"中文 (Zhōngwén), 汉语, 漢語"
        },
        "hr":{
            "de":"Kroatisch",
            "en":"Croatian",
            "native":"hrvatski"
        },
        "cs":{
            "de":"Tschechisch",
            "en":"Czech",
            "native":"česky, čeština"
        },
        "da":{
            "de":"Dänisch",
            "en":"Danish",
            "native":"dansk"
        },
        "nl":{
            "de":"Niederländisch",
            "en":"Dutch",
            "native":"Nederlands"
        },
        "en":{
            "de":"Englisch",
            "en":"English",
            "native":"English"
        },
        "fi":{
            "de":"Finnisch",
            "en":"Finnish",
            "native":"suomi"
        },
        "fr":{
            "de":"Französisch",
            "en":"French",
            "native":"français"
        },
        "ka":{
            "de":"Georgisch",
            "en":"Georgian",
            "native":"ქართული"
        },
        "de":{
            "de":"Deutsch",
            "en":"German",
            "native":"Deutsch"
        },
        "el":{
            "de":"Griechisch",
            "en":"Greek",
            "native":"Ελληνικά"
        },
        "he":{
            "de":"Hebräisch",
            "en":"Hebrew",
            "native":"עברית"
        },
        "hi":{
            "de":"Hindi",
            "en":"Hindi",
            "native":"हिन्दी, हिंदी"
        },
        "hu":{
            "de":"Ungarisch",
            "en":"Hungarian",
            "native":"Magyar"
        },
        "is":{
            "de":"Isländisch",
            "en":"Icelandic",
            "native":"Íslenska"
        },
        "it":{
            "de":"Italienisch",
            "en":"Italian",
            "native":"Italiano"
        },
        "ja":{
            "de":"Japanisch",
            "en":"Japanese",
            "native":"日本語 (にほんご／にっぽんご)"
        },
        "ko":{
            "de":"Koreanisch",
            "en":"Korean",
            "native":"한국어 (韓國語), 조선말 (朝鮮語)"
        },
        "la":{
            "de":"Latein",
            "en":"Latin",
            "native":"latine"
        },
        "mk":{
            "de":"Mazedonisch",
            "en":"Macedonian",
            "native":"македонски јазик"
        },
        "mn":{
            "de":"Mongolisch",
            "en":"Mongolian",
            "native":"монгол"
        },
        "no":{
            "de":"Norwegisch",
            "en":"Norwegian",
            "native":"Norsk"
        },
        "pl":{
            "de":"Polnisch",
            "en":"Polish",
            "native":"polski"
        },
        "pt":{
            "de":"Portugiesisch",
            "en":"Portuguese",
            "native":"Português"
        },
        "rm":{
            "de":"Rätoromanisch",
            "en":"Romansh",
            "native":"rumantsch grischun"
        },
        "ru":{
            "de":"Russisch",
            "en":"Russian",
            "native":"русский язык"
        },
        "sr":{
            "de":"Serbisch",
            "en":"Serbian",
            "native":"српски језик"
        },
        "sk":{
            "de":"Slovakisch",
            "en":"Slovak",
            "native":"slovenčina"
        },
        "sl":{
            "de":"Slowenisch",
            "en":"Slovene",
            "native":"slovenščina"
        },
        "es":{
            "de":"Spanisch",
            "en":"Spanish",
            "native":"español"
        },
        "sv":{
            "de":"Schwedisch",
            "en":"Swedish",
            "native":"svenska"
        },
        "th":{
            "de":"Thailändisch",
            "en":"Thai",
            "native":"ไทย"
        },
        "tr":{
            "de":"Türkisch",
            "en":"Turkish",
            "native":"Türkçe"
        },
        "uk":{
            "de":"Ukrainisch",
            "en":"Ukrainian",
            "native":"українська"
        },
        "vi":{
            "de":"Vietnamesisch",
            "en":"Vietnamese",
            "native":"Tiếng Việt"
        }
    }
}

alpha = 0.1;
const listConfig = {
    highlightingColorLine: colorPlate[6],
    drawingColor: colorPlate[5],
    listItemSelectColor: addAlpha(colorPlate[2], 0.2),
    lineColor: colorPlate[4],
    clickOffset: 5 * imageScaling,
}

export {canvasSize, colors, tagTypes, readingOrderConfig, headingColors, regionConfig, tableConfig,
    metaInformationConfig, listConfig, bboxLineWidth, addAlpha, font, imageScaling}
