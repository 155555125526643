import React from 'react';

function Help() {
    return <div id="legalHelp">
        <h2>Help</h2>
        <h3>What's the meaning of the word PAVE?</h3>
        <p>PAVE is the abbreviation of <b>P</b>DF <b>A</b>ccessiblity <b>V</b>alidation <b>E</b>ngine.</p>
    </div>
}

export default Help;
