import React, {useState, useRef} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {postPDF, getFiles, deleteFile, getFileInfo, getPDF} from "./APIcalls";
import {errorUpload} from "./utils/ErrorMessages";
import { useDropzone } from 'react-dropzone';
import {AiOutlineDelete, AiOutlineDownload, AiOutlineUpload} from "react-icons/ai";
import Instructions from "./utils/Instructions";
import Button from 'react-bootstrap/Button';
import {BeatLoader} from'react-spinners';
import {startResizeX, resizingX, resizingY} from "./utils/UtilsResize";
import {DownloadHandler} from "./utils/DownloadHandler";

const UploadPDF = ({pdf, setPdfInfo, nextStep, menuSize, setMenuSize, instructionSize, setInstructionSize}) => {

    // state for showing error message
    const [showError, setShowError] = useState(false);
    // state for error message text
    const errorMessage = useRef(null);
    // state for file uploaded
    const [file, setFile] = useState(null);
    // state with the list of previous files on the server of the user
    const [oldFiles, setOldFiles] = useState({list: []});

    // helper values for resizing the menu and instruction text
    const startResizeValueMenu = useRef(null);
    const startResizeValueInstruction = useRef(null);

    const [startDownload, setStartDownload] = useState(false);

    const queryClient = useQueryClient();

    // mutation for uploading a PDF
    const uploadingPDF = useMutation({
        mutationFn: ({file}) => {
            // Defining the workerSrc property and load the PDF with pdfjs
            window.pdfjsLib.GlobalWorkerOptions.workerSrc = "//cdnjs.cloudflare.com/ajax/libs/pdf.js/" + window.pdfjsLib.version + "/pdf.worker.mjs";
            const url = URL.createObjectURL(file.get("fileToUpload"));
            return window.pdfjsLib.getDocument(url).promise.then(pdfObject => {
                pdf.current = pdfObject;
                return postPDF({file: file});
            });
        },
        onSuccess: data => {
            setPdfInfo(data);
            nextStep();
            queryClient.invalidateQueries(["files"]);
        },
        onError: error => {
            if (error.message != null) errorMessage.current = error.message;
            else errorMessage.current = error;
            setShowError(true);
        }
    })

    // mutation for set pdf to previous file
    const setFileMutation = useMutation({
        mutationFn: ({file}) => getFileInfo({file}),
        onSuccess: data => {
            getPDF({pdfInfo: data}).then(res => {
                window.pdfjsLib.GlobalWorkerOptions.workerSrc = "//cdnjs.cloudflare.com/ajax/libs/pdf.js/" + window.pdfjsLib.version + "/pdf.worker.mjs";
                const url = URL.createObjectURL(res.data);
                window.pdfjsLib.getDocument(url).promise.then(pdfObject => {
                    pdf.current = pdfObject;
                    setPdfInfo(data);
                    nextStep();
                });
            });
        }
    })

    // get the list with previous uploaded files
    const getFilesQuery = useQuery({
        queryKey: ["files"],
        queryFn: () => getFiles(),
        onSuccess: data => {
            setOldFiles(data);
        }
    })

    // delete a file on the server
    const deleteFileMutation = useMutation({
        mutationFn: ({fileId}) => deleteFile({fileId: fileId}),
        onSuccess: data => {
            queryClient.invalidateQueries(["files"]);
        }
    })

    // helper function for drag and drop files
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: (acceptedFile) => {
            setFile(acceptedFile[0])
        },
    });

    return (
        <div id="uploadPDF" className="d-flex flex-row flex-fill"
             onMouseUp={() => startResizeValueMenu.current = null}
             onMouseMove={(e) => resizingX(e, startResizeValueMenu.current, setMenuSize)}
             style={startResizeValueMenu.current != null ? {userSelect: 'none'}: null}>
            <DownloadHandler pdfInfo={file != null ? {fileid: file.id, filename: file.filename} : null} startDownload={startDownload} setStartDownload={setStartDownload} />
            {showError ? errorUpload({errorMessage: errorMessage.current, setShowError: setShowError, setFile: setFile}) : null}
            <div className="d-flex flex-column" id="menu" style={{width: menuSize + "px"}}
                 onMouseUp={() => startResizeValueInstruction.current = null}
                 onMouseMove={(e) => resizingY(e, startResizeValueInstruction.current, setInstructionSize)}>
                <div id="resizeHandleX" onMouseDown={(e) => startResizeValueMenu.current = startResizeX(e)}></div>
                <Instructions
                    title="Upload a PDF"
                    bodyText={<>Please select a PDF and upload it or select a previously uploaded PDF from the
                        list.</>}
                    buttons={null}
                    instructionSize={instructionSize}
                    startResizeValueInstruction={startResizeValueInstruction}
                />
                <div id="files">
                    {file != null && file.path != null ? <div id="selectedFile" className="d-flex flex-row align-items-center">
                        <p className="flex-fill">{file.path}</p>
                        {uploadingPDF.isLoading ? <BeatLoader color="#ffffff"/> : <Button onClick={() => {
                            const formData = new FormData();
                            formData.append('fileToUpload', file);
                            uploadingPDF.mutate({file: formData});
                        }} variant="light">
                            Upload PDF
                        </Button>}
                    </div> : null}
                    <div {...getRootProps()} id="dropzone">
                        <input {...getInputProps()} aria-label="upload PDF dropzone and button"/>
                        <AiOutlineUpload size="5em"/>
                        {isDragActive ? (
                            <p>Drop the PDF here...</p>
                        ) : (
                            <p><b>Choose a file</b> or drag it here</p>
                        )}
                    </div>
                    {oldFiles.list.length > 0 ? <><h4>Old Files</h4>
                        <ul>
                            {oldFiles.list.map((file, fileI) => <li key={fileI}
                                                                    className="d-flex flex-row align-items-center">
                                <Button onClick={() => setFileMutation.mutate({file: file})} className="flex-fill" variant="light"
                                        id="fileButton">{file.filename}</Button>
                                <Button onClick={() => {
                                    setFile(file);
                                    setStartDownload(true);
                                }} variant="light"
                                        id="downloadButton" aria-label="download"><AiOutlineDownload size="1.5em"/></Button>
                                <Button onClick={() => deleteFileMutation.mutate({fileId: file.id})}
                                        variant="light" aria-label="delete"><AiOutlineDelete size="1.5em"/></Button>
                            </li>)}
                        </ul>
                    </> : null}
                </div>
            </div>
        </div>
    );
};

export default UploadPDF;
