import React, {useEffect, useRef, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from "react-query";
import {getPageImagePdfJs, getTagType, updateTags} from "./APIcalls";
import {updateAltText} from "./StructTreeActions";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import MathEditor from "./MathEditor";
import {drawFigures, loadingCanvas} from "./Drawing";
import {imageCropper, navigation} from "./Tools";
import {imageScaling} from "./Config";
import {errorDrawing, WarningNotComplete} from "./utils/ErrorMessages";
import Instructions from "./utils/Instructions";
import {MdOutlineAutoFixHigh} from "react-icons/md";
import {resizingX, resizingY, startResizeX} from "./utils/UtilsResize";
import PageView from "./PageView";

const Formulae = ({pdf, pdfInfo, stepSelected, setStep, nextStep, menuSize, setMenuSize, instructionSize, setInstructionSize, showPageMenu, setShowPageMenu}) => {

    const formulas = useRef([]);
    const [formulaI, setFormulaI] = useState(-1);
    const pageNum = useRef(1);
    const [imageReady, setImageReady] = useState(true);
    const modifications = useRef([]);
    const [useMathEditor, setUseMathEditor] = useState(false);
    const canvasRef = useRef(null);
    const ctx = useRef(null);

    const queryClient = useQueryClient();
    const viewed = useRef([true]);
    const [showWarning, setShowWarning] = useState(false);
    const [zoomFactor, setZoomFactor] = useState(1);

    // resize start values
    const startResizeValueMenu = useRef(null);
    const startResizeValueInstruction = useRef(null);

    // changing step
    useEffect(() => {
        if (stepSelected !== -1) {
            const checkedAll = viewed.current.every(t => t || stepSelected < 7);
            updateStep(() => {
                queryClient.invalidateQueries(["Formulas", pdfInfo.fileid]).then(() => {
                    if (checkedAll) setStep(stepSelected);
                    else setShowWarning(true);
                });
            });
        }
    }, [stepSelected]);

    // init canvas
    useEffect(() => {
        if (canvasRef.current) {
            ctx.current = canvasRef.current.getContext('2d');
        }
        formulasQuery.refetch();
        viewed.current[formulaI] = true;
    }, []);

    // update canvas after math editor
    useEffect(() => {
        if (canvasRef.current) {
            ctx.current = canvasRef.current.getContext('2d');
            drawing();
        }
    }, [canvasRef.current]);


    // get the formulas
    const formulasQuery = useQuery({
        queryKey: ["Formulas", pdfInfo.fileid],
        queryFn: () => getTagType({pdfInfo: pdfInfo, tagType: "Formula", pdf: pdf}),
        staleTime: 1000 * 60 * 5, // less fetching,
        enabled: pdfInfo.fileid != null,
        placeholderData: null,  // placeholder image
        onSuccess: data => {
            formulas.current = data;
            if (formulas.current.length !== 0) {
                pageNum.current = formulas.current[0].rectangle.page;
                if (viewed.current.length === 1) {
                    viewed.current = Array.from({length: formulas.current.length}, _ => false);
                }
                viewed.current[0] = true;
                setFormulaI(0);
            }
        }
    });

    // get the image
    const pageImage = useQuery({
        queryKey: ["image", pdfInfo.fileid, pageNum.current],
        queryFn: () => getPageImagePdfJs({pdf: pdf, pageNum: pageNum.current, setImageReady: setImageReady}),
        staleTime: 1000 * 60 * 5, // less fetching,
        enabled: pdfInfo.fileid != null && pageNum.current > -1,
        placeholderData: null,  // placeholder image
    });

    // update formulas
    const formulasMutation = useMutation({
        mutationFn: ({pdfInfo, modifications}) => updateTags({pdfInfo: pdfInfo, modifications: modifications})
    })

    function changeFormulaI(i) {
        if (i !== formulaI) {
            updateStep();
            setFormulaI(i);
            pageNum.current = formulas.current[i].rectangle.page;
            drawing();
        }
    }

    /**
     * Helper function for updating the step
     */
    function updateStep(onSuccessFunction) {
        if (formulas.current[formulaI] != null) {
            modifications.current.push(updateAltText({
                id: formulas.current[formulaI].id,
                altText: formulas.current[formulaI].altText
            }));
        }
        return formulasMutation.mutate({pdfInfo: pdfInfo, modifications: modifications.current}, {
            onSuccess: () => {
                if (onSuccessFunction) {
                    return onSuccessFunction();
                }
            }
        });
    }


    /**
     * helper function for drawing
     */
    function drawing(){
        if (!formulasQuery.isLoading && imageReady && ctx.current != null && pageImage.data != null) {
            try {
                canvasRef.current.height = pageImage.data.naturalHeight;
                canvasRef.current.width = pageImage.data.naturalWidth;
                drawFigures({ctx: ctx.current, figure: formulas.current[formulaI], image: pageImage.data});
            }
            catch (e) {
                errorDrawing({errorMessage: e});
            }
        }
        else {
            if (imageReady && ctx.current != null && pageImage.data != null) {
                drawFigures({ctx: ctx.current, figure: null, image: pageImage.data})
            }
            else if (!formulasQuery.isLoading && ctx.current != null) {
                loadingCanvas({ctx: ctx.current, canvasRef: canvasRef.current});
            }
        }
    }

    drawing();

    function showFormula() {
        drawing();
        if (imageReady && pageImage.data != null && formulas.current.length > 0) {
            return <div className="d-flex flex-column" id="formula">
                <h3>Formula {formulaI + 1}/{formulas.current.length}</h3>
                <div id="figureDiv">
                    <img src={imageCropper({
                        image: pageImage.data,
                        border: 5,
                        rectangle: {
                            x: formulas.current[formulaI].rectangle.llx * imageScaling,
                            y: pageImage.data.naturalHeight - formulas.current[formulaI].rectangle.ury * imageScaling,
                            w: formulas.current[formulaI].rectangle.urx * imageScaling - formulas.current[formulaI].rectangle.llx * imageScaling,
                            h: formulas.current[formulaI].rectangle.ury * imageScaling - formulas.current[formulaI].rectangle.lly * imageScaling
                        },
                        resize: 1
                    })}/>
                </div>
                <Form className="flex-column" id="alternativeTextInput">
                    <Form.Group key={'formulaAltText-' + formulaI} controlId={'formulaAltText'}>
                        <Form.Label>Alternative Text</Form.Label>
                        <Form.Control key={'formulaAltText-' + formulaI} type="alternative text" as="textarea" rows={5}
                                      defaultValue={formulas.current[formulaI].altText}
                                      onChange={e => formulas.current[formulaI].altText = e.target.value}/>
                    </Form.Group>
                </Form>
                <div className="d-flex flex-row justify-content-center">
                    <Button onClick={() => setUseMathEditor(true)} variant="light" id="mathEditorButton">
                        <MdOutlineAutoFixHigh size="1.5em" /><br/>
                        Math Editor: Generate Alternative Text Automatically
                    </Button>
                </div>
            </div>
        }
        return <div className="d-flex flex-column" id="formula">
            <h3>No formulas found in the document</h3>
        </div>;
    }

    if (useMathEditor) {
        return <MathEditor formula={formulas.current[formulaI]} setUseMathEditor={setUseMathEditor} img={pageImage.data}
                           pdfInfo={pdfInfo}/>
    }

    return <div className="d-flex flex-row flex-fill" id="formulas"
                onMouseUp={() => startResizeValueMenu.current = null}
                onMouseMove={(e) => resizingX(e, startResizeValueMenu.current, setMenuSize)}
                style={startResizeValueMenu.current != null ? {userSelect: 'none'}: null}>
            <WarningNotComplete
                showWarning={showWarning} setShowWarning={setShowWarning}
                titleMessage={"Have you checked all formulae?"}
                bodyMessage={<>It seems that you have not checked the following
                    formulae: {viewed.current.map((e, ei) => e ? null : ei + 1).filter(e => e != null).join(", ")}
                    <br/> Do you want to check them before you continue?</>}
                currentTask={7}
                setTask={setStep}
                taskSelected={stepSelected}/>
        <div className="d-flex flex-column" id="menu" style={{width: menuSize + "px"}}
             onMouseUp={() => startResizeValueInstruction.current = null}
             onMouseMove={(e) => resizingY(e, startResizeValueInstruction.current, setInstructionSize)}>
            <div id="resizeHandleX" onMouseDown={(e) => startResizeValueMenu.current = startResizeX(e)}></div>
            <Instructions
                title="Step 7: Edit Alternative Text of Mathematical Formulas"
                bodyText="In this step, you can edit the alternative text of all mathematical formulas in the document.
                    The alternative text of mathematical formulas should follow clear rules, i.e. the MathSpeak rules.
                    The math editor allows you to generate a valid altenative text without knowledge of the math speak rules."
                buttons={[["Math Editor", "Use the AI supported math editor to create a valid alternative text."]]}
                nextStep={nextStep}
                instructionSize={instructionSize}
                startResizeValueInstruction={startResizeValueInstruction}
            />
            {navigation({
                callback: changeFormulaI,
                length: formulas.current.length,
                i: formulaI,
                title: "Formulas",
                viewed: viewed.current
            })}
            {showFormula()}
        </div>
        <PageView
            pageNum={pageNum.current}
            canvasRef={canvasRef}
            zoomFactor={zoomFactor}
            setZoomFactor={setZoomFactor}
            isLoading={formulasQuery.isLoading || pageImage.isLoading}
            showPageMenu={showPageMenu}
            setShowPageMenu={setShowPageMenu}
            pdf={pdf}
            pdfInfo={pdfInfo}
            updateStep={updateStep}
        />
    </div>
};

export default Formulae;
