import {getBoundingBoxCell, transformRectangle} from "../Tools";
import {listConfig} from "../Config";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import React from "react";

function getListElemByPoint(list, point, imageSize) {
    let found = null;
    list.every(li => {
        const rect = transformRectangle({rect: li.rectangle, imageSize: imageSize});
        if (rect.x <= point.x && point.x <= rect.x + rect.w && rect.y + listConfig.clickOffset <= point.y && point.y <= rect.y + rect.h + listConfig.clickOffset) {
            // check if a nested list item is more suitable
            let foundChildList = null;
            li.children.every(liE => {
                if (liE.type === "LBody") {
                    liE.children.every(lbE => {
                        if (lbE.type === "L") {
                            foundChildList = getListElemByPoint(lbE.children, point, imageSize);
                            return false;
                        }
                        return true;
                    })
                }
                return foundChildList == null;

            })
            if (foundChildList !== null) {
                found = foundChildList;
                return false;
            }
            // if no better child list item is found
            found = li;
            return false
        }
        return true;
    });
    return found;
}

function addListItem({list, startPos, curPos, imageSize}) {
    let pos = null;  // the position of the element
    let children1 = [];  // children of the top list item
    let children2 = [];  // children of the bottom list item
    // if the list is empty
    if (list.children == null) {
        return false
    }
    // if start or stop is not defined
    if (startPos == null || curPos == null) {
        return false
    }
    // go through the list
    list.children.every((li, i) => {
        const rect = transformRectangle({rect: li.rectangle, imageSize: imageSize}); // list item rect
        if (startPos.y > rect.y && startPos.y < rect.y + rect.h && curPos.y > rect.y && curPos.y < rect.y + rect.h) {
            // check if children of the list item are more suitable
            let children = false;
            li.children.every(liE => {
                if (liE.type === "LBody") {
                    liE.children.every(lbE => {
                        if (lbE.type === "L") {
                            children = addListItem({
                                list: lbE,
                                startPos: startPos,
                                curPos: curPos,
                                imageSize: imageSize
                            });
                            return false;
                        }
                        return true;
                    })
                    return false;
                }
                return true;
            })
            if (children) {
                pos = i;
                children1 = children;
                return false;
            } else {
                pos = i;  // the position of the split the list item
                // go through the list elements Span, Lbl, LBody
                li.children.forEach(liE => {
                    if (liE.type !== "MCRef") {
                        let cTemp1 = [];
                        let cTemp2 = [];
                        // go through the elements (MCRefs)
                        liE.children.forEach(elements => {
                            const cRect = transformRectangle({rect: elements.rectangle, imageSize: imageSize});
                            if (cRect.y + cRect.h / 2 < startPos.y + (curPos.y - startPos.y) / (curPos.x - startPos.x) * (cRect.x + cRect.w / 2 - startPos.x)) {
                                cTemp1.push(elements);
                            } else {
                                cTemp2.push(elements);
                            }
                        })
                        if (cTemp1.length > 0) {
                            children1.push([liE.type, cTemp1])
                        }
                        if (cTemp2.length > 0) {
                            children2.push([liE.type, cTemp2])
                        }
                    }
                })
                return false;
            }
        }
        return true;
    })
    // nothing found to split
    if (pos == null) {
        return false;
    }
    if (children1.length > 0 && children2.length > 0) {
        // top list item
        list.children[pos].children = children1.map(liE => {
            return {
                type: liE[0],
                children: liE[1]
            }
        })
        // bottom list item
        list.children.splice(pos + 1, 0, {
            type: "LI",
            rectangle: null,
            text: null,
            children: children2.map(liE => {
                return {
                    type: liE[0],
                    children: liE[1]
                }
            }),
            id: null
        })
        updateList({list: list, positions: [0]});
    }
    return list;
}

function updateListItem({listItem}) {
    listItem.text = listItem.children.map(MCRef => MCRef.text).join("");
    listItem.rectangle = getBoundingBoxCell({elements: listItem.children});
}

function getAllMCRefs({element}) {
    let MCRef = [];
    element.children.forEach(e => {
        if (e.type !== "MCRef") {
            MCRef = MCRef.concat(getAllMCRefs({element: e, MCRef: MCRef}));
        } else {
            MCRef.push(e);
        }
    })
    return MCRef;
}

function checkList({list}) {
    const correct = list.children.every(li => {
        if (li.type !== "LI") {
            return false;
        }
        return li.children.every(liE => {
            if (liE.type !== "LBody" && liE.type !== "Span" && liE.type !== "Lbl") {
                return false;
            }
            return liE.children.every(e => {
                if (e.type !== "MCRef" && e.type !== "L") {
                    return false;
                }
                if (e.type === "L") {
                    e = checkList({list: e});
                }
                return true;
            })
        })
    })
    if (!correct) {
        list = {
            type: "L",
            children: [{
                type: "LI",
                children: [{
                    type: "LBody",
                    children: getAllMCRefs({element: list})
                }]
            }],
            id: list.id
        };
        updateList({list: list, positions: [0]})
    }
    return list;
}

function updateList({list, positions}) {
    for (let i = positions.length - 1; i >= 0; i--) {
        let sublist = getSublist({list: list, position: positions.slice(0, i + 1), i: 0, create: false});
        if (sublist.children.length === 0) {
            // remove empty list
            let parentList = getSublist({list: list, position: positions.slice(0, i), i: 0, create: false});
            parentList.children.forEach(li => li.children.forEach(liE => {
                let removeEmpty = [];
                liE.children.forEach((e, ei) => {
                    if (e.type === "L" && e.children.length === 0) {
                        removeEmpty.push(ei);
                    }
                })
                let correctionI = 0;
                removeEmpty.forEach(ei => {
                    liE.children.splice(ei - correctionI, 1);
                    correctionI = correctionI + 1;
                })
            }))
        } else {
            // update children
            sublist.children.forEach(liE => {
                liE.children.forEach(element => {
                    updateListItem({listItem: element});
                });
                updateListItem({listItem: liE});
            })
            updateListItem({listItem: sublist});
        }
    }
}

function getSublist({list, position, i}) {
    if (i + 2 <= position.length) {
        let ptemp = null;
        let p2temp = null;
        // add list item if it not exists
        if (list.children[position[i]] == null) {
            list.children[position[i]] = {
                type: "LI",
                children: {
                    type: "LBody",
                    children: {
                        type: "L",
                        children: []
                    }
                }
            };
        }
        // add list body if not exists
        if (list.children[position[i]].children.every(liE => liE.type !== "LBody")) {
            list.children[position[i]].children.push({
                type: "LBody",
                children: {
                    type: "L",
                    children: []
                }
            });
        }
        // get sublist
        list.children[position[i]].children.every((liE, liEi) => {
            if (liE.type === "LBody") {
                // add list if not exists
                if (liE.children.every(lbe => lbe.type !== "L")) {
                    liE.children.push({
                        type: "L",
                        children: []
                    });
                }
                liE.children.every((lbE, lbEi) => {
                    if (lbE.type === "L") {
                        ptemp = liEi;
                        p2temp = lbEi;
                        return false;
                    }
                    return true;
                })
                return false;
            }
            return true;
        })
        return getSublist({
            list: list.children[position[i]].children[ptemp].children[p2temp],
            position: position,
            i: i + 1
        });
    }
    return list;
}

function listAsHtml({list, setRender, baseList, drawing, setHighlightListItem}) {
    if (list == null) return
    return list.children.map(li => {
        let [position, prevPosition] = getListItemPosition({element: li, list: baseList, nesting: 0});
        let positionOptions = [];
        prevPosition.forEach((p, pi) => {
            positionOptions.push(prevPosition.slice(0, pi).concat([p + 1]));
            if (pi + 2 > prevPosition.length) {
                positionOptions.push(prevPosition.slice(0, pi).concat([p, 0]));
            }
        })
        if (positionOptions.length === 0) positionOptions.push(position);
        return <li key={position.map(o => o + 1).join(".")}>
            <div id="listItem" className="d-flex flex-row align-items-center" onMouseEnter={() => {
                setHighlightListItem(li);
            }} onMouseLeave={() => {
                setHighlightListItem(null);
            }}>
                <DropdownButton id="dropdown-item-button" title={position.map(o => o + 1).join(".")} variant="light" key="listItemDropdown">
                    {positionOptions.map((o, i) => {
                        return <Dropdown.Item key={i} onClick={() => {
                            changeListItemPosition({list: baseList, oldPosition: position, newPosition: o});
                            setRender(prevState => prevState + 1);
                        }}>
                            {o.map(o => o +1).join(".")}
                        </Dropdown.Item>
                    })}
                </DropdownButton>
                <p id="listItemText">{li.children.map(liE => liE.children.map(e => e.type !== "L" ? e.text : "").join("")).join("")}</p>
            </div>
            {li.children.map(liE => liE.children.map(e => {
                if (e.type === "L") {
                    return <ul key={"L" + position.map(o => o + 1).join(".")}>{listAsHtml({list: e, setRender: setRender, baseList:baseList, drawing: drawing, setHighlightListItem: setHighlightListItem})}</ul>;
                }
                return null;
            }))}
        </li>
    })
}


function deleteListLine({list, selectedLine, imageSize, startPoint, topListItem, lowListItem}) {
    list.children.forEach((li, i) => {
        // found correct listItem
        if (startPoint === selectedLine) {
            topListItem = li;
        }
        if (startPoint === selectedLine + 1) {
            lowListItem = [i, li];
        }
        startPoint += 1;
        li.children.forEach(liE => {
            if (liE.type === "LBody") {
                liE.children.forEach(lbE => {
                    if (lbE.type === "L") {
                        [startPoint, topListItem, lowListItem] = deleteListLine({
                            list: lbE,
                            selectedLine: selectedLine,
                            imageSize: imageSize,
                            startPoint: startPoint,
                            topListItem: topListItem,
                            lowListItem: lowListItem
                        });
                    }
                })
            }
        })
    })
    if (lowListItem != null) {
        if (lowListItem[0] != null) {
            list.children.splice(lowListItem[0], 1);
            lowListItem[0] = null;
            updateList({list: list, positions: [0]});
        }
        // add low list elements to top list elements
        if (topListItem != null) {
            lowListItem[1].children.forEach(lowLiE => {
                let found = false;
                topListItem.children.every(topLiE => {
                    if (topLiE.type === lowLiE.type) {
                        topLiE.children = topLiE.children.concat(lowLiE.children);
                        found = true
                    }
                })
                if (!found) {
                    topListItem.children.push(lowLiE);
                }
            });
            updateList({list: list, positions: [0]});
            lowListItem = null;
            topListItem = null;
        }
    }
    return [startPoint, topListItem, lowListItem]
}

function getListItemPosition({element, list, nesting}) {
    let prevPosition = []
    let position = [];
    list.children.every((li, i) => {
        // check list item
        if (Object.values(li.rectangle).toString() === Object.values(element.rectangle).toString()) {
            position = [i];
            return false;
        }
        prevPosition = [i];
        // check children
        let childrenPosition = [];
        let childrenPrevPosition = [];
        li.children.every((liE, liEi) => {
            if (liE.type === "LBody") {
                liE.children.every((lbE, lbEi) => {
                    if (lbE.type === "L") {
                        [childrenPosition, childrenPrevPosition] = getListItemPosition({element: element, list: lbE, nesting: nesting + 1});
                        return false;
                    }
                    return true;
                })
                return false;
            }
            return true;
        });
        if (childrenPrevPosition.length !== 0) {
            prevPosition = prevPosition.concat(childrenPrevPosition);
        }
        if (childrenPosition.length !== 0) {
            position = [i];
            position = position.concat(childrenPosition);
            return false;
        }
        return true;
    })
    return [position, prevPosition];
}

function changeListItemPosition({list, oldPosition, newPosition}) {
    // old element list
    let subListOld = getSublist({list: list, position: oldPosition, i: 0});
    // new element list
    let subListNew = getSublist({list: list, position: newPosition, i: 0});
    subListNew.children.splice(newPosition[newPosition.length - 1], 0, subListOld.children[oldPosition[oldPosition.length - 1]]);
    subListOld.children.splice(oldPosition[oldPosition.length - 1], 1);
    // move list items after the moved list item, if needed
    if (newPosition.length < oldPosition.length) {
        let i = 0
        while(subListOld.children[oldPosition[oldPosition.length - 1]] != null) {
            let tempNewPosition = newPosition.concat([i]);
            subListNew = getSublist({list: list, position: tempNewPosition, i: 0});
            subListNew.children.splice(tempNewPosition[tempNewPosition.length - 1], 0, subListOld.children[oldPosition[oldPosition.length - 1]]);
            subListOld.children.splice(oldPosition[oldPosition.length - 1], 1);
            updateList({list: list, positions: tempNewPosition});
            i += 1;
        }
    }
    updateList({list: list, positions: newPosition});
    updateList({list: list, positions: oldPosition});
}


export {getSublist, updateList, checkList, updateListItem, addListItem, getListElemByPoint, getListItemPosition, changeListItemPosition, deleteListLine, listAsHtml, getAllMCRefs};
