import React, {useState} from "react";
import Button from "react-bootstrap/Button";
import {SlArrowDown, SlArrowRight} from "react-icons/sl";
import {IoMdPricetag} from "react-icons/io";

export function CollapsibleListItem({tagName, content}) {
    const [collapsed, setCollapsed] = useState(true);

    return <li className="collapsible">
        <div className="tag">
            <Button variant="light" onClick={() => {setCollapsed(!collapsed)}} aria-label="collapse button">
                {collapsed ? <SlArrowRight size="1.5em"/> : <SlArrowDown size="1.5em"/>}
            </Button><IoMdPricetag size="1.5em"/><p>{tagName}</p>
        </div>
        {collapsed ? null : <ul>{content}</ul>}
    </li>
}
