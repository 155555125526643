import React, {useState} from "react";
import {analyzePDF, getPDF} from "../APIcalls";
import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {BeatLoader} from'react-spinners';
import {MdOutlineDone} from "react-icons/md";

export function DownloadHandler({startDownload, pdfInfo, setStep, setStartDownload, pdf}) {

    const [downloadSuccess, setDownloadSuccess] = useState(false);
    const [showDownloadWindow, setShowDownloadWindow] = useState(false);
    const [allowAnalyzingPDF, setAllowAnalyzingPDF] = useState(false);

    if (startDownload && !showDownloadWindow) {
        setShowDownloadWindow(true);
        getPDF({pdfInfo: pdfInfo, pdf: pdf, addWhitespace: true}).then(res => {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(res.data);
            link.download = pdfInfo.filename.replace(".pdf", "_PAVE2.pdf").replace(".PDF", "_PAVE2.pdf");
            link.click();
            setDownloadSuccess(true);
        });
    }
    return <Modal
        show={showDownloadWindow}
        aria-labelledby="download modal"
        onHide={() => {
            setStartDownload(false);
            setShowDownloadWindow(false);
        }}
        id="downloadModal"
    >
        <Modal.Header closeButton>
            <Modal.Title>
                Thank you for using PAVE 2.0!
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="d-flex flex-row" id="pdfDownloadProcessing">
                {!downloadSuccess ? <><p>Preparing your PDF</p>  <BeatLoader color="#000000"/> </> :
                    <><p>PDF prepared</p> <MdOutlineDone  color="#000000"/></>}
            </div>
                <h2>PAVE 2.0 Survey</h2>
                <p>
                PAVE 2.0 is part of the SNSF research project (Accessible Scientific PDFs for All) to develop a novel
                method to remediate PDFs. We would really appreciate your input to improve the tool and learn more about
                your PDF remediation experience.
            </p>
            <p>
                This survey will take about 15 minutes, so grab a beverage and help us to improve PAVE 2.0.
                The results will be published anonymized in a scientific publication.
                If you have more in-depth comments or suggestions, please contact Felix Schmitt (scmx@zhaw.ch) with any
                information you'd like to share.
            </p>
            <p>
                Beside filling out the survey, you can help us by allowing us to analyze the accessibility of your
                PDF.
            </p>
            <div className="d-flex flex-row justify-content-evenly">
                <Button onClick={() => {
                    analyzePDF({pdfInfo: pdfInfo});
                    setAllowAnalyzingPDF(true);
                }}
                        disabled={allowAnalyzingPDF}> {allowAnalyzingPDF ? "Thank you for your help" : "Allow to analyze your PDF"}</Button>
                <Button
                    href={"https://docs.google.com/forms/d/e/1FAIpQLSfPJro6fWypn8BlEhcq6OjIPm0kH0XMhHCueJfM-gnKlQxACg/viewform?usp=sf_link"}
                    target="_blank"> Survey Link </Button>
            </div>
        </Modal.Body>
        <Modal.Footer>
            {setStep != null ? <Button onClick={() => {
                setStep(0);
                setStartDownload(false);
                setShowDownloadWindow(false);
            }}>New PDF</Button> : null}
            <Button variant="light" onClick={() => {
                setStartDownload(false);
                setShowDownloadWindow(false);
            }}>Return</Button>
        </Modal.Footer>
    </Modal>
}
