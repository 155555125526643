import React, {useState, useRef, useEffect } from 'react';
import {updatePageStructTree, predictRegions, getPageImagePdfJs} from "./APIcalls";
import {
    getNotMarked,
    getStructElemByPoint,
    changeTagType,
    transformRectangle,
    getChangeBoxPoint,
    getMousePos, navigation
} from "./Tools";
import {drawRegions, loadingCanvas} from "./Drawing";
import {regionConfig, tagTypes} from "./Config"

import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import { AiOutlineDelete} from 'react-icons/ai';
import {useMutation, useQuery} from "react-query";
import {deleteTag, newTag, updateTagChildren} from "./StructTreeActions";
import Instructions from "./utils/Instructions";
import {errorDrawing, WarningNotComplete} from "./utils/ErrorMessages";
import {MdOutlineAutoFixHigh} from "react-icons/md";
import {resizingX, resizingY, startResizeX} from "./utils/UtilsResize";
import PageView from "./PageView";

const Regions = ({pdf, pdfInfo, stepSelected, setStep, nextStep, menuSize, setMenuSize, instructionSize,
                     setInstructionSize, showPageMenu, setShowPageMenu}) => {

    // page related states
    const pageNum = useRef(1);
    const [imageReady, setImageReady] = useState(true);
    const viewed = useRef(Array.from({length: pdfInfo.numberOfPages}, _ => false));

    // struct tree related states
    const structTree = useRef({structTree: null, operators: null});
    const [structElemSelected, setStructElemSelected] = useState(false);
    const selectedStructElem = useRef([]);
    const notMarked = useRef(null);
    const modifications = useRef([]);

    // canvas related states
    const canvasRef = useRef(null);
    const ctx = useRef(null);
    const isDrawing = useRef(false);
    const newStructElemOperators = useRef(false);
    const startPos = useRef(null);
    const curPos = useRef(null);
    const changeBoxSize = useRef(false);

    // helper states for visualization settings
    const [showLabels, setShowLabels] = useState(true);
    const [showArtifacts, setShowArtifacts] = useState(true);
    const [multiSelect, setMultiSelect] = useState(false);
    const [zoomFactor, setZoomFactor] = useState(1);

    // resize start values
    const startResizeValueMenu = useRef(null);
    const startResizeValueInstruction = useRef(null);

    // other states
    const [showWarning, setShowWarning] = useState(false);
    const [render, setRender] = useState(0);

    // changing step
    useEffect(() => {
        if (stepSelected !== -1) {
            updateStep(() => {
                if (viewed.current.every(t => t) || stepSelected < 1) setStep(stepSelected);
                else setShowWarning(true);
            });
        }
    }, [stepSelected]);

    // initialize canvas
    useEffect(() => {
        if (canvasRef.current) {
            ctx.current = canvasRef.current.getContext('2d');
        }
        updateStep();
        viewed.current[pageNum.current - 1] = true;
        // add keyboard listener
        document.addEventListener('keydown', onKeyDown);
        document.addEventListener('keyup', onKeyUp);
    }, []);

    // getting the image
    const pageImage = useQuery({
        queryKey: ["image", pdfInfo.fileid, pageNum.current],
        queryFn: () => getPageImagePdfJs({pdf: pdf, pageNum: pageNum.current, setImageReady: setImageReady}),
        staleTime: 1000 * 60 * 5, // less fetching,
        enabled: pdfInfo.fileid != null,
        placeholderData: null,  // placeholder image
        onSuccess: () => drawing()
    });

    // update and get structTree
    const structTreeMutation = useMutation({
        mutationFn: ({pdfInfo, oldPage, newPage, modifications, pdf}) => updatePageStructTree({pdfInfo: pdfInfo, oldPage: oldPage, newPage: newPage, modifications: modifications, addArtifacts: true, pdf: pdf}),
        onSuccess: data => {
            structTree.current = data;
            //markEmptyOperatorsAsArtifacts({operators: structTree.current.operators, structTree: structTree.current.structTree, modifications: modifications.current});
            notMarked.current = getNotMarked(structTree.current);
            drawing();
        }
    })

    // predict regions
    const predictRegionsMutation = useMutation({
        mutationFn: ({pdfInfo, pageNum}) => predictRegions({pdfInfo: pdfInfo, page: pageNum}),
        onSuccess: data => {
            modifications.current = [];
            updateStep();
        }
    })

    /**
     * select a struct element
     * @param e event
     */
    function onMouseClick(e) {
        // do nothing during loading
        if (structTreeMutation.isLoading || predictRegionsMutation.isLoading || pageImage.isLoading) return

        // get mouse position and selected element
        const mousePos = getMousePos({e: e, canvasRef: canvasRef.current});
        const tempSelection = getStructElemByPoint(structTree.current.structTree, mousePos, [pageImage.data.naturalWidth, pageImage.data.naturalHeight], showArtifacts);

        if (multiSelect) {
            if (tempSelection != null) {
                if (selectedStructElem.current.every(e => e !== tempSelection)) {
                    selectedStructElem.current.push(tempSelection);
                }
                else {
                    selectedStructElem.current = selectedStructElem.current.filter(e => e !== tempSelection);
                    if (selectedStructElem.current.length === 0) selectedStructElem.current = [];
                }
            }
        }
        else {
            if (tempSelection != null) selectedStructElem.current = [tempSelection];
            else selectedStructElem.current = [];
        }

        if (selectedStructElem.current.length === 0) {
            // no elem selected
            setStructElemSelected(false);
        }
        else {
            // element selected
            setStructElemSelected((prevState) => prevState + 1);
        }
        drawing();
    }

    /**
     * start drawing
     * @param e event
     */
    function onMouseDown(e) {
        // do nothing during loading
        if (structTreeMutation.isLoading || predictRegionsMutation.isLoading || pageImage.isLoading || multiSelect) return
        newStructElemOperators.current = [];
        curPos.current = startPos.current = getMousePos({e: e, canvasRef: canvasRef.current});

        if (structElemSelected && selectedStructElem.current.length === 1 && !multiSelect) {
            const rect = transformRectangle({rect: selectedStructElem.current[0].rectangle, imageSize: [pageImage.data.naturalWidth, pageImage.data.naturalHeight]});
            changeBoxSize.current = getChangeBoxPoint({curX: curPos.current.x, curY: curPos.current.y, rect: rect, threshold: regionConfig.clickOffset});
            if (!changeBoxSize.current) {
                curPos.current = startPos.current = null;
            }
            else {
                requestAnimationFrame(drawing);
            }
        }
        else {
            selectedStructElem.current = [];
            setStructElemSelected(false);
            isDrawing.current = true;
            requestAnimationFrame(drawing)
        }
    }

    /**
     * draw the rectangle
     * @param e event
     */
    function onMouseMove(e) {
        // do nothing during loading
        if (structTreeMutation.isLoading || predictRegionsMutation.isLoading || pageImage.isLoading || (!isDrawing.current && !changeBoxSize.current)  || multiSelect) return
        curPos.current = getMousePos({e: e, canvasRef: canvasRef.current})
        requestAnimationFrame(drawing);
    }

    /**
     * stop drawing create new struct element
     * @param e event
     */
    function onMouseUp(e) {
        // do nothing during loading
        if (structTreeMutation.isLoading || predictRegionsMutation.isLoading || pageImage.isLoading  || multiSelect) return

        // if changing box size
        if (changeBoxSize.current && selectedStructElem.current.length === 1) {
            // get all operators of new element
            newStructElemOperators.current = [...new Set(newStructElemOperators.current.sort((o1, o2) => (o1.id > o2.id) ? 1 : (o1.id < o2.id) ? -1 : 0))];
            // check if operators changed
            if (selectedStructElem.current[0].children.map(o => {return o.id;}).toString() !== newStructElemOperators.current.map(o => {return o.id;}).toString() && newStructElemOperators.current.length > 0) {
                updateTagChildren({
                    elem: selectedStructElem.current[0],
                    newChildren:newStructElemOperators.current,
                    modifications: modifications.current,
                    structTree: structTree.current.structTree
                })
            }
            changeBoxSize.current = false;
        }
        else {
            // if no operators selected
            if (newStructElemOperators.current.length === 0) {
                curPos.current = startPos.current = null;
                isDrawing.current = false;
                return;
            }
            // stop drawing
            isDrawing.current = false;
            // sort struct element operators by id
            newStructElemOperators.current.sort((o1, o2) => (o1.id > o2.id) ? 1 : (o1.id < o2.id) ? -1 : 0);
            // get new struct element
            const tempStructElem = {id: null, children: newStructElemOperators.current.map(o => {
                    return {children: [], id: o.id, rectangle: o.rectangle, text: o.text, type: "MCRef"};
                }), rectangle: {llx: 99999, lly: 99999, urx: -1, ury: -1}, text: "", type: "P"};
            // update text and bounding box of new element
            newStructElemOperators.current.forEach(o => {
                tempStructElem.text += o.text;
                if (tempStructElem.rectangle.llx > o.rectangle.llx) tempStructElem.rectangle.llx = o.rectangle.llx;
                if (tempStructElem.rectangle.urx < o.rectangle.urx) tempStructElem.rectangle.urx = o.rectangle.urx;
                if (tempStructElem.rectangle.lly > o.rectangle.lly) tempStructElem.rectangle.lly = o.rectangle.lly;
                if (tempStructElem.rectangle.ury < o.rectangle.ury) tempStructElem.rectangle.ury = o.rectangle.ury;
            })
            // add new tag
            newTag({elem: tempStructElem, modifications: modifications.current, structTree: structTree.current.structTree});
            // set the new tag as selected
            selectedStructElem.current = [tempStructElem];
            setStructElemSelected(true);

        }
        // reset values
        newStructElemOperators.current = [];
        curPos.current = startPos.current = null;
        notMarked.current = getNotMarked(structTree.current);
        drawing();
    }

    function onKeyDown(e) {
        if (e.key === "Meta" || e.key === 'Control') {
            setMultiSelect(true);
        }
    }

    function onKeyUp(e) {
        if (e.key === "Meta" || e.key === 'Control') {
            setMultiSelect(false);
        }
    }

    /**
     * Helper function for updating the step
     */
    function updateStep(onSuccessFunction, newPage) {
        if (newPage == null) newPage = pageNum.current;
        return structTreeMutation.mutate({pdfInfo: pdfInfo, oldPage: pageNum.current, newPage: newPage, modifications: modifications.current, pdf: pdf}, {onSuccess: () => {
                modifications.current = [];
                if (onSuccessFunction != null) {
                    onSuccessFunction();
                }
            }
        });
    }


    /**
     * helper function for drawing
     */
    function drawing() {
        if (!structTreeMutation.isLoading && notMarked.current != null && imageReady && ctx.current != null && pageImage.data != null) {
            try {
                canvasRef.current.height = pageImage.data.naturalHeight;
                canvasRef.current.width = pageImage.data.naturalWidth;
                drawRegions({
                    ctx: ctx.current,
                    image: pageImage.data,
                    notMarked: notMarked,
                    structTree: structTree.current.structTree,
                    newStructElemOperators: newStructElemOperators,
                    selectedStructElem: selectedStructElem.current,
                    startPos: startPos.current,
                    curPos: curPos.current,
                    showRegionLabels: showLabels,
                    changeBoxSize: changeBoxSize.current,
                    showArtifacts: showArtifacts,
                });
            }
            catch (e) {
                errorDrawing({errorMessage: e});
            }
        }
        if (structTreeMutation.isLoading || predictRegionsMutation.isLoading || pageImage.isLoading) {
            loadingCanvas({ctx: ctx.current, canvasRef: canvasRef.current});
        }
    }

    // draw image and rectangles
    drawing();

    function changePage(i) {
        if (i !== pageNum.current - 1) {
            selectedStructElem.current = [];
            setStructElemSelected(false);
            updateStep(null, i + 1);
            pageNum.current = i + 1;
            modifications.current = [];
        }
    }

    function showSelectedElement() {
        if (selectedStructElem.current.length === 0) {
            return <div id="selectedRegion" className="d-flex flex-column">
                <h3>No Region Selected</h3>
                <p>To edit a region, select a region on the page view.</p>
            </div>
        }
        return <div id="selectedRegion" className="d-flex flex-column">
            <h3>{selectedStructElem.current.length > 1 ? selectedStructElem.current.length + " Regions Selected" : "1 Region Selected"}</h3>
            <div className="d-flex flex-row align-items-center">
                <h4 id="tagTypeTitle">Region Type</h4>
                <DropdownButton id="dropdown-item-button" title={selectedStructElem.current.length > 1 ? "       " : tagTypes[selectedStructElem.current[0].type]} variant="light" key="tagTypeDropdown">
                    {[...new Set(Object.values(tagTypes))].map((t, i) => {
                        return <Dropdown.Item active={tagTypes[selectedStructElem.current.type] === t}
                              onClick={() => {
                                  if (selectedStructElem.current.length > 1 || tagTypes[selectedStructElem.current[0].type] != t) {
                                      selectedStructElem.current.forEach(elem => {
                                          changeTagType({
                                              structTree: structTree.current.structTree,
                                              selectedStructElem: elem,
                                              modifications: modifications.current,
                                              newType: Object.keys(tagTypes)[Object.values(tagTypes).findIndex((e) => e === t)]});
                                      });
                                      setStructElemSelected((prevState) => {
                                          return prevState + 1;
                                      });
                                  }
                              }} key={i}>
                            {t}
                        </Dropdown.Item>
                    })}
                </DropdownButton>
                {selectedStructElem.current.length === 1 ? selectedStructElem.current[0].type === "Artifact" ? <p id="artifactHint">will be ignored by the screen reader</p>: null : null}
            </div>
            <div className="d-flex flex-row">
                <h4>Content</h4>
            </div>
            <div className="d-flex flex-row flex-wrap" id="regionText">
                {selectedStructElem.current.map((selectedElem, selectedElemI) => {
                    return <p id="elementText" key={selectedElemI}>{selectedElem.text}</p>
                })
                }
            </div>
            <div className="d-flex flex-row justify-content-evenly">
                <Button variant="light" onClick={() => {
                    selectedStructElem.current.forEach(elem => {
                        modifications.current = deleteTag({
                            structTree: structTree.current.structTree,
                            elem: elem,
                            modifications: modifications.current
                        });
                    })
                    notMarked.current = getNotMarked({operators: structTree.current.operators, structTree: structTree.current.structTree});
                    selectedStructElem.current = [];
                    setStructElemSelected(false);
                    setMultiSelect(false);
                    drawing();
                }} disabled={!structElemSelected}>
                    <AiOutlineDelete /> <br/>
                    {selectedStructElem.current.length > 1 ? "Delete All Selected Regions" : "Delete Region"}
                </Button>
            </div>
        </div>
    }

    return (
        <div id="regions" className="d-flex flex-row flex-fill"
             onMouseUp={() => startResizeValueMenu.current = null}
             onMouseMove={(e) => resizingX(e, startResizeValueMenu.current, setMenuSize)}
             style={startResizeValueMenu.current != null ? {userSelect: 'none'}: null}>
            <WarningNotComplete
                showWarning={showWarning} setShowWarning={setShowWarning}
                titleMessage={"Have you checked the regions on all pages?"}
                bodyMessage={<>It seems that you have not checked the regions on the following
                    pages: {viewed.current.map((e, ei) => e ? null : ei + 1).filter(e => e != null).join(", ")} <br/> Do
                    you want to check them before you continue?</>}
                currentTask={1}
                setTask={setStep}
                taskSelected={stepSelected}/>
            <div id="menu" className="d-flex flex-column" style={{width: menuSize + "px"}}
                 onMouseUp={() => startResizeValueInstruction.current = null}
                 onMouseMove={(e) => resizingY(e, startResizeValueInstruction.current, setInstructionSize)}>
                <div id="resizeHandleX" onMouseDown={(e) => startResizeValueMenu.current = startResizeX(e)}></div>
                <Instructions
                    title="Step 1: Define Regions"
                    bodyText="
                    In this step, you can refine and adjust regions in the entire document.
                    Utilize the page navigation located below to navigate through the document.
                    A region categorizes PDF elements, each labeled with a specific type indicating its semantic meaning.
                    On the right, you have the page view with the current regions.
                    The title reveals the number of elements that need to be tagged on the page.
                    You can show and hide the labels of the regions and the artifact regions.
                    Additionally, you can zoom in and out of the page. On the left, you can manipulate the regions.
                    To modify a region, select a region within the page view and change its size, type, or remove it entirely.
                    If you checked all pages in the document use the “Next Step” button to get to step 2."
                    buttons={[["Detect Regions", "Automatically detect regions in the current page. It will overwrite existing regions of the current page."],
                        ["Delete All Regions", "Remove all regions from the current page in a single action."]]}
                    hints={[["Combine regions", "Delete one region and resize the other."],
                        ["Multi-select", "Normally you can select only one region. With this button you can select multiple regions"]]}
                    nextStep={nextStep}
                    instructionSize={instructionSize}
                    startResizeValueInstruction={startResizeValueInstruction}
                />
                {navigation({
                    callback: changePage,
                    length: pdfInfo.numberOfPages,
                    i: pageNum.current - 1,
                    title: "Pages",
                    viewed: viewed.current
                })}
                <div id="actionButtons" className="d-flex flex-row justify-content-evenly">
                    <Button variant="light" onClick={() => {
                        predictRegionsMutation.mutate({pdfInfo: pdfInfo, pageNum: pageNum.current});
                    }}>
                        <MdOutlineAutoFixHigh size="1.5em"/><br/>
                        Detect Regions
                    </Button>
                    <Button variant="light" onClick={() => {
                        structTree.current.structTree.forEach(elem => {
                            modifications.current = deleteTag({
                                structTree: structTree.current.structTree,
                                elem: elem,
                                modifications: modifications.current
                            });
                        });
                        //markEmptyOperatorsAsArtifacts({operators: structTree.current.operators, structTree: structTree.current.structTree, modifications: modifications.current});
                        notMarked.current = getNotMarked({
                            operators: structTree.current.operators,
                            structTree: structTree.current.structTree
                        });
                        selectedStructElem.current = [];
                        drawing();
                        setStructElemSelected(false);
                        setRender(prevState => prevState + 1);
                    }}>
                        <AiOutlineDelete size="1.5em"/><br/>
                        Delete All
                    </Button>
                </div>
                {showSelectedElement()}
            </div>
            <PageView
                pageNum={pageNum.current}
                extraTitleText={notMarked.current != null ? " (" + notMarked.current.length + " elements not tagged)" : ""}
                canvasRef={canvasRef}
                onMouseClick={onMouseClick}
                onMouseDown={onMouseDown}
                onMouseMove={onMouseMove}
                onMouseUp={onMouseUp}
                showLabels={showLabels}
                setShowLabels={setShowLabels}
                showArtifacts={showArtifacts}
                setShowArtifacts={setShowArtifacts}
                zoomFactor={zoomFactor}
                setZoomFactor={setZoomFactor}
                multiSelect={multiSelect}
                setMultiSelect={setMultiSelect}
                isLoading={structTreeMutation.isLoading || predictRegionsMutation.isLoading || pageImage.isLoading}
                showPageMenu={showPageMenu}
                setShowPageMenu={setShowPageMenu}
                pdf={pdf}
                pdfInfo={pdfInfo}
                updateStep={updateStep}
            />
        </div>
    )

}

export default Regions;
