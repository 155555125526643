import React, {useEffect, useRef, useState} from 'react';
import {useQuery, useMutation, useQueryClient} from "react-query";
import {getPageImagePdfJs, getTagType, updateTags} from "./APIcalls";
import Button from "react-bootstrap/Button";
import {headingColors} from "./Config";
import Dropdown from "react-bootstrap/Dropdown";
import {changeTagType, navigation} from "./Tools";
import DropdownButton from "react-bootstrap/DropdownButton";
import {drawFigures, drawHeadings, loadingCanvas} from "./Drawing";
import {checkHeadingLevels, detectHeadingLevels, getLastHeadingLevel} from "./utils/UtilsHeadings.jsx";
import Instructions from "./utils/Instructions";
import {errorDrawing} from "./utils/ErrorMessages";
import {MdOutlineAutoFixHigh} from "react-icons/md";
import {resizingX, resizingY, startResizeX} from "./utils/UtilsResize";
import PageView from "./PageView";

const Headings = ({pdf, pdfInfo, stepSelected, setStep, nextStep, menuSize, setMenuSize, instructionSize, setInstructionSize, showPageMenu, setShowPageMenu}) => {

    const headings = useRef(false);
    const pageNum = useRef(1);
    const [imageReady, setImageReady] = useState(true);
    const modifications = useRef([]);

    // canvas
    const canvasRef = useRef(null);
    const ctx = useRef(null);
    const highlighted = useRef(false);

    const [forceRender, setForceRender] = useState(0);
    const [detected, setDetected] = useState(false);
    const [showLabels, setShowLabels] = useState(true);

    const queryClient = useQueryClient();

    const viewed = useRef(Array.from({length: pdfInfo.numberOfPages}, _ => false));

    const [zoomFactor, setZoomFactor] = useState(1);

    // resize start values
    const startResizeValueMenu = useRef(null);
    const startResizeValueInstruction = useRef(null);

    useEffect(() => {
        if (canvasRef.current) {
            ctx.current = canvasRef.current.getContext('2d');
        }
        headingsQuery.refetch();
        viewed.current[pageNum.current - 1] = true;
    }, []);

    // changing step
    useEffect(() => {
        if (stepSelected !== -1) {
            updateStep(() => setStep(stepSelected));
        }
    }, [stepSelected]);

    // getting the image
    const headingsQuery = useQuery({
        queryKey: ["Headings", pdfInfo.fileid],
        queryFn: () => getTagType({pdfInfo: pdfInfo, tagType: "Headings", pdf: pdf}),
        staleTime: 1000 * 60 * 5, // less fetching,
        enabled: pdfInfo.fileid != null,
        placeholderData: null,  // placeholder image
        onSuccess: data => headings.current = checkHeadingLevels({headings: data, modifications: modifications.current})
    });

    // getting the image
    const pageImage = useQuery({
        queryKey: ["image", pdfInfo.fileid, pageNum.current],
        queryFn: () => getPageImagePdfJs({pdf: pdf, pageNum: pageNum.current, setImageReady: setImageReady}),
        staleTime: 1000 * 60 * 5, // less fetching,
        enabled: pdfInfo.fileid != null,
        placeholderData: null,  // placeholder image
        onSuccess: () => drawing()
    });

    // update and get structTree
    const headingsMutation = useMutation({
        mutationFn: ({pdfInfo, modifications}) => updateTags({pdfInfo: pdfInfo, modifications: modifications})
    })

    function getHeadingList() {
        return headings.current.map((heading, i) => {
            let headingLevel = 1;
            if (heading.type.length === 2) {
                headingLevel = parseInt(heading.type[1]);
            }
            let headingText = heading.text;
            return <li id="headingElement" className="d-flex flex-row align-items-center"
                       style={{marginLeft: (headingLevel-1)*50 + "px", borderColor: headingColors[heading.type]}} key={i}
                       onClick={() => {
                highlighted.current = heading;
                pageNum.current = heading.rectangle.page;
                setForceRender(prevState => prevState + 1);
            }}>
                <DropdownButton id="dropdown-headings" title={"H" + headingLevel} variant="light" key="tagTypeDropdown">
                    {Array.from(
                        {length: getLastHeadingLevel({headings: headings.current, selectedHeading: heading}) + 1},
                        (_, index) => index + 1
                    ).map((t, i) => {
                        return <Dropdown.Item active={headingLevel === t}
                                              onClick={() => {
                                                  changeTagType({
                                                      structTree: headings.current,
                                                      selectedStructElem: heading,
                                                      modifications: modifications.current,
                                                      newType: "H"+ t});
                                                  checkHeadingLevels({
                                                      headings: headings.current,
                                                      modifications: modifications.current});
                                                  setForceRender(prevState => prevState + 1);
                                              }} key={i}>
                            {"H" + t}
                        </Dropdown.Item>
                    })}
                </DropdownButton>
                <p id="headingText">{headingText}</p>
            </li>
        })

    }

    function changePage(i) {
        if (i !== pageNum.current - 1) {
            pageNum.current = i + 1;
            setForceRender(prevState => prevState + 1);
        }
    }

    /**
     * Helper function for updating the step
     */
    function updateStep(onSuccessFunction) {
        return headingsMutation.mutate({pdfInfo: pdfInfo, modifications: modifications.current}, {
            onSuccess: () => {
                queryClient.invalidateQueries(["Headings", pdfInfo.fileid]).then(() => {
                    if (onSuccessFunction != null) {
                        onSuccessFunction();
                    }
                });
            }
        });
    }


    /**
     * helper function for drawing
     */
    function drawing() {
        if (!headingsQuery.isLoading && imageReady && headings.current.length !== 0 && ctx.current != null && pageImage.data != null) {
            try {
                canvasRef.current.height = pageImage.data.naturalHeight;
                canvasRef.current.width = pageImage.data.naturalWidth;
                drawHeadings({
                    ctx: ctx.current,
                    image: pageImage.data,
                    headings: headings.current,
                    page: pageNum.current,
                    highlighted: highlighted.current,
                    showHeadingLabels: showLabels
                });
            }
            catch (e) {
                errorDrawing({errorMessage: e});
            }
        }
        else if (headingsQuery.isLoading || pageImage.isLoading) {
            loadingCanvas({ctx: ctx.current, canvasRef: canvasRef.current});
        }
        else if (imageReady && pageImage.data != null && ctx.current != null) {
            drawFigures({ctx: ctx.current, figure: null, image: pageImage.data});
        }
    }

    drawing();

    return (
        <div className="d-flex flex-row flex-fill" id="headings"
             onMouseUp={() => startResizeValueMenu.current = null}
             onMouseMove={(e) => resizingX(e, startResizeValueMenu.current, setMenuSize)}
             style={startResizeValueMenu.current != null ? {userSelect: 'none'}: null}>
            <div className="d-flex flex-column" id="menu" style={{width: menuSize + "px"}}
                 onMouseUp={() => startResizeValueInstruction.current = null}
                 onMouseMove={(e) => resizingY(e, startResizeValueInstruction.current, setInstructionSize)}>
                <div id="resizeHandleX" onMouseDown={(e) => startResizeValueMenu.current = startResizeX(e)}></div>
                <Instructions
                    title="Step 3: Organize Heading Levels"
                    bodyText="In this step, you can adjust the heading levels in the document.
                    Below you see a list of all headers in the document. You can change the level of a header via the dropdown menu."
                    buttons={[["Detect Heading Levels", "Automatically detect the heading levels."]]}
                    hints={[["Show Header", "Click on a header to highlight the header in the page view."]]}
                    nextStep={nextStep}
                    instructionSize={instructionSize}
                    startResizeValueInstruction={startResizeValueInstruction}
                />
                {navigation({
                    callback: changePage,
                    length: pdfInfo.numberOfPages,
                    i: pageNum.current - 1,
                    title: "Pages",
                    viewed: []
                })}
                <div id="actionButtons" className="d-flex flex-row justify-content-evenly">
                    <Button variant="light" onClick={() => {
                        detectHeadingLevels({headings: headings.current, modifications: modifications.current});
                        setDetected(() => true);
                    }} disabled={detected}>
                        <MdOutlineAutoFixHigh size="1.5em"/><br/>
                        {detected ? "Heading Levels Detected" : "Detect Heading Levels"}
                    </Button>
                </div>
                <div id="headingList" className="d-flex flex-column">
                    <h3>Heading Structure</h3>
                    {headings.current.length > 0 ? <div id="headingView">
                        <ul>
                            {getHeadingList()}
                        </ul>
                    </div> : null}
                </div>
            </div>
            <PageView
                pageNum={pageNum.current}
                canvasRef={canvasRef}
                showHeadingLabels={showLabels}
                setShowHeadingLabels={setShowLabels}
                zoomFactor={zoomFactor}
                setZoomFactor={setZoomFactor}
                isLoading={headingsQuery.isLoading || pageImage.isLoading}
                showPageMenu={showPageMenu}
                setShowPageMenu={setShowPageMenu}
                pdf={pdf}
                pdfInfo={pdfInfo}
                updateStep={updateStep}
            />
        </div>
    );
};

export default Headings;
