import React, {useEffect, useRef, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from "react-query";
import {listConfig} from "./Config";
import Button from "react-bootstrap/Button";
import {BiListMinus} from "react-icons/bi";
import {getPageImagePdfJs, getTagType, updateTags} from "./APIcalls";
import {
    getMousePos, navigation
} from "./Tools";
import {drawFigures, drawLists, loadingCanvas} from "./Drawing";
import {changeList} from "./StructTreeActions";
import {AiOutlineDelete} from "react-icons/ai";

import {
    addListItem,
    checkList,
    deleteListLine,
    getAllMCRefs,
    listAsHtml,
    updateList
} from "./utils/UtilsList";
import Instructions from "./utils/Instructions";
import {errorDrawing, WarningNotComplete} from "./utils/ErrorMessages";
import {resizingX, resizingY, startResizeX} from "./utils/UtilsResize";
import PageView from "./PageView";

const Lists = ({pdf, pdfInfo, stepSelected, setStep, nextStep, menuSize, setMenuSize, instructionSize, setInstructionSize, showPageMenu, setShowPageMenu}) => {

    const lists = useRef([]);
    const [listI, setListI] = useState(-1);
    const pageNum = useRef(1);
    const [imageReady, setImageReady] = useState(true);

    // mouse position
    const startPos = useRef(null);
    const curPos = useRef(null);

    // canvas list
    const canvasRef = useRef(null);
    const ctx = useRef(null);
    const isDrawing = useRef(false);
    const selectedListElement = useRef(null);
    const [listElementSelected, setListElementSelected] = useState(false);
    const lines = useRef([]);
    const [render, setRender] = useState(0);
    const highlightListItem = useRef(null);

    const queryClient = useQueryClient();

    const viewed = useRef([true]);
    const [showWarning, setShowWarning] = useState(false);

    const [zoomFactor, setZoomFactor] = useState(1);

    // resize start values
    const startResizeValueMenu = useRef(null);
    const startResizeValueInstruction = useRef(null);

    // changing step
    useEffect(() => {
        if (stepSelected !== -1) {
            const checkedAll = viewed.current.every(t => t) || stepSelected < 5;
            updateStep(() => {
                if (checkedAll) setStep(stepSelected);
                else setShowWarning(true);
            });
        }
    }, [stepSelected]);

    // init canvas
    useEffect(() => {
        if (canvasRef.current) {
            ctx.current = canvasRef.current.getContext('2d');
        }
        listsQuery.refetch();
        viewed.current[listI] = true;
    }, []);


    // get the lists
    const listsQuery = useQuery({
        queryKey: ["Lists", pdfInfo.fileid],
        queryFn: () => getTagType({pdfInfo: pdfInfo, tagType: "L", pdf: pdf}),
        staleTime: 1000 * 60 * 5, // less fetching,
        enabled: pdfInfo.fileid != null,
        placeholderData: null,  // placeholder image
        onSuccess: data => {
            lists.current = data.map(list => checkList({list: list}));
            if (lists.current.length > 0) {
                pageNum.current = lists.current[0].rectangle.page;
                if (viewed.current.length === 1) {
                    viewed.current = Array.from({length: lists.current.length}, _ => false);
                }
                viewed.current[0] = true;
                setListI(0);
            }
        }
    });

    // get the image
    const pageImage = useQuery({
        queryKey: ["image", pdfInfo.fileid, pageNum.current],
        queryFn: () => getPageImagePdfJs({pdf: pdf, pageNum: pageNum.current, setImageReady: setImageReady}),
        staleTime: 1000 * 60 * 5, // less fetching,
        enabled: pdfInfo.fileid != null && pageNum.current > -1,
        placeholderData: null,  // placeholder image
        onSuccess: () => drawing()
    });

    // update tables
    const listMutation = useMutation({
        mutationFn: ({pdfInfo, modifications}) => updateTags({pdfInfo: pdfInfo, modifications: modifications})
    })

    drawing();

    function setHighlightListItem(li) {
        highlightListItem.current = li;
        requestAnimationFrame(drawing);
    }

    /**
     * Helper function for updating the step
     */
    function updateStep(onSuccessFunction) {
        return listMutation.mutate({pdfInfo: pdfInfo, modifications: lists.current.map(list => changeList({list: list}))}, {
            onSuccess: () => {
                queryClient.invalidateQueries(["Lists", pdfInfo.fileid]).then(() => {
                    if (onSuccessFunction != null) {
                        onSuccessFunction();
                    }
                });
            }
        });
    }


    /**
     * helper function for drawing
     */
    function drawing() {
        if (!listsQuery.isLoading && lists.current.length !== 0 && imageReady && ctx.current != null && pageImage.data != null) {
            try {
                canvasRef.current.height = pageImage.data.naturalHeight;
                canvasRef.current.width = pageImage.data.naturalWidth;
                lines.current = [];
                drawLists({
                    ctx: ctx.current,
                    list: lists.current[listI],
                    image: pageImage.data,
                    selectedListELement: selectedListElement.current,
                    lines: lines.current,
                    startPos: startPos.current,
                    curPos: curPos.current,
                    highlightListItem: highlightListItem.current
                });
            }
            catch (e) {
                errorDrawing({errorMessage: e});
            }
        }
        else if (listsQuery.isLoading || pageImage.isLoading) {
            loadingCanvas({ctx: ctx.current, canvasRef: canvasRef.current});
        }
        else if (imageReady && ctx.current != null && pageImage.data != null) {
            drawFigures({ctx:ctx.current, figure: null, image: pageImage.data});
        }
    }

    /**
     * select a list element or line
     * @param e event
     */
    function onMouseClick(e) {
        // do nothing during loading
        if (listsQuery.isLoading || pageImage.isLoading) return
        // get mouse position
        const mousePos = getMousePos({e: e, canvasRef: canvasRef.current});
        if (!lines.current.every(line => !(mousePos['y'] - listConfig.clickOffset < line && line < mousePos['y'] + listConfig.clickOffset))) {
            lines.current.every((line, lineI) => {
                selectedListElement.current = lineI;
                return !(mousePos['y'] - listConfig.clickOffset < line && line < mousePos['y'] + listConfig.clickOffset);
            });
        }
        // no list element selected
        if (selectedListElement.current === null) {
            setListElementSelected(false);
            drawing();
            return
        }
        // list element selected
        setListElementSelected((prevState) => {
            return prevState + 1;
        });
        drawing();
    }

    /**
     * start drawing
     * @param e event
     */
    function onMouseDown(e) {
        // do nothing during loading
        if (listsQuery.isLoading || pageImage.isLoading) return
        startPos.current = getMousePos({e: e, canvasRef: canvasRef.current})
        isDrawing.current = true;
        requestAnimationFrame(drawing);
    }

    /**
     * draw the rectangle
     * @param e event
     */
    function onMouseMove(e) {
        // do nothing during loading
        if (listsQuery.isLoading || pageImage.isLoading) return
        if (isDrawing.current) {
            curPos.current = getMousePos({e: e, canvasRef: canvasRef.current});
            requestAnimationFrame(drawing);
        }
    }

    /**
     * stop drawing create new struct element
     * @param e event
     */
    function onMouseUp(e) {
        // do nothing during loading
        if (listsQuery.isLoading || pageImage.isLoading) return
        if (isDrawing.current) {
            const addedList = addListItem({list: lists.current[listI], startPos: startPos.current, curPos: curPos.current, imageSize: [pageImage.data.naturalWidth, pageImage.data.naturalHeight]});
            if (addedList) {
                lists.current[listI] = addedList;
                setRender(prevState => prevState + 1);
                requestAnimationFrame(drawing);
            }
        }
        startPos.current = null;
        curPos.current = null;
        isDrawing.current = false;
    }

    function changeListI(i) {
        if (i !== listI) {
            pageNum.current = lists.current[i].rectangle.page;
            setListI(i);
        }
    }

    function resetList() {
        const elements = getAllMCRefs({element: lists.current[listI]});
        lists.current[listI] = {type: "L", id: lists.current[listI].id, children: [{type: "LI", children: [{type:"LBody", children: elements}]}]};
        updateList({list: lists.current[listI], positions: [0]});
        drawing();
        selectedListElement.current = null;
        setListElementSelected(false);
        setRender(prevState => prevState + 1);
    }

    return (
        <div className="d-flex flex-row flex-fill" id="lists"
             onMouseUp={() => startResizeValueMenu.current = null}
             onMouseMove={(e) => resizingX(e, startResizeValueMenu.current, setMenuSize)}
             style={startResizeValueMenu.current != null ? {userSelect: 'none'}: null}>
            <WarningNotComplete
                showWarning={showWarning} setShowWarning={setShowWarning}
                titleMessage={"Have you checked all lists?"}
                bodyMessage={<>It seems that you have not checked the following lists: {viewed.current.map((e, ei) => e ? null : ei + 1).filter(e => e != null).join(", ")} <br /> Do you want to check them before you continue?</>}
                currentTask={5}
                setTask={setStep}
                taskSelected={stepSelected}/>
            <div className="d-flex flex-column" id="menu" style={{width: menuSize + "px"}}
                 onMouseUp={() => startResizeValueInstruction.current = null}
                 onMouseMove={(e) => resizingY(e, startResizeValueInstruction.current, setInstructionSize)}>
                <div id="resizeHandleX" onMouseDown={(e) => startResizeValueMenu.current = startResizeX(e)}></div>
                <Instructions
                    title="Step 5: Define List Structure"
                    bodyText="In this step, you can adjust the list structure of all lists in your document.
                    Each list item must be separated in the page view with a line.
                    The list below illustrates the assigned list structure.
                    You can nest lists by changing the list level via the drop down menu of the list item."
                    buttons={[
                        ["Combine List Items", "Select a line in the page view and select Combine List Item to combine it with the other list item."],
                        ["Delete All List Items", "Remove all list items."]]}
                    nextStep={nextStep}
                    instructionSize={instructionSize}
                    startResizeValueInstruction={startResizeValueInstruction}
                />
                {navigation({
                    callback: changeListI,
                    length: lists.current.length,
                    i: listI,
                    title: "Lists",
                    viewed: viewed.current
                })}
                <div id="actionButtons" className="d-flex flex-row justify-content-evenly">
                    <Button variant="light" onClick={() => {
                        deleteListLine({
                            list: lists.current[listI],
                            selectedLine: selectedListElement.current,
                            imageSize: [pageImage.data.naturalWidth, pageImage.data.naturalHeight],
                            startPoint: 0
                        });
                        selectedListElement.current = null;
                        setListElementSelected(false);
                    }} disabled={!(typeof selectedListElement.current === 'number')}>
                        <BiListMinus size="1.5em"/><br/> Combine List Items
                    </Button>
                    <Button variant="light" onClick={resetList}>
                        <AiOutlineDelete size="1.5em"/> <br/>
                        Delete All List Items
                    </Button>
                </div>
                <div className="d-flex flex-column justify-content-center" id="listView">
                    <h3>{lists.current.length > 0 ? <>List {listI + 1}/{lists.current.length}</> : "No list found in the document"}</h3>
                    <ul id="listAsHtml">
                        {listAsHtml({
                            list: lists.current[listI],
                            setRender: setRender,
                            baseList: lists.current[listI],
                            drawing: drawing,
                            setHighlightListItem: setHighlightListItem
                        })}
                    </ul>
                </div>
            </div>
            <PageView
                pageNum={pageNum.current}
                canvasRef={canvasRef}
                onMouseClick={onMouseClick}
                onMouseDown={onMouseDown}
                onMouseMove={onMouseMove}
                onMouseUp={onMouseUp}
                zoomFactor={zoomFactor}
                setZoomFactor={setZoomFactor}
                isLoading={listsQuery.isLoading || pageImage.isLoading}
                showPageMenu={showPageMenu}
                setShowPageMenu={setShowPageMenu}
                pdf={pdf}
                pdfInfo={pdfInfo}
                updateStep={updateStep}
            />
        </div>
    );
};

export default Lists;
