import React from 'react';

function Legal() {
    return <div id="legalHelp">
        <h2>Legal Information</h2>
        <h3>Collection and Use of Information</h3>
        <p>Upon accessing the website, various access data (e.g. IP address, date and time of access, name of accessed file, etc.) is stored.
        No personal analysis takes place. For statistical purposes, data is analyzed anonymously, such as to determine how many hits the portal had on a given day.
        This data is treated with strict confidentiality and is not shared or provided to third parties unless this is required by applicable law and specifically by the responsible criminal prosecution authority.</p>
        <h3>Cookies</h3>
        <p>When using the portal, the user's computer stores a small file ("cookies"). This file is used only to anonymously collect visitor statistics.</p>
        <h3>Data Security</h3>
        <p>Secure storage of personal data (e.g. regarding newsletter subscriptions) is guaranteed.</p>
        <h3>Image Copyrights</h3>
        <p>All photos and images used on this website are protected by copyright. All use or dissemination of the photos on this website require the express consent of the ZHAW - ICT Accessibility Lab. ZHAW - ICT Accessibility Lab reserves the right to take legal action in the event that the photos are used without consent.</p>
        <h3>Liability</h3>
        <p>This website is for information purposes only. ZHAW - ICT Accessibility Lab is not liable for the completeness and accuracy of the published information or for information from third parties that is accessible via the PAVE site.</p>
    </div>
}

export default Legal;
