import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App";
import { QueryClient, QueryClientProvider} from "react-query";
import { ReactQueryDevtools} from "react-query/devtools";
import {HashRouter} from "react-router-dom";

const queryClient = new QueryClient()

ReactDOM.createRoot(document.getElementById("root")).render(
    //<React.StrictMode>
    <HashRouter>
        <QueryClientProvider client={queryClient}>
            <App />
            <ReactQueryDevtools />
        </QueryClientProvider>
    </HashRouter>
    //</React.StrictMode>
);
