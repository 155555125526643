import React, {useEffect, useState} from "react";
import {getStructTree} from "../APIcalls";
import {useQuery, useQueryClient} from "react-query";
import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {IoMdPricetag} from "react-icons/io";
import {CollapsibleListItem} from "./CollapsibleListItem";
import {MdTextSnippet} from "react-icons/md";


export function StructTreeVisualizer({showStructTreeVisualizer, setShowStructTreeVisualizer, pdf, pdfInfo, pageNum}) {

    const [renderI, setRenderI] = useState(0);

    const queryClient = useQueryClient()

    useEffect(() => {
        return () => {
            queryClient.invalidateQueries({ queryKey: ["structTree", pdfInfo.fileid, pageNum] })
        };
    }, [showStructTreeVisualizer]);


    // get the struct tree
    const structTreeQuery = useQuery({
        queryKey: ["structTree", pdfInfo.fileid, pageNum],
        queryFn: () => getStructTree({pdfInfo: pdfInfo, pdf: pdf, pageNum: pageNum}),
        staleTime: 1000 * 60 * 5, // less fetching,
        enabled: pdfInfo.fileid != null,
        placeholderData: null,  // placeholder image
        onSuccess: data => {
            setRenderI(prevState => prevState + 1);
        }
    });

    function convertStructTree(structElements) {
        if (structElements == null) {
            return null;
        }
        return structElements.map(element => {
            if (element.type === "MCRef") {
                if (element.altText !== ":") {
                    return <li className="mcref"><MdTextSnippet size="1.5em"/>{element.altText}</li>;
                } else {
                    return <li className="mcref"><MdTextSnippet size="1.5em"/>{element.text}</li>;
                }
            } else {
                return <CollapsibleListItem tagName={element.type} content={convertStructTree(element.children)}></CollapsibleListItem>
            }
        })
    }

    return <Modal
        show={showStructTreeVisualizer}
        aria-labelledby="contained-modal-title"
        onHide={() => setShowStructTreeVisualizer(false)}
        id="structTreeVisualizerModal"
    >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title">
                {"Structure Tree of Page " + pageNum}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <ul id="structTreeVisualizer">
                {structTreeQuery.data != null ? convertStructTree(structTreeQuery.data.structTree) : null}
            </ul>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={() => setShowStructTreeVisualizer(false)}>Close</Button>
        </Modal.Footer>
    </Modal>
}
