import {transformRectangle, getMidPoint} from './Tools'
import {
    colors,
    readingOrderConfig,
    tagTypes,
    listConfig,
    headingColors,
    bboxLineWidth,
    tableConfig, regionConfig, font
} from "./Config";
import {getReadingOrderByPoint} from "./utils/UtilsReadingOrder";
import {getTableDimensions} from "./utils/UtilsTables";

function drawTextLabel({ctx, text, rect}) {
    ctx.beginPath();
    ctx.lineWidth = 1;
    ctx.fillStyle = '#ffffff';
    ctx.strokeStyle = "#000000";
    ctx.font = font;
    const textSize = ctx.measureText(text);
    ctx.fillRect(rect.x + rect.w + 2, rect.y + 6, -textSize.width - 4, -34);
    ctx.strokeRect(rect.x + rect.w + 2, rect.y + 6, -textSize.width - 4, -34)
    ctx.stroke();
    ctx.fillStyle = '#000000';
    ctx.fillText(text, rect.x + rect.w - textSize.width, rect.y);
    ctx.stroke();
}


function drawStructElem({structElem, ctx, showRegionLabels, selectedStructElem, imageSize, showArtifacts}) {
    if (structElem.type === "Artifact" && !showArtifacts) return;
    ctx.lineWidth = bboxLineWidth;
    let rect = transformRectangle({rect:structElem.rectangle, imageSize: imageSize});
    if (!regionConfig.doNotShow.includes(structElem.type)) {
        if (selectedStructElem == null || !selectedStructElem.includes(structElem)) {
            ctx.strokeStyle = colors[structElem.type];
            let text = tagTypes[structElem.type];
            if (text == null) {
                ctx.strokeStyle = colors['Not defined'];
                text = structElem.type;
            }
            ctx.strokeRect(rect.x, rect.y, rect.w, rect.h);
            if (showRegionLabels) {
                drawTextLabel({ctx:ctx, rect:rect, text: text});
            }
        }
    }
    if (structElem.children !== null) {
        structElem.children.forEach(child => {
            if (child.type !== "MCRef") {
                drawStructElem({structElem: child, ctx: ctx, showRegionLabels: showRegionLabels, selectedStructElem: selectedStructElem, imageSize: imageSize, showArtifacts: showArtifacts});
            }
        })
    }
}

function iou(drawingRectangle, operator) {
    const operatorArea = operator.w * operator.h;
    const x1 = Math.max(drawingRectangle.x, operator.x);
    const x2 = Math.min(drawingRectangle.x + drawingRectangle.w, operator.x + operator.w);
    const y1 = Math.max(drawingRectangle.y, operator.y);
    const y2 = Math.min(drawingRectangle.y + drawingRectangle.h, operator.y + operator.h);
    if (x1 >= x2 || y1 >= y2) {
        return 0;
    }
    return (x2 - x1) * (y2 - y1) / operatorArea;
}

/**
 * drawing function for regions
 * @param ctx canvas element
 * @param image backgorund image
 * @param notMarked not marked operators
 * @param structTree struct tree
 * @param newStructElemOperators operators of new struct element
 * @param selectedStructElem selected struct element
 * @param startPos start x and y value of new struct element
 * @param curPos current x and y value of courser
 * @param showRegionLabels if region labels should be shown
 * @param changeBoxSize if the box size should be changed
 * @param showArtifacts if the artifacts should be shown
 */
function drawRegions({ctx, image, notMarked, structTree, newStructElemOperators, selectedStructElem, startPos, curPos, showRegionLabels, changeBoxSize, showArtifacts}) {
    // draw image
    const imageSize = [image.naturalWidth, image.naturalHeight];
    ctx.clearRect(0, 0, imageSize[0], imageSize[1]);
    ctx.drawImage(image, 0, 0, imageSize[0], imageSize[1]);

    //highlight not marked elements
    ctx.fillStyle = regionConfig.notMarkedElements;
    notMarked.current.forEach(operator => {
        const rect = transformRectangle({rect:operator.rectangle, imageSize: imageSize});
        return ctx.fillRect(rect.x, rect.y, rect.w, rect.h);
    });
    ctx.stroke();

    // draw structTree
    structTree.forEach(structElem => {
        drawStructElem({structElem: structElem, ctx: ctx, showRegionLabels: changeBoxSize ? false : showRegionLabels, selectedStructElem: selectedStructElem, imageSize: imageSize, showArtifacts});
    });
    ctx.lineWidth = 1;

    // highlight selected struct element
    if (selectedStructElem.length !== 0) {
        selectedStructElem.forEach(elem => {
            ctx.strokeStyle = colors[elem.type];
            ctx.lineWidth = 5;
            let rect = transformRectangle({rect:elem.rectangle, imageSize: imageSize});

            // modify rectangle if it is resized
            if (changeBoxSize) {
                if (changeBoxSize === 1) {
                    rect.w = rect.w - curPos.x + rect.x;
                    rect.h = rect.h - curPos.y + rect.y;
                    rect.x = curPos.x;
                    rect.y = curPos.y;
                }
                if (changeBoxSize === 2) {
                    rect.h = rect.h - curPos.y + rect.y;
                    rect.y = curPos.y;
                }
                if (changeBoxSize === 3) {
                    rect.w = curPos.x - rect.x;
                    rect.h = rect.h - curPos.y + rect.y;
                    rect.y = curPos.y;
                }
                if (changeBoxSize === 4) {
                    rect.w = curPos.x - rect.x;
                }
                if (changeBoxSize === 5) {
                    rect.w = curPos.x - rect.x;
                    rect.h = curPos.y - rect.y;
                }
                if (changeBoxSize === 6) {
                    rect.h = curPos.y - rect.y;
                }
                if (changeBoxSize === 7) {
                    rect.w = rect.w - curPos.x + rect.x;
                    rect.x = curPos.x;
                    rect.h = curPos.y - rect.y;
                }
                if (changeBoxSize === 8) {
                    rect.w = rect.w - curPos.x + rect.x;
                    rect.x = curPos.x;
                }
            }

            // draw rectangle
            ctx.strokeRect(rect.x, rect.y, rect.w, rect.h);

            // draw circles for changing size
            if (selectedStructElem.length === 1) {
                ctx.lineWidth = 1;
                ctx.strokeStyle = "#000000";
                ctx.fillStyle = "#000000";
                ctx.beginPath();
                ctx.fillRect(rect.x - 5, rect.y -5, 10, 10);
                ctx.fillRect(rect.x + rect.w/2 - 5, rect.y -5, 10, 10);
                ctx.fillRect(rect.x + rect.w - 5, rect.y -5, 10, 10);
                ctx.fillRect(rect.x + rect.w - 5, rect.y + rect.h/2 -5, 10, 10);
                ctx.fillRect(rect.x + rect.w - 5, rect.y + rect.h -5, 10, 10);
                ctx.fillRect(rect.x + rect.w/2 - 5, rect.y + rect.h -5, 10, 10);
                ctx.fillRect(rect.x - 5, rect.y + rect.h -5, 10, 10);
                ctx.fillRect(rect.x - 5, rect.y + rect.h/2 -5, 10, 10);
                ctx.closePath();
                ctx.stroke();
            }

            // highlight operators
            newStructElemOperators.current = notMarked.current.filter(marking => {
                const rectNotMarked = transformRectangle({rect:marking.rectangle, imageSize: imageSize});
                if (iou(rect, rectNotMarked) > 0.6) {
                    ctx.fillStyle = regionConfig.markedElements
                    ctx.fillRect(rectNotMarked.x, rectNotMarked.y, rectNotMarked.w, rectNotMarked.h);
                    return true;
                }
                return false;
            })
            newStructElemOperators.current = newStructElemOperators.current.map(o => {
                return {children: [], id: o.id, rectangle: o.rectangle, text: o.text, type: "MCRef"};
            });

            elem.children.forEach((child) => {
                if (child.type !== "MCRef") return
                const rectOldOperators = transformRectangle({rect: child.rectangle, imageSize: imageSize});
                if (iou(rect, rectOldOperators) > 0.6) {
                    ctx.fillStyle = regionConfig.markedElements;
                    newStructElemOperators.current.push(child);
                }
                else {
                    ctx.fillStyle = regionConfig.notMarkedElements;
                }
                ctx.fillRect(rectOldOperators.x, rectOldOperators.y, rectOldOperators.w, rectOldOperators.h);
            })
        })
    }
    else if (startPos != null) {
        // draw new structElement
        ctx.strokeStyle = colors["P"];
        // draw rectangle
        ctx.strokeRect(startPos.x, startPos.y, curPos.x - startPos.x, curPos.y - startPos.y);
        // highlight selected operators
        newStructElemOperators.current = notMarked.current.filter(marking => {
            const rect = transformRectangle({rect:marking.rectangle, imageSize: imageSize});
            if (iou({x: Math.min(startPos.x, curPos.x), y: Math.min(startPos.y, curPos.y), w: Math.abs(curPos.x - startPos.x), h: Math.abs(curPos.y - startPos.y)}, rect) > 0.6) {
                ctx.fillStyle = regionConfig.markedElements
                ctx.fillRect(rect.x, rect.y, rect.w, rect.h);
                return true;
            }
            return false;
        })
    }
}

function drawReadingOrder({ctx, image, readingOrder, structTree, mouseMove, drawingReadingOrder, elementHighlighted, showRegionLabels}) {
    // draw image
    const imageSize = [image.naturalWidth, image.naturalHeight];
    ctx.clearRect(0, 0, imageSize[0], imageSize[1]);
    ctx.drawImage(image, 0, 0, imageSize[0], imageSize[1]);

    // if drawing reading order
    if (drawingReadingOrder) {
        ctx.strokeStyle = readingOrderConfig.readingOrderColor;
        ctx.fillStyle = readingOrderConfig.readingOrderColor;
        ctx.beginPath();
        mouseMove.forEach((step, i) => {
            if (i === 0) {
                ctx.moveTo(step.pos.x, step.pos.y);
            }
            else {
                ctx.lineTo(step.pos.x, step.pos.y);
                if (step.element == null) {
                    const foundElement = getReadingOrderByPoint({structTree: structTree, point: step.pos, imageSize: imageSize});
                    if (foundElement !== null && !readingOrder.some(e => e === foundElement)) {
                        readingOrder.push(foundElement);
                        step.element = foundElement;
                    }
                }
            }
        })
        ctx.stroke();
        // draw green boxes for selected elements and red for not selected elements
        structTree.forEach(elem => {
            if (readingOrder.some(e => e === elem)) {
                ctx.strokeStyle = "#00ff00";
                ctx.fillStyle = "rgba(0,255,0,0.22)";
                const rect = transformRectangle({rect: elem.rectangle, imageSize: imageSize});
                ctx.fillRect(rect.x, rect.y, rect.w, rect.h);
                ctx.strokeRect(rect.x, rect.y, rect.w, rect.h);
                ctx.stroke();
            }
            else {
                ctx.strokeStyle = "#ff0000";
                ctx.fillStyle = "rgba(255,0,0,0.22)";
                const rect = transformRectangle({rect: elem.rectangle, imageSize: imageSize});
                ctx.fillRect(rect.x, rect.y, rect.w, rect.h)
                ctx.stroke();
            }
        })

    }

    // if not drawing
    else {
        // draw boxes for structElems
        readingOrder.forEach((elem, i) => {
            ctx.strokeStyle = colors[elem.type];
            ctx.lineWidth = bboxLineWidth;
            const rect = transformRectangle({rect: elem.rectangle, imageSize: imageSize});
            ctx.strokeRect(rect.x, rect.y, rect.w, rect.h);
            if (elementHighlighted === i) {
                ctx.fillStyle = "rgba(0,255,0,0.20)";
                ctx.fillRect(rect.x, rect.y, rect.w, rect.h);
                ctx.stroke();
            }
            if (showRegionLabels) {
                drawTextLabel({ctx: ctx, text: tagTypes[elem.type], rect: rect});
            }
        })
        // draw reading order
        for (let i = 0; i < readingOrder.length-1; i++) {
            let rect1 = getMidPoint({rect: readingOrder[i].rectangle, imageSize: imageSize});
            let rect2 = getMidPoint({rect: readingOrder[i+1].rectangle, imageSize: imageSize});
            // draw the line
            ctx.beginPath();
            ctx.strokeStyle = readingOrderConfig.readingOrderColor;
            ctx.lineWidth = 3;
            ctx.moveTo(rect1.x, rect1.y);
            const dx = rect1.x - rect2.x;
            const dy = rect1.y - rect2.y;
            if (Math.abs(dx) > Math.abs(dy)) {
                const direction = Math.sign(dx)
                ctx.bezierCurveTo(rect1.x, rect1.y + 30 * direction, rect2.x, rect2.y + 30 * direction, rect2.x, rect2.y);
            }
            else {
                const direction = Math.sign(dy)
                ctx.bezierCurveTo(rect1.x + 30 * direction, rect1.y, rect2.x + 30 * direction, rect2.y, rect2.x, rect2.y);
            }
            ctx.stroke();

            // draw the arc
            ctx.beginPath();
            ctx.moveTo(rect1.x, rect1.y);
            if (i === 0) {
                ctx.strokeStyle = readingOrderConfig.readingOrderStartColor;
                ctx.fillStyle = "#ffffff";
                ctx.fillRect(rect1.x - readingOrderConfig.imagePointRadius, rect1.y - readingOrderConfig.imagePointRadius, 2*readingOrderConfig.imagePointRadius, 2*readingOrderConfig.imagePointRadius);
                ctx.strokeRect(rect1.x - readingOrderConfig.imagePointRadius, rect1.y - readingOrderConfig.imagePointRadius, 2*readingOrderConfig.imagePointRadius, 2*readingOrderConfig.imagePointRadius);
            }
            else {
                ctx.strokeStyle = readingOrderConfig.readingOrderColor;
                ctx.fillStyle = readingOrderConfig.readingOrderColor;
                ctx.arc(rect1.x, rect1.y, readingOrderConfig.imagePointRadius, 0, 2 * Math.PI);
                ctx.fill();
            }
        }
        if (readingOrder.length > 0) {
            let rect = getMidPoint({rect: readingOrder[readingOrder.length-1].rectangle, imageSize: imageSize});
            ctx.beginPath();
            ctx.fillStyle = readingOrderConfig.readingOrderColor;
            ctx.fillRect(rect.x - readingOrderConfig.imagePointRadius, rect.y - readingOrderConfig.imagePointRadius, 2*readingOrderConfig.imagePointRadius, 2*readingOrderConfig.imagePointRadius);
            ctx.stroke();
            ctx.closePath();
        }
        // add numbers
        readingOrder.forEach((elem, i) => {
            const rect = getMidPoint({rect: readingOrder[i].rectangle, imageSize: imageSize});
            if (i === 0) ctx.fillStyle = "#000000";
            else ctx.fillStyle = "#ffffff";
            ctx.font = font;
            const text = ctx.measureText(i+1);
            ctx.fillText(i + 1, rect.x - text.width/2, rect.y + 10);
            ctx.stroke();
        })
    }


}

function drawList({ctx, list, imageSize, selectedListELement, lines, rect, nesting, highlightListItem}) {
    // draw line for each list item
    list.children.forEach((li, i) => {
        const rectLI = transformRectangle({rect: li.rectangle, imageSize: imageSize});
        // add line
        if (i !== 0 || nesting !== 0) {
            lines.push(rectLI.y)
            if ((lines.length - 1) === selectedListELement) {
                ctx.strokeStyle = listConfig.highlightingColorLine;
            } else {
                ctx.strokeStyle = listConfig.lineColor;
            }
            ctx.lineWidth = 3;
            ctx.beginPath();
            ctx.moveTo(rect.x, rectLI.y)
            ctx.lineTo(rect.x + rect.w, rectLI.y)
            ctx.stroke();
        }
        // if hover
        if (highlightListItem === li) {
            ctx.fillStyle = listConfig.listItemSelectColor;
            ctx.fillRect(rect.x, rectLI.y, rect.w, rectLI.h);
        }
        // check if nested list
        li.children.forEach(liE => {
            if (liE.type === "LBody") {
                liE.children.forEach(lbE => {
                    if (lbE.type === "L") {
                        drawList({
                            ctx: ctx,
                            list: lbE,
                            imageSize: imageSize,
                            selectedListELement: selectedListELement,
                            lines: lines,
                            rect: rect,
                            nesting: nesting + 1,
                            highlightListItem: highlightListItem
                        })
                    }
                })
            }
        })
    })
}

function drawLists({ctx, list, image, selectedListELement, lines, startPos, curPos, highlightListItem}) {
    // draw image
    const imageSize = [image.naturalWidth, image.naturalHeight];
    ctx.clearRect(0, 0, image.naturalWidth, image.naturalHeight);
    ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight);

    const rect = transformRectangle({rect: list.rectangle, imageSize: imageSize}); // get list rectangle

    // highlight list
    highlightElement({ctx: ctx, rect: rect, imageSize: imageSize});
    drawList({
        ctx: ctx,
        list: list,
        imageSize: imageSize,
        selectedListELement: selectedListELement,
        lines: lines,
        rect: rect,
        nesting: 0,
        highlightListItem: highlightListItem
    })
    // if drawing
    if (startPos != null && curPos != null) {
        ctx.strokeStyle = listConfig.drawingColor;
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.moveTo(startPos.x, startPos.y)
        ctx.lineTo(curPos.x, curPos.y)
        ctx.stroke();
    }
}

function loadingCanvas({ctx, canvasRef}) {
    if (canvasRef == null) return
    ctx.clearRect(0, 0, canvasRef.width, canvasRef.height);
    ctx.fillStyle = "rgba(200,200,200,0.2)";
    ctx.fillRect(0, 0, canvasRef.width, canvasRef.height);
}

function drawHeadings({ctx, image, headings, page, highlighted, showHeadingLabels}) {
    // draw image
    const imageSize = [image.naturalWidth, image.naturalHeight];
    ctx.clearRect(0, 0, imageSize[0], imageSize[1]);
    ctx.drawImage(image, 0, 0, imageSize[0], imageSize[1]);

    headings.forEach(heading =>{
        if (page === heading.rectangle.page) {
            ctx.strokeStyle = headingColors[heading.type];
            ctx.lineWidth = bboxLineWidth;
            const rect = transformRectangle({rect: heading.rectangle, imageSize: imageSize});
            if (highlighted === heading) {
                ctx.fillStyle = headingColors['Highlight'];
                ctx.fillRect(rect.x, rect.y, rect.w, rect.h)
            }
            ctx.strokeRect(rect.x, rect.y, rect.w, rect.h);
            if (showHeadingLabels) {
                drawTextLabel({ctx: ctx, text: heading.type, rect: rect});
            }
        }
    });
}

function drawTables({ctx, image, table, tableElementSelected, mouseMove, highlightCell}) {
    // draw image
    const imageSize = [image.naturalWidth, image.naturalHeight];
    ctx.clearRect(0, 0, imageSize[0], imageSize[1]);
    ctx.drawImage(image, 0, 0, imageSize[0], imageSize[1]);

    const borderSize = 25;
    const tableRect = transformRectangle({rect: table.rectangle, imageSize});
    let [rowDimensions, columnDimensions] = getTableDimensions({table: table, imageSize: imageSize});
    let rowY = [tableRect.y];
    let columnX = [tableRect.x];

    // highlight element
    highlightElement({ctx: ctx, rect: tableRect, imageSize: imageSize});

    // draw border
    ctx.fillStyle = tableConfig.borderColor;
    ctx.fillRect(tableRect.x - borderSize, tableRect.y -borderSize, tableRect.w + borderSize, borderSize);
    ctx.fillRect(tableRect.x - borderSize, tableRect.y -borderSize, borderSize, tableRect.h + borderSize);
    // draw first lines
    ctx.beginPath();
    ctx.strokeStyle = tableConfig.lineColor;
    ctx.moveTo(tableRect.x -borderSize, tableRect.y);
    ctx.lineTo(tableRect.x + tableRect.w, tableRect.y);
    ctx.moveTo(tableRect.x, tableRect.y - borderSize);
    ctx.lineTo(tableRect.x, tableRect.y + tableRect.h);
    ctx.stroke();
    // draw row lines
    for (let i = 0; i < rowDimensions.length; i++) {
        if (i < rowDimensions.length -1) {
            if (tableElementSelected != null && tableElementSelected.rowLine - 1 === i) ctx.strokeStyle = tableConfig.lineHighlightColor;
            else ctx.strokeStyle = tableConfig.lineColor;
            const y = (rowDimensions[i].bottom + rowDimensions[i+1].top) / 2;
            rowY.push(y);
            ctx.beginPath();
            ctx.moveTo(tableRect.x -borderSize, y);
            ctx.lineTo(tableRect.x + tableRect.w, y);
            ctx.stroke();
        }
        else rowY.push(tableRect.y + tableRect.h);
        const text  = String(i + 1);
        const measureText = ctx.measureText(text);
        ctx.fillStyle = "#000000";
        ctx.font = font;
        ctx.font = "20px Arial"
        ctx.fillText(text, tableRect.x - 12.5 - measureText.width/2, (rowY[i] + rowY[i + 1])/2 + 7);
    }
    // draw column lines
    for (let i = 0; i < columnDimensions.length; i++) {
        if (i < columnDimensions.length - 1) {
            if (tableElementSelected != null && tableElementSelected.columnLine - 1 === i) ctx.strokeStyle = tableConfig.lineHighlightColor;
            else ctx.strokeStyle = tableConfig.lineColor;
            const x = (columnDimensions[i].right + columnDimensions[i+1].left) / 2;
            columnX.push(x);
            ctx.beginPath();
            ctx.moveTo(x, tableRect.y - borderSize);
            ctx.lineTo(x, tableRect.y + tableRect.h);
            ctx.stroke();
        }
        else columnX.push(tableRect.x + tableRect.w);
        const text  = String.fromCharCode(65 + i);
        const measureText = ctx.measureText(text);
        ctx.fillStyle = "#000000";
        ctx.font = "20px Arial"
        ctx.fillText(text, (columnX[i] + columnX[i + 1])/2 - measureText.width/2, tableRect.y - 5);
    }
    // draw cell colors
    table.processedTable.forEach((row, rowI) => {
        row.forEach((column, columnI) => {
            if (column.type === "TH") ctx.fillStyle = tableConfig.headerColor;
            else ctx.fillStyle = tableConfig.dataColor;
            if (rowI === highlightCell[0] && columnI === highlightCell[1]) ctx.fillStyle = tableConfig.highlightingColor;
            ctx.fillRect(columnX[columnI], rowY[rowI], columnX[columnI + 1] - columnX[columnI], rowY[rowI + 1] - rowY[rowI]);
        })
    })
    // draw new line
    if (mouseMove != null) {
        ctx.strokeStyle = tableConfig.lineHighlightColor;
        ctx.beginPath();
        ctx.moveTo(mouseMove.startX, mouseMove.startY);
        ctx.lineTo(mouseMove.endX, mouseMove.endY);
        ctx.stroke();
    }
    return {columnX: columnX, rowY: rowY};
}

function drawFigures({ctx, image, figure}) {
    // draw image
    const imageSize = [image.naturalWidth, image.naturalHeight];
    ctx.clearRect(0, 0, imageSize[0], imageSize[1]);
    ctx.drawImage(image, 0, 0, imageSize[0], imageSize[1]);

    if (figure) {
        highlightElement({ctx: ctx, rect: transformRectangle({rect: figure.rectangle, imageSize: imageSize}), imageSize: imageSize});
    }
}

function highlightElement({ctx, rect, imageSize}) {
    ctx.fillStyle = 'rgba(0,0,0,0.1)';
    ctx.fillRect(0, 0, imageSize[0], rect.y);
    ctx.fillRect(0, rect.y, rect.x, rect.h);
    ctx.fillRect(rect.x + rect.w, rect.y, imageSize[0] - rect.x - rect.w, rect.h);
    ctx.fillRect(0, rect.y + rect.h, imageSize[0], imageSize[1] - rect.y -rect.h);
}

function drawListItems({ctx, list, rect, nesting, imageSize}) {
    const offset = 2;
    list.children.forEach((li, liI) => {
        const rectLi = transformRectangle({rect: li.rectangle, imageSize: imageSize});
        ctx.strokeStyle = "#ff0000";
        // top line
        ctx.beginPath();
        ctx.moveTo(rect.x, rectLi.y);
        ctx.lineTo(rect.x + rect.w, rectLi.y);
        ctx.stroke();
        let text = "";
        if (nesting === "") text = liI + 1;
        else text = nesting + "." + (liI + 1);
        const textSize = ctx.measureText(text);
        ctx.fillStyle = "#ffffff";
        ctx.fillRect(rectLi.x, rectLi.y, textSize.width + 2 * offset, 2 * offset + textSize.fontBoundingBoxAscent + textSize.fontBoundingBoxDescent)
        ctx.fillStyle = "#ff0000";
        ctx.font = font;
        ctx.fillText(text, rectLi.x + offset, rectLi.y  + textSize.fontBoundingBoxAscent);
        li.children.forEach(liE => {
            liE.children.forEach(e => {
                if (e.type === "L") {
                    drawListItems({ctx: ctx, list: e, rect: rect, nesting: text, imageSize: imageSize});
                }
            });
        });
    })
}

function drawValidation({ctx, image, structTree, showRegionLabels, showArtifacts}) {
    // draw image
    const imageSize = [image.naturalWidth, image.naturalHeight];
    ctx.clearRect(0, 0, imageSize[0], imageSize[1]);
    ctx.drawImage(image, 0, 0, imageSize[0], imageSize[1]);

    if (structTree == null) return;

    structTree.forEach((structElem, structElemI) => {
        if (structElem.type === "Artifact" && !showArtifacts) return;
        const rect = transformRectangle({rect: structElem.rectangle, imageSize: imageSize});
        // draw list items
        if (structElem.type === "L") {
            drawListItems({ctx: ctx, list: structElem, rect: rect, nesting: "", imageSize: imageSize});
        }

        // overdraw figures and formula with alt text
        if (structElem.type === "Figure" || structElem.type === "Formula") {
            ctx.fillStyle = "#ffffff";
            ctx.fillRect(rect.x + 1, rect.y + 1, rect.w - 2, rect.h - 2);
            ctx.stroke();
            ctx.font = font;
            let text = "";
            let textSize = ctx.measureText(text);
            let lineCorrection = textSize.fontBoundingBoxAscent + textSize.fontBoundingBoxDescent;
            let alternativeText = structElem.altText;
            if (alternativeText === "" || alternativeText == null) alternativeText = "No Alternative Text!"
            for (const c of alternativeText) {
                textSize = ctx.measureText(text + c);
                if (textSize.width > rect.w - 10) {
                    ctx.fillStyle = "#ff0000";
                    ctx.fillText(text, rect.x + 5, rect.y + lineCorrection);
                    ctx.stroke();
                    text = "";
                    lineCorrection += textSize.fontBoundingBoxAscent + textSize.fontBoundingBoxDescent;
                    if ((lineCorrection + textSize.fontBoundingBoxAscent + textSize.fontBoundingBoxDescent) > rect.h) break;
                }
                text += c;
            }
            if (text !== "" && ((lineCorrection + textSize.fontBoundingBoxAscent + textSize.fontBoundingBoxDescent) <= rect.h)) {
                ctx.fillStyle = "#ff0000";
                ctx.fillText(text, rect.x + 5, rect.y + lineCorrection);
                ctx.stroke();
            }
        }

        // draw tables
        if (structElem.type === "Table") {
            let columns = [];
            structElem.children.forEach((tR, tRI) => {
                const rectTR = transformRectangle({rect: tR.rectangle, imageSize: imageSize});
                ctx.beginPath();
                ctx.strokeStyle = "#ff0000";
                ctx.moveTo(rect.x, rectTR.y + rectTR.h);
                ctx.lineTo(rect.x + rect.w, rectTR.y + rectTR.h);
                ctx.stroke();
                tR.children.forEach((cell, columnI) => {
                    const cellRect = transformRectangle({rect: cell.rectangle, imageSize: imageSize});
                    if (tRI === 0) columns[columnI] = {start: cellRect.x, end: cellRect.x + cellRect.w};
                    else {
                        columns[columnI].start = Math.min(columns[columnI].start, cellRect.x);
                        columns[columnI].end = Math.max(columns[columnI].end, cellRect.x + cellRect.w);
                    }
                })
            });
            for (let i = 1; i < columns.length; i++) {
                const x = (columns[i-1].end + columns[i].start) / 2;
                ctx.beginPath();
                ctx.strokeStyle = "#ff0000";
                ctx.moveTo(x, rect.y);
                ctx.lineTo(x, rect.y + rect.h);
                ctx.stroke();
            }
        }

        // draw bounding boxes for regions
        ctx.strokeStyle = "#000000";
        ctx.lineWidth = bboxLineWidth;
        if (structElem.type in colors) ctx.strokeStyle = colors[structElem.type];
        ctx.strokeRect(rect.x, rect.y, rect.w, rect.h);
        if (showRegionLabels) {
            let text = (tagTypes[structElem.type] === "Header" ? structElem.type : tagTypes[structElem.type]);
            if (structElem.type !== "Artifact") text = "[" + (structElemI + 1) + "] " + text;
            drawTextLabel({ctx: ctx, rect: rect, text: text});
        }

    })
}

export {drawRegions, drawReadingOrder, drawLists, loadingCanvas, drawHeadings, drawTables, drawFigures, drawValidation}
