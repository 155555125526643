import React, {useEffect, useState} from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {mathExpressions, operators, symbols} from "./listOfSpecialCharacters";
import Button from "react-bootstrap/Button";
import katex from "katex";

// https://blog.logrocket.com/how-to-build-tab-component-react/
export const VirtualKeyboard = ({mathInputRef}) => {

    const [search, setSearch] = useState('');

    function renderLatex(latexString){
        while (latexString.indexOf('{#0}') !== -1){
            latexString = latexString.replace('{#0}', 'x')}
        try {

            return { __html: katex.renderToString(latexString, {
                    throwOnError: false,
                    displayMode: true,
                    errorColor: '#cc0000',
                    macros: {"\\R": "\\mathbb{R}"}, // \mathbb{R} = \R
                    colorIsTextColor: false,

                })};
        } catch (error) {
            // KaTeX can't parse the expression
            return { __html: error.message };
        }
    }

    function insertKeyItem(latexString){
        mathInputRef.executeCommand(['insert', latexString]);
    }

    function getButton(key, dictionary, i, search) {
        if (search === '') {
            return <Button key={i} id="keyboardItem" onClick={() => insertKeyItem(dictionary[key])} variant="light"><span dangerouslySetInnerHTML={renderLatex(dictionary[key])} /></Button>
        }
        if (key.includes(search)) {
            return <Button key={i} id="keyboardItem" onClick={() => insertKeyItem(dictionary[key])} variant="light"><span dangerouslySetInnerHTML={renderLatex(dictionary[key])} /></Button>
        }
        return null;
    }

    const test = Object.keys(mathExpressions).map((key, i) => {
        getButton(key, mathExpressions, i, search);
    });

    return (
        <div id="virtualKeyboard">
            <input id='searchBar' placeholder={'search...'} onChange={e => setSearch(e.target.value)} />
            <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3"
            >
                <Tab eventKey="home" title="Mathematical Expression">
                    {Object.keys(mathExpressions).map((key, i) => getButton(key, mathExpressions, i, search))}
                </Tab>
                <Tab eventKey="profile" title="Operators">
                    {Object.keys(operators).map((key, i) => getButton(key, operators, i, search))}
                </Tab>
                <Tab eventKey="contact" title="Symbols">
                    {Object.keys(symbols).map((key, i) =>  getButton(key, symbols, i, search))}
                </Tab>
            </Tabs>
        </div>
    )
}

export default VirtualKeyboard;
