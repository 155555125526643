import React, {useState} from 'react';
import {
    AiOutlinePicture,
    AiOutlineTable,
    AiOutlineUnorderedList,
    AiOutlineDownload
} from "react-icons/ai";
import {BsTextareaT} from "react-icons/bs";
import {BiBookReader} from "react-icons/bi";
import {FaStream} from "react-icons/fa";
import {TbMath} from "react-icons/tb";
import Button from "react-bootstrap/Button";

function WelcomePage() {

    localStorage.removeItem("task")
    localStorage.removeItem("pdf")

    return <div id="welcomePage" className="d-flex flex-column">
        <div id="welcomeBanner" className="justify-content-center">
            <h2 id="welcomeHeading">Welcome to PAVE 2.0 <sup>BETA</sup></h2>
            <img src="img/en-zhaw-init-rgb.png" alt="Logo of ZHAW InIT"></img>
            <h3>Developed by the ICT Accessibility Lab, Institute of Computer Science, ZHAW</h3>
            <p id="welcomeText">PAVE 2.0 is an AI-powered tool to remediate PDFs. Building upon the success of its
                predecessor, it
                introduces a redesigned and simplified remediation workflow, divided into 8 easy-to-follow steps.
                With the support of our cutting-edge AI technology, you'll experience accelerated remediation,
                saving you valuable time and effort.</p>

            <p id="welcomeText">Our tool is currently a research project (SNSF Bridge Discovery Project
                "Accessible Scientific PDFs for All" - Grant number 194677) and is under <b>active development</b>.
                While we strive to minimize errors, please note that occasional issues may arise. Your feedback is
                invaluable in helping us enhance the tool's performance and stability. Thank you for your understanding
                and support.</p>
        </div>
        <div id="newsletterBanner" className="justify-content-center">
            {/*{registeredNewsletter ? <h3>Thank you for subscribing to the newsletter.</h3> :
                <>
                    <h3>Register for the PAVE 2.0 Newsletter</h3>
                    <div className="d-flex flex-row justify-content-center">
                        <Form>
                            <Form.Group controlId="newsletterForm">
                                <Form.Control type="email" placeholder="name@example.com"/>
                            </Form.Group>
                        </Form>
                        <Button onClick={() => {
                            const email = document.getElementById("newsletterForm").value
                            if (email.indexOf("@") && email.length > 9) {
                                registerNewsletter({email: email}).then((res) => {
                                    if (res.data === "email registered") setRegisteredNewsletter(true);
                                });
                            }
                        }} variant="light">Registers
                        </Button>
                    </div>
                </>
            }*/}
            <h3>Please fill out our survey after using PAVE 2.0</h3>
            <p id="welcomeText">
                We would really appreciate your input to improve the tool.
                This survey will take about 15 minutes, so grab a beverage and help us to improve PAVE 2.0.
                The survey results will be published anonymized in a scientific publication. You will find the link to the survey again after downloading your accessible PDF.
            </p>
            <div className="d-flex flex-row justify-content-evenly">
                <Button
                    href={"https://docs.google.com/forms/d/e/1FAIpQLSfPJro6fWypn8BlEhcq6OjIPm0kH0XMhHCueJfM-gnKlQxACg/viewform?usp=sf_link"}
                    target="_blank" variant="light"> Survey Link </Button>
            </div>
        </div>
        <div className="d-flex flex-column align-items-center" id="discoverSteps">
            <h3>The 8 Remediation Steps</h3>
            <ul>
                <li className="d-flex flex-row align-items-center"><BsTextareaT size="5em"/>
                    <div>
                        <h4>Step 1: Define Regions</h4>
                        <p>
                            In this step, you can define and adjust regions in the document. A region is a group of PDF
                            elements of the same type that belong together (e.g. paragraph, heading, figure, table,
                            formula, caption).
                        </p>
                    </div>
                </li>
                <li className="d-flex flex-row align-items-center"><BiBookReader size="5em"/>
                    <div>
                    <h4>Step 2: Define Reading Order</h4>
                        <p>
                            In this step, you can adjust the reading order of the regions defined in step 1 for each
                            page. The reading order is the order, in which the regions are read by the screen reader.
                        </p>
                    </div>
                </li>
                <li className="d-flex flex-row align-items-center"><FaStream size="5em"/>
                    <div>
                        <h4>Step 3: Organize Header Levels</h4>
                        <p>
                            In this step, you can adjust the heading level of each heading in the document.
                        </p>
                    </div>
                </li>
                <li className="d-flex flex-row align-items-center"><AiOutlineTable size="5em"/>
                    <div>
                        <h4>Step 4: Define Table Structure</h4>
                        <p>
                            In this step, you can define the table structure for all tables in your document.
                        </p>
                    </div>
                </li>
                <li className="d-flex flex-row align-items-center"><AiOutlineUnorderedList size="5em"/>
                    <div>
                        <h4>Step 5: Define Lists</h4>
                        <p>
                            In this step, you can adjust the list structure of all lists in your document.
                        </p>
                    </div>
                </li>
                <li className="d-flex flex-row align-items-center"><AiOutlinePicture size="5em"/>
                    <div>
                        <h4>Step 6: Edit Alternative Text of Figures</h4>
                        <p>
                            In this step, you can insert or edit the alternative text for each figure in your document.
                            An alternative text describes the figure content. It is read aloud by the screen readers.
                        </p>
                    </div>
                </li>
                <li className="d-flex flex-row align-items-center"><TbMath size="5em"/>
                    <div>
                        <h4>Step 7: Edit Alternative Text of Mathematical Formulas</h4>
                        <p>
                            In this step, you can insert or edit the alternative text of each mathematical formula in your
                            document.
                        </p>
                    </div>
                </li>
                <li className="d-flex flex-row align-items-center"><AiOutlineDownload size="5em"/>
                    <div>
                        <h4>Step 8: Check Meta Information and Final Results</h4>
                        <p>
                            In this step, you can complement the meta information of the PDF. An accessible PDF requires
                            at least a title, author, and a language.
                        </p>
                    </div>
                </li>
            </ul>
        </div>

        <div id="start">
            <h3>Get Started</h3>
            <Button onClick={() => window.location.href = '#app'} variant="light">Start PAVE 2.0</Button>
            <p>By using the tool, you agree to our <a href="#legal">terms of service</a>.</p>
        </div>
        <div id="disclaimer">
            <h3>Disclaimer</h3>
            <p>While we make every effort to avoid changes to content or presentation of the document when
                edited in PAVE 2.0,
                we cannot provide a guarantee. The user is responsible for reviewing the PDF documents
                generated.
                ZHAW assumes no liability.</p>
        </div>
    </div>
}

export default WelcomePage;
