import React, {useEffect, useState, useRef} from "react";
import Button from "react-bootstrap/Button";
import {FaArrowCircleDown} from "react-icons/fa";
import {resizingY, startResizeY} from "./UtilsResize";


const Instructions = ({title, bodyText, buttons, hints, nextStep, instructionSize, startResizeValueInstruction}) => {
    const [showBottomButton, setShowBottomButton] = useState(false);
    const instructionsText = useRef(null);

    useEffect(() => {
        instructionsText.current = document.getElementById("instructionsText");
        if (instructionsText.current != null) {
            instructionsText.current.addEventListener('scroll', checkScroll);
            checkScroll();
        }
    }, []);

    function checkScroll() {
        if (instructionsText.current != null) {
            if (instructionsText.current.scrollTop + instructionsText.current.clientHeight <= instructionsText.current.scrollHeight - 20) setShowBottomButton(true);
            else setShowBottomButton(false);
        }
    }


    return <div id="instructions" className="d-flex flex-column"
                style={startResizeValueInstruction.current != null ? {userSelect: 'none', height: instructionSize + "px"}: {height: instructionSize + "px"}}>
        <div id="resizeHandleY" onMouseDown={(e) => startResizeValueInstruction.current = startResizeY(e)}></div>
        <h2 className="d-flex flex-row">
            {title}
        </h2>
        <div id="instructionsText">
            <p>
                {bodyText}
            </p>
            {buttons ? <h3>Possible Actions</h3> : null}
            {buttons ? <ul>
                {buttons.map((button, buttonI) => <li key={buttonI}><strong>{button[0]}:</strong> {button[1]}</li>)}
            </ul> : null}
            {hints ? <h3>Hints</h3> : null}
            {hints ? <ul>
                {hints.map((hint, hintI) => <li key={hintI}><strong>{hint[0]}:</strong> {hint[1]}</li>)}
            </ul> : null}
            {showBottomButton ? <Button id="scrollDownButton"
                                        onClick={() => instructionsText.current.scrollTo(0, instructionsText.current.scrollHeight)} aria-label="scroll to the end"><FaArrowCircleDown
                size="1.5em"/></Button> : null}
        </div>
        {nextStep ? <div className="d-flex flex-row justify-content-end">
            <Button onClick={() => nextStep()} variant="light">Next Step</Button>
        </div> : null}
    </div>
}
export default Instructions;
