import { Bezier } from "bezier-js";

const WHITESPACETHRESHOLD = 150

const getBoundingBoxForTextItem = (item, pageViewport, pageNum) => {
    // Apply page viewport transform and item transform
    const itemRotated = window.pdfjsLib.Util.transform(pageViewport.transform, item.transform);
    const x = itemRotated[4];
    const y = itemRotated[5];

    // item.height is not correct for some cases (https://github.com/mozilla/pdf.js/issues/8276)
    const height = Math.sqrt((itemRotated[2] * itemRotated[2]) + (itemRotated[3] * itemRotated[3]));
    const width =  Math.sqrt(itemRotated[0] * itemRotated[0]) * item.width;
    const itemAngle =  Math.atan2(itemRotated[2], itemRotated[0]);

    // This will provide top left, clockwise bounding box
    const horizontalBoundingBox = [
        x, pageViewport.height - y,
        x + width, pageViewport.height - y,
        x + width, pageViewport.height - y + height,
        x, pageViewport.height - y + height
    ];

    // return rotated bounding box in pixels
    const roatedBoundingBox = rotateBoundingBox(horizontalBoundingBox, [x, y], itemAngle);
    return {page: pageNum,llx: roatedBoundingBox[0], lly: roatedBoundingBox[1], urx: roatedBoundingBox[2], ury: roatedBoundingBox[5]}
}

function transformMultiplication(a, b) {
    if (b == null) return a;
    let newTransform = [];
    newTransform[0] = a[0] * b[0] + a[2] * b[1];
    newTransform[1] = a[1] * b[0] + a[3] * b[1];
    newTransform[2] = a[0] * b[2] + a[2] * b[3];
    newTransform[3] = a[1] * b[2] + a[3] * b[3];
    newTransform[4] = a[0] * b[4] + a[2] * b[5] + a[4];
    newTransform[5] = a[1] * b[4] + a[3] * b[5] + a[5];
    return newTransform;
}

const rotateBoundingBox = (absBox, origin, rads) => {
    const [originX, originY] = origin;
    const cosT = Math.cos(rads);
    const sinT = Math.sin(rads);

    const rotate = (x, y) => ([
        (x - originX) * cosT - (y - originY) * sinT + originX,
        (x - originX) * sinT + (y - originY) * cosT + originY
    ]);

    const [v1x, v1y, v2x, v2y, v3x, v3y, v4x, v4y] = absBox;
    return [
        ...rotate(v1x, v1y),
        ...rotate(v2x, v2y),
        ...rotate(v3x, v3y),
        ...rotate(v4x, v4y)
    ];
};

export function processPdfJSRenderingInformation({pdf, pageNum}) {
    const pdfJSOperatorKeys = Object.keys(window.pdfjsLib.OPS);
    let pdfJSOperators = [];
    Object.values(window.pdfjsLib.OPS).forEach((v, i) => {
        pdfJSOperators[v] = pdfJSOperatorKeys[i];
    });
    return pdf.getPage(pageNum).then((page) => {
        const viewport = page.getViewport({scale: 1});
        let lastTextFont = null;
        let lastTextMatrix = [1, 0, 0, 1, 0, 0];
        let lastTransform = [1, 0, 0, 1, 0, 0];
        let textX = 0;
        let addSpace = false;
        let textLeading = 0;
        const pageSize = {page: pageNum,llx: viewport.viewBox[0], lly: viewport.viewBox[1], urx: viewport.viewBox[2], ury: viewport.viewBox[3]};
        let stateHistory = [];
        let clipBBox = viewport.viewBox;
        return page.getOperatorList().then(opList => {
            let textOperators = [];
            let imageOperators = [];
            let shapeOperators = [];
            let lastX = null;
            let lastY = null;
            for (let opI = 0; opI < opList.argsArray.length; opI++) {
                const op = opList.argsArray[opI];

                // 44
                if (opList.fnArray[opI] === window.pdfjsLib.OPS.showText) {

                    let text = op[0].map(char => {
                        if (char.unicode != null) return char.unicode;
                        else if (char < -WHITESPACETHRESHOLD) return " ";
                    });
                    if (addSpace) {
                        text.unshift(" ");
                        addSpace = false;
                    }
                    const item = {
                        transform: [lastTextFont[1] * lastTextMatrix[0], lastTextFont[1] * lastTextMatrix[1],
                            lastTextFont[1] * lastTextMatrix[2], lastTextFont[1] * 1.2 * lastTextMatrix[3], lastTextMatrix[4] + textX,
                            lastTextMatrix[5] - lastTextFont[1] * lastTextMatrix[3] * 0.2],
                        width: op[0].reduce((accumulator, currentValue) => {
                            if (currentValue.width != null) {
                                return accumulator + (currentValue.width / 1000);
                            }
                            else if (typeof currentValue === 'number') {
                                return accumulator - (currentValue / 1000);
                            }
                            else {
                                return accumulator;
                            }
                        }, 0)
                    }
                    const textBbox = getBoundingBoxForTextItem(item, viewport, pageNum);
                    textOperators.push({
                        type:"text",
                        text: text.join(""),
                        op: "", //op
                        textFont: lastTextFont[1] != null ? [lastTextFont[0], lastTextFont[1] * lastTextMatrix[3]].toString() : [lastTextFont[0], lastTextMatrix[3]].toString(),
                        textMatrix: lastTextMatrix.toString(),
                        lastTransform: lastTransform.toString(),
                        textX: textX,
                        boundingBox: textBbox,
                        id: opI,
                        unicodes: op[0].map(char => char.unicode),
                        widths: op[0].map(char => char.width),
                        originalCharCodes: op[0].map(char => char.originalCharCode),

                    });
                    textX += (textBbox.urx - textBbox.llx);
                }

                // 31
                else if (opList.fnArray[opI] === window.pdfjsLib.OPS.beginText) {
                    lastTextMatrix = JSON.parse(JSON.stringify(lastTransform));
                }

                // 36
                else if (opList.fnArray[opI] === window.pdfjsLib.OPS.setLeading) {
                    textLeading = op[0];
                }

                // 37
                else if (opList.fnArray[opI] === window.pdfjsLib.OPS.setFont) {
                    lastTextFont = op;
                }

                // 40
                else if (opList.fnArray[opI] === window.pdfjsLib.OPS.moveText) {
                    textX = 0;
                    addSpace = true;
                    lastTextMatrix[4] += lastTextMatrix[0] * op[0] + lastTextMatrix[1] * op[1];
                    lastTextMatrix[5] += lastTextMatrix[2] * op[0] + lastTextMatrix[3] * op[1];
                }

                // 41
                else if (opList.fnArray[opI] === window.pdfjsLib.OPS.setLeadingMoveText) {
                    textX = 0;
                    addSpace = true;
                    lastTextMatrix[4] += lastTextMatrix[0] * op[0] + lastTextMatrix[1] * op[1];
                    lastTextMatrix[5] += lastTextMatrix[2] * op[0] + lastTextMatrix[3] * op[1];
                    textLeading = -op[1] * lastTextMatrix[0];
                }

                // 42
                else if (opList.fnArray[opI] === window.pdfjsLib.OPS.setTextMatrix) {
                    lastTextMatrix = transformMultiplication(lastTransform, op);
                    textX = 0;
                    if (textOperators.length === 0 ||
                        Math.abs(lastTextMatrix[4] - textOperators[textOperators.length - 1].boundingBox.urx) > WHITESPACETHRESHOLD ||
                        Math.abs(lastTextMatrix[5] - textOperators[textOperators.length - 1].boundingBox.lly) > 0.5 * lastTextMatrix[4] ) {
                        addSpace = true;
                    }
                }

                // 43
                else if (opList.fnArray[opI] === window.pdfjsLib.OPS.nextLine) {
                    textX = 0;
                    addSpace = true;
                    lastTextMatrix[5] -= textLeading;
                }

                // 12
                else if (opList.fnArray[opI] === window.pdfjsLib.OPS.transform) {
                    lastTransform = transformMultiplication(lastTransform, op);
                }

                // 85
                else if (opList.fnArray[opI] === window.pdfjsLib.OPS.paintImageXObject) {
                    const item = {
                        transform: lastTransform,
                        width: 1
                    }
                    const boundingBox = getBoundingBoxForTextItem(item, viewport, pageNum);
                    imageOperators.push({
                        type:"ImageXObject",
                        op: "", //op
                        transform: lastTransform.toString(),
                        boundingBox: boundingBox,
                        id: opI
                    });
                }

                // 91
                else if (opList.fnArray[opI] === window.pdfjsLib.OPS.constructPath) {
                    let llx = 99999999;
                    let lly = 99999999;
                    let urx =  -99999999;
                    let ury = -99999999;
                    let startX = 0;
                    let startY = 0;
                    let shapeStart = 0;
                    let shapeText = "ref x: " + lastTransform[4] + ", " + "ref y: " + lastTransform[5];
                    for (let drawingOperator = 0; drawingOperator < op[0].length; drawingOperator++) {
                        // bounding box of the shape instruction
                        let boundingBox = null;

                        // 13 , 2 arguments
                        if (op[0][drawingOperator] === window.pdfjsLib.OPS.moveTo) {
                            shapeText += ", MoveTo: " + op[1][shapeStart] + ", " + op[1][shapeStart + 1];
                            lastX = op[1][shapeStart];
                            lastY = op[1][shapeStart + 1];
                            startX = JSON.parse(JSON.stringify(lastX));
                            startY = JSON.parse(JSON.stringify(lastY));
                            shapeStart += 2;
                        }

                        // 14 , 2 arguments
                        else if (op[0][drawingOperator] === window.pdfjsLib.OPS.lineTo) {
                            if (lastX == null) {
                                console.log("not starting point for line to");
                                shapeStart += 2;
                                continue;
                            }
                            shapeText += ", LineTo: " + op[1][shapeStart] + ", " + op[1][shapeStart + 1];
                            const dx = Math.min(lastX, op[1][shapeStart]);
                            const dy = Math.min(lastY, op[1][shapeStart + 1]);
                            const width = Math.max(Math.abs(lastX - op[1][shapeStart]), 1);
                            const height = Math.max(Math.abs(lastY - op[1][shapeStart + 1]), 1);
                            const item = {
                                transform: [width, 0, 0, height, dx, dy],
                                width: 1
                            }
                            boundingBox = getBoundingBoxForTextItem(item, viewport, pageNum);
                            lastX = op[1][shapeStart];
                            lastY = op[1][shapeStart + 1];
                            shapeStart += 2;
                        }

                        // 15 bezier curve, 6 arguments
                        else if (op[0][drawingOperator] === window.pdfjsLib.OPS.curveTo) {
                            if (lastX == null) {
                                console.log("not starting point for curve to");
                                shapeStart += 6;
                                continue;
                            }
                            shapeText += ", CurveTo: " + op[1][shapeStart] + ", " + op[1][shapeStart + 1] + ", " + op[1][shapeStart + 2] + ", " + op[1][shapeStart + 3] + ", " + op[1][shapeStart + 4] + ", " + op[1][shapeStart + 5];
                            const b = new Bezier(lastX, lastY, op[1][shapeStart], op[1][shapeStart + 1], op[1][shapeStart + 2], op[1][shapeStart + 3], op[1][shapeStart + 4], op[1][shapeStart + 5]);
                            const bezierBbox = b.bbox();
                            boundingBox = {llx: bezierBbox.x.min, lly: bezierBbox.y.min, urx: bezierBbox.x.max, ury: bezierBbox.y.max};
                            lastX = op[1][shapeStart + 4];
                            lastY = op[1][shapeStart + 5];
                            shapeStart += 6;
                        }

                        // 16 bezier curve2, 4 arguments
                        else if (op[0][drawingOperator] === window.pdfjsLib.OPS.curveTo2) {
                            if (lastX == null) {
                                console.log("not starting point for curve to");
                                shapeStart += 4;
                                continue;
                            }
                            shapeText += ", CurveTo2: " + op[1][shapeStart] + ", " + op[1][shapeStart + 1] + ", " + op[1][shapeStart + 2] + ", " + op[1][shapeStart + 3];
                            const b = new Bezier(lastX, lastY, lastX,lastY, op[1][shapeStart], op[1][shapeStart + 1], op[1][shapeStart + 2], op[1][shapeStart + 3]);
                            const bezierBbox = b.bbox();
                            boundingBox = {llx: bezierBbox.x.min, lly: bezierBbox.y.min, urx: bezierBbox.x.max, ury: bezierBbox.y.max};
                            lastX = op[1][shapeStart + 2];
                            lastY = op[1][shapeStart + 3];
                            shapeStart += 4;
                        }

                        // 17 bezier curve3, 4 arguments
                        else if (op[0][drawingOperator] === window.pdfjsLib.OPS.curveTo3) {
                            if (lastX == null) {
                                console.log("not starting point for curve to");
                                shapeStart += 4;
                                continue;
                            }
                            shapeText += ", CurveTo3: " + op[1][shapeStart] + ", " + op[1][shapeStart + 1] + ", " + op[1][shapeStart + 2] + ", " + op[1][shapeStart + 3];
                            const b = new Bezier(lastX, lastY, op[1][shapeStart], op[1][shapeStart + 1], op[1][shapeStart + 2], op[1][shapeStart + 3], op[1][shapeStart + 2], op[1][shapeStart + 3]);
                            const bezierBbox = b.bbox();
                            boundingBox = {llx: bezierBbox.x.min, lly: bezierBbox.y.min, urx: bezierBbox.x.max, ury: bezierBbox.y.max};
                            lastX = op[1][shapeStart + 2];
                            lastY = op[1][shapeStart + 3];
                            shapeStart += 4;
                        }

                        // 18
                        else if (op[0][drawingOperator] === window.pdfjsLib.OPS.closePath) {
                            shapeText += ", ClosePath: " + startX + ", " + startY;
                            lastX = JSON.parse(JSON.stringify(startX));
                            lastY = JSON.parse(JSON.stringify(startY));
                        }

                        // 19 , 4 arguments
                        else if (op[0][drawingOperator] === window.pdfjsLib.OPS.rectangle) {
                            if (op[1][shapeStart + 3] < 0) {
                                op[1][shapeStart + 1] += op[1][shapeStart + 3];
                                op[1][shapeStart + 3] *= -1;
                            }
                            const item = {
                                transform: [op[1][shapeStart + 2], 0, 0, op[1][shapeStart + 3], op[1][shapeStart], op[1][shapeStart + 1]],
                                width: 1
                            };
                            boundingBox = getBoundingBoxForTextItem(item, viewport, pageNum);
                            shapeText += ", Rect: " + op[1][shapeStart] + ", " + op[1][shapeStart + 1] + ", " + op[1][shapeStart + 2] + ", " + op[1][shapeStart + 3];
                            lastX = boundingBox.llx;
                            lastY = boundingBox.lly;
                            shapeStart += 4;
                        }
                        else {
                            console.log(op[0][drawingOperator])
                        }
                        if (boundingBox != null) {
                            llx = Math.min(boundingBox.llx * lastTransform[0] + lastTransform[4], boundingBox.urx * lastTransform[0] + lastTransform[4], llx);
                            lly = Math.min(boundingBox.lly * lastTransform[3] + lastTransform[5], boundingBox.ury * lastTransform[3] + lastTransform[5], lly);
                            urx = Math.max(boundingBox.llx * lastTransform[0] + lastTransform[4], boundingBox.urx * lastTransform[0] + lastTransform[4], urx);
                            ury = Math.max(boundingBox.lly * lastTransform[3] + lastTransform[5], boundingBox.ury * lastTransform[3] + lastTransform[5], ury);
                        }
                    }
                    // add the shape operator bounding box
                    if (isNaN(urx) || urx === -99999999) {
                        //console.log("something went wrong")
                    }
                    else {
                        shapeOperators.push({
                            type:"Shape",
                            op: shapeText, //op
                            boundingBox: {page: pageNum, llx: Math.max(llx, clipBBox[0]), lly: Math.max(lly, clipBBox[1]), urx: Math.min(urx, clipBBox[2]), ury: Math.min(ury, clipBBox[3])},
                            boundingBoxNotClipped: {llx: llx, lly: lly, urx: urx, ury: ury},
                            clipBBox: clipBBox,
                            id: opI,
                        });
                    }
                }

                // 29
                else if (opList.fnArray[opI] === window.pdfjsLib.OPS.clip) {
                    const shapeOperator = shapeOperators.pop();
                    if (shapeOperator != null) {
                        const shapeRect = shapeOperator.boundingBoxNotClipped;
                        // check if shape rect is inside the clip box
                        if (shapeRect.llx >= clipBBox[0] && shapeRect.lly >= clipBBox[1] && shapeRect.urx <= clipBBox[2] && shapeRect.ury <= clipBBox[3]) {
                            clipBBox = [shapeOperator.boundingBoxNotClipped.llx, shapeOperator.boundingBoxNotClipped.lly, shapeOperator.boundingBoxNotClipped.urx, shapeOperator.boundingBoxNotClipped.ury]
                        }
                        else {
                            clipBBox = [Math.max(clipBBox[0], shapeOperator.boundingBoxNotClipped.llx), Math.max(clipBBox[1], shapeOperator.boundingBoxNotClipped.lly), Math.min(clipBBox[2], shapeOperator.boundingBoxNotClipped.urx), Math.min(clipBBox[3], shapeOperator.boundingBoxNotClipped.ury)]
                        }
                    }
                }

                // 30
                else if (opList.fnArray[opI] === window.pdfjsLib.OPS.eoClip) {
                    const shapeOperator = shapeOperators.pop();
                    if (shapeOperator != null) {
                        // even odd winding rule -> intersection
                        clipBBox = [Math.max(clipBBox[0], shapeOperator.boundingBoxNotClipped.llx), Math.max(clipBBox[1], shapeOperator.boundingBoxNotClipped.lly), Math.min(clipBBox[2], shapeOperator.boundingBoxNotClipped.urx), Math.min(clipBBox[3], shapeOperator.boundingBoxNotClipped.ury)]
                    }
                }

                // 10
                else if (opList.fnArray[opI] === window.pdfjsLib.OPS.save) {
                    stateHistory.push({
                        lastTextFont: JSON.parse(JSON.stringify(lastTextFont)),
                        lastTextMatrix: JSON.parse(JSON.stringify(lastTextMatrix)),
                        lastTransform: JSON.parse(JSON.stringify(lastTransform)),
                        clipBBox: JSON.parse(JSON.stringify(clipBBox))
                    });
                }

                // 11
                else if (opList.fnArray[opI] === window.pdfjsLib.OPS.restore) {
                    const lastState = stateHistory.pop();
                    if (lastState != null) {
                        lastTextFont = lastState.lastTextFont;
                        lastTextMatrix = lastState.lastTextMatrix;
                        lastTransform = lastState.lastTransform;
                        clipBBox = lastState.clipBBox;
                    }
                }

                // 74
                else if (opList.fnArray[opI] === window.pdfjsLib.OPS.paintFormXObjectBegin) {
                    stateHistory.push({
                        lastTextFont: JSON.parse(JSON.stringify(lastTextFont)),
                        lastTextMatrix: JSON.parse(JSON.stringify(lastTextMatrix)),
                        lastTransform: JSON.parse(JSON.stringify(lastTransform)),
                        clipBBox: JSON.parse(JSON.stringify(clipBBox))
                    });
                    const newTransform = transformMultiplication(lastTransform, op[0]);
                    lastTransform = newTransform;
                    lastTextMatrix = newTransform;
                }

                // 75
                else if (opList.fnArray[opI] === window.pdfjsLib.OPS.paintFormXObjectEnd) {
                    const lastState = stateHistory.pop();
                    if (lastState != null) {
                        lastTextFont = lastState.lastTextFont;
                        lastTextMatrix = lastState.lastTextMatrix;
                        lastTransform = lastState.lastTransform;
                    }
                }

                // 1
                else if (opList.fnArray[opI] === window.pdfjsLib.OPS.dependency) {

                }

                // 2
                else if (opList.fnArray[opI] === window.pdfjsLib.OPS.setLineWidth) {

                }

                // 23
                else if (opList.fnArray[opI] === window.pdfjsLib.OPS.eoFill) {

                }

                // 28
                else if (opList.fnArray[opI] === window.pdfjsLib.OPS.endPath) {
                    //console.log("endPath");
                }

                // 32
                else if (opList.fnArray[opI] === window.pdfjsLib.OPS.endText) {

                }

                // 33
                else if (opList.fnArray[opI] === window.pdfjsLib.OPS.setCharSpacing) {

                }

                // 34
                else if (opList.fnArray[opI] === window.pdfjsLib.OPS.setWordSpacing) {

                }

                // 58
                else if (opList.fnArray[opI] === window.pdfjsLib.OPS.setStrokeRGBColor) {
                    // does not influence the rendering
                }

                // 59
                else if (opList.fnArray[opI] === window.pdfjsLib.OPS.setFillRGBColor) {
                    // does not influence the rendering
                }

                // 63
                else if (opList.fnArray[opI] === window.pdfjsLib.OPS.beginInlineImage) {
                    console.log("Inline Image not Implemented")
                }

                // 64
                else if (opList.fnArray[opI] === window.pdfjsLib.OPS.beginImageData) {
                    console.log("Image Data not implemented")
                }

                // 69
                else if (opList.fnArray[opI] === window.pdfjsLib.OPS.beginMarkedContent) {
                    // does not influence the rendering
                }

                // 70
                else if (opList.fnArray[opI] === window.pdfjsLib.OPS.beginMarkedContentProps) {
                    // does not influence the rendering
                }

                // 70
                else if (opList.fnArray[opI] === window.pdfjsLib.OPS.endMarkedContent) {
                    // does not influence the rendering
                }

                // other operators
                else {
                    //console.log(opI + ": " + pdfJSOperators[opList.fnArray[opI]]);
                    //console.log(op);
                }

            }
            console.log("Processed Page: " + pageNum);
            return {textOperators: textOperators, imageOperators: imageOperators, shapeOperators: shapeOperators, pageSize: pageSize};
        });
    });
}
