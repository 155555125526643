import React, {useState, useRef} from "react";

import Header from "./Header"
import NavBar from "./NavBar"
import UploadPDF from "./UploadPDF";
import Regions from "./Regions";
import Headings from "./Headings"
import ReadingOrder from "./ReadingOrder";
import Tables from "./Tables";
import Lists from "./Lists";
import Figures from "./Figures";
import Formulae from "./Formulae";
import MetaInformation from "./MetaInformation";
import { Routes, Route } from "react-router-dom";
import Help from "./Help";
import Legal from "./Legal"
import Contact from "./Contact";
import WelcomePage from "./WelcomePage";

function getTasks(language) {
    return ["UPLOAD PDF", "REGIONS", "READING ORDER", "HEADERS", "TABLES", "LISTS", "FIGURES",
        "MATHEMATICAL FORMULAE", "META INFORMATION"]
}

// clarity
//clarity.init('mxyzzvn4t0');

const App = () => {
    const [language, setLanguage] = useState("EN");
    const [pdfInfo, setPdfInfo] = useState({filename: null, fileid: null, numberOfPages: 0});
    const pdf = useRef(null);
    const [steps, setSteps] = useState(getTasks(language));
    const [step, setStep] = useState(0);
    const [renderI, setRenderI] = useState(0);
    const stepSelected = useRef(-1);

    // resize the elements
    const [menuSize, setMenuSize] = useState(500);
    const [instructionSize, setInstructionSize] = useState(200);
    const [showPageMenu, setShowPageMenu] = useState(true);


    function nextStep() {
        // queryClient.removeQueries();
        if (step === 0) setStep(prevTask => prevTask + 1)
        else setStepSelected(step + 1)
    }

    function setStep2(t) {
        stepSelected.current = -1;
        setStep(t);
        setRenderI(prevState => prevState + 1);
    }

    function setStepSelected(t) {
        if (t !== step) {
            stepSelected.current = t;
            setRenderI(prevState => prevState + 1);
        }
    }

    function changeLanguage(lang_code) {
        alert('Other languages are not supported yet');
    }

    return (
        <div className="d-flex flex-column" id="body">
            <Header language={language} changeLanguage={changeLanguage}/>
            <Routes>
                <Route path="/" element={<WelcomePage />} />
                <Route path="app" element={
                    <div className="d-flex flex-fill flex-row" id="navAndWorkspace">
                        <div id="nav-area">
                            <NavBar task_i={step} tasks={steps} setTask={setStepSelected}/>
                        </div>
                        <div className="d-flex flex-fill" id="workspace">
                            {step === 0 && <UploadPDF pdf={pdf} setPdfInfo={setPdfInfo} nextStep={nextStep} menuSize={menuSize} setMenuSize={setMenuSize} instructionSize={instructionSize} setInstructionSize={setInstructionSize}/>}
                            {step === 1 && <Regions pdf={pdf.current} pdfInfo={pdfInfo} stepSelected={stepSelected.current} setStep={setStep2} nextStep={nextStep} menuSize={menuSize} setMenuSize={setMenuSize} instructionSize={instructionSize} setInstructionSize={setInstructionSize} showPageMenu={showPageMenu} setShowPageMenu={setShowPageMenu}/>}
                            {step === 2 &&
                                <ReadingOrder pdf={pdf.current} pdfInfo={pdfInfo} stepSelected={stepSelected.current} setStep={setStep2} nextStep={nextStep} menuSize={menuSize} setMenuSize={setMenuSize} instructionSize={instructionSize} setInstructionSize={setInstructionSize} showPageMenu={showPageMenu} setShowPageMenu={setShowPageMenu}/>}
                            {step === 3 && <Headings pdf={pdf.current} pdfInfo={pdfInfo} stepSelected={stepSelected.current} setStep={setStep2} nextStep={nextStep} menuSize={menuSize} setMenuSize={setMenuSize} instructionSize={instructionSize} setInstructionSize={setInstructionSize} showPageMenu={showPageMenu} setShowPageMenu={setShowPageMenu}/>}
                            {step === 4 && <Tables pdf={pdf.current} pdfInfo={pdfInfo} stepSelected={stepSelected.current} setStep={setStep2}
                                                   nextStep={nextStep} menuSize={menuSize} setMenuSize={setMenuSize} instructionSize={instructionSize} setInstructionSize={setInstructionSize} showPageMenu={showPageMenu} setShowPageMenu={setShowPageMenu}/>}
                            {step === 5 && <Lists pdf={pdf.current} pdfInfo={pdfInfo} stepSelected={stepSelected.current} setStep={setStep2}
                                                  nextStep={nextStep} menuSize={menuSize} setMenuSize={setMenuSize} instructionSize={instructionSize} setInstructionSize={setInstructionSize} showPageMenu={showPageMenu} setShowPageMenu={setShowPageMenu}/>}
                            {step === 6 && <Figures pdf={pdf.current} pdfInfo={pdfInfo} stepSelected={stepSelected.current} setStep={setStep2}
                                                    nextStep={nextStep} menuSize={menuSize} setMenuSize={setMenuSize} instructionSize={instructionSize} setInstructionSize={setInstructionSize} showPageMenu={showPageMenu} setShowPageMenu={setShowPageMenu}/>}
                            {step === 7 && <Formulae pdf={pdf.current} pdfInfo={pdfInfo} stepSelected={stepSelected.current} setStep={setStep2}
                                                     nextStep={nextStep} menuSize={menuSize} setMenuSize={setMenuSize} instructionSize={instructionSize} setInstructionSize={setInstructionSize} showPageMenu={showPageMenu} setShowPageMenu={setShowPageMenu}/>}
                            {step === 8 &&
                                <MetaInformation pdf={pdf.current} pdfInfo={pdfInfo} stepSelected={stepSelected.current} setStep={setStep2} menuSize={menuSize} setMenuSize={setMenuSize} instructionSize={instructionSize} setInstructionSize={setInstructionSize} showPageMenu={showPageMenu} setShowPageMenu={setShowPageMenu}/>}
                        </div>
                    </div>
                } />
                <Route path="help" element={<Help/>}/>
                <Route path="legal" element={<Legal/>}/>
                <Route path="contact" element={<Contact/>}/>
            </Routes>
        </div>
    );
};

export default App;
