import React, {useEffect, useState} from "react";
import {getStructTree} from "../APIcalls";
import {useQuery, useQueryClient} from "react-query";
import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {getNotMarked} from "../Tools";


export function StructTreeHTMLConverter({showHTML, setShowHTML, pdf, pdfInfo, pageNum}) {

    const [renderI, setRenderI] = useState(0);

    const queryClient = useQueryClient()

    useEffect(() => {
        return () => {
            queryClient.invalidateQueries({ queryKey: ["structTree", pdfInfo.fileid, pageNum] })
        };
    }, [showHTML]);


    // get the struct tree
    const structTreeQuery = useQuery({
        queryKey: ["structTree", pdfInfo.fileid, pageNum],
        queryFn: () => getStructTree({pdfInfo: pdfInfo, pdf: pdf, pageNum: pageNum}),
        staleTime: 1000 * 60 * 5, // less fetching,
        enabled: pdfInfo.fileid != null,
        placeholderData: null,  // placeholder image
        onSuccess: data => {
            setRenderI(prevState => prevState + 1);
        }
    });

    let headingLevel = 0;

    function convertStructTree(structElements) {
        if (structElements == null) {
            return null;
        }
        return structElements.map(element => {
            if (element.type === "H1") {
                headingLevel = 1;
                return <h1 className={"headingLevel" + (headingLevel - 1)}><p id="tagLabel">H1</p>{convertStructTree(element.children)}</h1>;
            }
            else if (element.type === "H2") {
                headingLevel = 2;
                return <h2 className={"headingLevel" + (headingLevel - 1)}><p id="tagLabel">H2</p>{convertStructTree(element.children)}
                </h2>
            } else if (element.type === "H3") {
                headingLevel = 3;
                return <h3 className={"headingLevel" + (headingLevel - 1)}><p id="tagLabel">H3</p>{convertStructTree(element.children)}
                </h3>
            } else if (element.type === "H4") {
                headingLevel = 4;
                return <h4 className={"headingLevel" + (headingLevel - 1)}><p id="tagLabel">H4</p>{convertStructTree(element.children)}
                </h4>
            } else if (element.type === "H5") {
                headingLevel = 5;
                return <h5 className={"headingLevel" + (headingLevel - 1)}><p id="tagLabel">H5</p>{convertStructTree(element.children)}
                </h5>
            } else if (element.type === "H6") {
                headingLevel = 6;
                return <h6 className={"headingLevel" + (headingLevel - 1)}><p id="tagLabel">H6</p>{convertStructTree(element.children)}
                </h6>
            } else if (element.type === "Figure") {
                return <div className={"imgDiv headingLevel" + headingLevel}><p id="tagLabel">Figure</p><img
                    alt={element.altText === "" ? "no alt text set!" : element.altText}/>
                </div>
            } else if (element.type === "L") {
                return <div className={"headingLevel" + headingLevel + " listDiv"}><p id="tagLabel">List</p><ul>{convertStructTree(element.children)}</ul>
                </div>
            } else if (element.type === "LI") {
                return <li>{convertStructTree(element.children)}</li>
            }
            else if (element.type === "Table") {
                return <div className={"headingLevel" + headingLevel + " tableDiv"}><p id="tagLabel">Table</p><table>{convertStructTree(element.children)}</table>
                </div>
            } else if (element.type === "TR") {
                return <tr>{convertStructTree(element.children)}</tr>
            }
            else if (element.type === "TH") {
                return <th>{convertStructTree(element.children)}</th>
            } else if (element.type === "TD") {
                return <td>{convertStructTree(element.children)}</td>
            } else if (element.type === "Link") {
                return <a>{convertStructTree(element.children)}</a>
            } else if (element.type === "P") {
                return <p className={"headingLevel" + headingLevel}><p id="tagLabel">P</p>{convertStructTree(element.children)}
                </p>
            } else if (element.type === "Artifact") {
                return null;
            } else if (element.type === "Caption") {
                return <caption className={"headingLevel" + headingLevel}><p id="tagLabel">Caption</p>{convertStructTree(element.children)}
                </caption>
            } else if (element.type === "MCRef") {
                if (element.altText !== ":") {
                    return element.altText;
                }
                else {
                    return element.text;
                }
            }
            else {
                return convertStructTree(element.children);
            }
        })
    }

    function convertOperators(data) {
        let notMarked = getNotMarked(data)
        return notMarked.length > 0 ? <div className={"headingLevel0"} id="notMarked"><p id="tagLabel">Not Marked</p>{notMarked.map(element => element.text)}
        </div> : null;
    }

    return <Modal
        show={showHTML}
        aria-labelledby="contained-modal-title"
        onHide={() => setShowHTML(false)}
        id="structTreeHTMLConverterModal"
    >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title">
                {"HTML View of Page " + pageNum}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div id="structTreeHTMLConverter">
                {structTreeQuery.data != null ? convertStructTree(structTreeQuery.data.structTree) : null}
                {structTreeQuery.data != null ? convertOperators(structTreeQuery.data) : null}
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={() => setShowHTML(false)}>Close</Button>
        </Modal.Footer>
    </Modal>
}
