import React from 'react';

function Contact() {
    return <div id="legalHelp">
        <h2>Contact</h2>
        <h3>ZHAW</h3>
        <a href="http://accessibility.zhaw.ch/?L=0">ICT Accessibility Lab</a><br/>
        <a href="https://www.zhaw.ch/en/engineering/institutes-centres/init">Init - Institute of Applied Information
            Technology</a><br/>
        <a href="https://www.zhaw.ch/en/university">ZHAW - Zurich University of Applied Sciences</a><br/>
        <br/>
        <h3>Contact Persons</h3>
        <a href="https://www.zhaw.ch/en/about-us/person/scmx/">Felix Schmitt-Koopmann</a><br/>
        Steinberggasse 13<br/>
        8400 Winterthur<br/>
        <a href="mailto:felix.schmitt-koopmann@zhaw.ch">felix.schmitt-koopmann@zhaw.ch</a><br/>
        <br/>
        <a href="https://www.zhaw.ch/en/about-us/person/dvya/">Prof. Dr. Alireza Darvishy</a><br/>
        Steinberggasse 13<br/>
        8400 Winterthur<br/>
        +41 (0) 58 934 69 12<br/>
        <a href="mailto:alireza.darvishy@zhaw.ch">alireza.darvishy@zhaw.ch</a>
    </div>
}

export default Contact;
