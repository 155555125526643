import React, {useState} from 'react';
import {canvasSize} from "./Config";
import {BeatLoader} from "react-spinners";
import ShowLabel from "./utils/ShowLabel";
import ZoomNav from "./utils/ZoomNav";
import Button from "react-bootstrap/Button";
import {BiSelectMultiple} from "react-icons/bi";
import {FaArrowLeft, FaArrowRight} from "react-icons/fa";
import {StructTreeHTMLConverter} from "./utils/StructTreeHTMLConverter";
import {StructTreeVisualizer} from "./utils/StructTreeVisualizer";
import {PiTreeViewLight} from "react-icons/pi";
import {TbFileTypeHtml} from "react-icons/tb";

const PageView = ({pageNum, extraTitleText, canvasRef, onMouseClick, onMouseDown, onMouseUp, onMouseMove, showLabels,
                      setShowLabels, showArtifacts, setShowArtifacts, zoomFactor, setZoomFactor, multiSelect,
                      setMultiSelect, showHeadingLabels, setShowHeadingLabels, isLoading, showPageMenu, setShowPageMenu,
                      pdf, pdfInfo, updateStep
                  }) => {

    if (extraTitleText == null) extraTitleText = "";

    const [showHTML, setShowHTML] = useState(false);
    const [showStructTreeVisualizer, setShowStructTreeVisualizer] = useState(false);

    return (
        <div className="d-flex flex-row flex-fill" id="page">
            <StructTreeHTMLConverter showHTML={showHTML} setShowHTML={setShowHTML} pdf={pdf} pdfInfo={pdfInfo} pageNum={pageNum}></StructTreeHTMLConverter>
            <StructTreeVisualizer showStructTreeVisualizer={showStructTreeVisualizer} setShowStructTreeVisualizer={setShowStructTreeVisualizer} pdf={pdf} pdfInfo={pdfInfo} pageNum={pageNum}></StructTreeVisualizer>
            <div className="d-flex flex-column flex-fill" id="canvasAndNav">
                <div>
                    <h3>{"Page " + pageNum + extraTitleText}</h3>
                </div>
                <div className="d-flex flex-fill" id="canvas">
                    <canvas
                        className="d-flex"
                        id="pageCanvas"
                        height={canvasSize[1]}
                        width={canvasSize[0]}
                        ref={canvasRef}
                        onClick={onMouseClick}
                        onMouseDown={onMouseDown}
                        onMouseUp={onMouseUp}
                        onMouseMove={onMouseMove}
                        style={{height: 98 * zoomFactor + "%"}}
                    />
                    {isLoading ? <BeatLoader id="canvasLoading" /> : null}
                </div>
            </div>
            <Button variant="light" onClick={() => setShowPageMenu(prev => !prev)} id="showPageMenu" aria-label="Show page menu">
                {showPageMenu ? <FaArrowRight /> : <FaArrowLeft />}
            </Button>
            {showPageMenu ? <div className="d-flex flex-column" id="pageModifications">
                {setShowLabels != null ? <ShowLabel setShowLabels={setShowLabels} showLabels={showLabels} elementName={"Region Labels"}/> : null}
                {setShowHeadingLabels != null ? <ShowLabel setShowLabels={setShowHeadingLabels} showLabels={showHeadingLabels} elementName={"Heading Labels"}/> : null}
                {setShowArtifacts != null ? <ShowLabel setShowLabels={setShowArtifacts} showLabels={showArtifacts} elementName={"Artifacts"}/> : null}
                {setZoomFactor != null ? <ZoomNav setZoomFactor={setZoomFactor}/> : null}
                {setMultiSelect != null ? <Button variant="light" onClick={() => {
                    setMultiSelect(prevState => !prevState);
                }} active={multiSelect} className="pageViewButtons">
                    <BiSelectMultiple/><br/>
                    Multi-Select
                </Button> : null}
                {/*<Button variant="light" onClick={() => {
                    updateStep(() => setShowHTML(true));
                }} className="pageViewButtons"><TbFileTypeHtml /><br/>HTML View</Button>
                <Button variant="light" onClick={() => {
                    updateStep(() => setShowStructTreeVisualizer(true));
                }} className="pageViewButtons"><PiTreeViewLight /><br/>Structure Tree</Button>*/}
            </div> : null}
        </div>
    );
};

export default PageView;
