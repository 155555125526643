import React, {useState, useRef, useEffect } from 'react';
import {useMutation, useQuery} from "react-query";
import {getPageImagePdfJs, updatePageStructTree} from "./APIcalls";
import {colors, readingOrderConfig, tagTypes} from "./Config";

import Button from "react-bootstrap/Button";
import {drawReadingOrder, loadingCanvas} from "./Drawing";
import {changeTagType, getMousePos, navigation, transformRectangle} from "./Tools";
import {MdOutlineAutoFixHigh, MdOutlineDraw} from "react-icons/md";
import {moveTag} from "./StructTreeActions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Instructions from "./utils/Instructions";
import {errorDrawing, WarningNotComplete} from "./utils/ErrorMessages";
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import {PopoverBody, PopoverHeader} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {resizingX, resizingY, startResizeX} from "./utils/UtilsResize";
import PageView from "./PageView";


const ReadingOrder = ({pdf, pdfInfo, stepSelected, setStep, nextStep, menuSize, setMenuSize, instructionSize, setInstructionSize, showPageMenu, setShowPageMenu}) => {
    // common values
    const pageNum = useRef(1);
    const [imageReady, setImageReady] = useState(true);
    const structTree = useRef({structTree: null, operators: null});
    const modifications = useRef([]);

    // canvas image elements
    const canvasRef = useRef(null);
    const ctx = useRef(null);

    // mouse position
    const curPos = useRef(0);

    const readingOrder = useRef([]);
    const mouseMove = useRef([]);
    const drawingReadingOrder = useRef(false);
    const elementHighlighted  = useRef(false);
    const [showLabels, setShowLabels] = useState(true);

    const [renderI, setRenderI] = useState(0);
    const viewed = useRef(Array.from({length: pdfInfo.numberOfPages}, _ => false));
    const [showWarning, setShowWarning] = useState(false);

    const [zoomFactor, setZoomFactor] = useState(1);

    // resize start values
    const startResizeValueMenu = useRef(null);
    const startResizeValueInstruction = useRef(null);

    // changing step
    useEffect(() => {
        if (stepSelected !== -1) {
            updateStep(() => {
                    if (viewed.current.every(t => t) || stepSelected < 2) setStep(stepSelected);
                    else setShowWarning(true);
                }
            );
        }
    }, [stepSelected]);

    // initialize canvas
    useEffect(() => {
        if (canvasRef.current) {
            ctx.current = canvasRef.current.getContext('2d');
        }
        updateStep();
        viewed.current[pageNum.current - 1] = true;
    }, []);

    // getting the image
    const pageImage = useQuery({
        queryKey: ["image", pdfInfo.fileid, pageNum.current],
        queryFn: () => getPageImagePdfJs({pdf: pdf, pageNum: pageNum.current, setImageReady: setImageReady}),
        staleTime: 1000 * 60 * 5, // less fetching,
        enabled: pdfInfo.fileid != null,
        placeholderData: null,  // placeholder image
        onSuccess: () => drawing()
    });

    // update and get structTree
    const structTreeMutation = useMutation({
        mutationFn: ({pdfInfo, pdf, oldPage, newPage, modifications}) => updatePageStructTree({pdfInfo: pdfInfo, oldPage: oldPage, newPage: newPage, modifications: modifications, addArtifacts: false, pdf:pdf}),
        onSuccess: data => {
            structTree.current = data;
            readingOrder.current = [...data.structTree];
            drawing();
        }
    })

    /**
     * Helper function for updating the step
     */
    function updateStep(onSuccessFunction, newPage) {
        if (newPage == null) newPage = pageNum.current;
        updateReadingOrder();
        return structTreeMutation.mutate({pdfInfo: pdfInfo, oldPage: pageNum.current, newPage: newPage, modifications: modifications.current, pdf: pdf}, {onSuccess: () => {
                modifications.current = [];
                if (onSuccessFunction != null) {
                    onSuccessFunction();
                }
            }
        });
    }


    /**
     * Helper function for drawing
     */
    function drawing() {
        if (!structTreeMutation.isLoading && imageReady  && ctx.current != null && pageImage.data != null) {
            try {
                canvasRef.current.height = pageImage.data.naturalHeight;
                canvasRef.current.width = pageImage.data.naturalWidth;
                drawReadingOrder({
                    ctx: ctx.current,
                    image: pageImage.data,
                    readingOrder: readingOrder.current,
                    structTree: structTree.current.structTree,
                    mouseMove: mouseMove.current,
                    drawingReadingOrder: drawingReadingOrder.current,
                    elementHighlighted: elementHighlighted.current,
                    showRegionLabels: showLabels
                })
            }
            catch (e) {
                errorDrawing({errorMessage: e});
            }

        }
        if (structTreeMutation.isLoading || pageImage.isLoading) {
            loadingCanvas({ctx: ctx.current, canvasRef: canvasRef.current});
        }
    }

    // draw image and rectangles
    drawing();

    function changePage(i) {
        if (i !== pageNum.current - 1) {
            updateStep(null, i + 1);
            pageNum.current = i + 1;
            modifications.current = [];
        }
    }

    function onMouseDown(e) {
        // do nothing during loading
        if (structTreeMutation.isLoading || pageImage.isLoading) return
        if (!drawingReadingOrder.current) return
        curPos.current = getMousePos({e: e, canvasRef: canvasRef.current});
        mouseMove.current = [{pos: curPos.current, element: "start"}];
        requestAnimationFrame(drawing)
    }

    function onMouseMove(e) {
        // do nothing during loading
        if (structTreeMutation.isLoading || pageImage.isLoading) return
        if (!drawingReadingOrder.current || mouseMove.current.length === 0) return
        curPos.current = getMousePos({e: e, canvasRef: canvasRef.current});
        if (mouseMove.current[mouseMove.current.length -1].element == null) mouseMove.current.pop();
        mouseMove.current.push({pos: curPos.current, id: null});
        requestAnimationFrame(drawing);
    }

    function onMouseUp(e) {
        // do nothing during loading
        if (structTreeMutation.isLoading || pageImage.isLoading) return
        if (!drawingReadingOrder.current || mouseMove.current.length === 0) return
        mouseMove.current = [];
        drawingReadingOrder.current = false;
        structTree.current.structTree.forEach(elem => {
            if (!readingOrder.current.some(e => e.children.map(c => c.id).toString() === elem.children.map(c => c.id).toString())) {
                readingOrder.current.push(elem)
            }
        })
        requestAnimationFrame(drawing);
        setRenderI(prevState => prevState + 1);
    }

    function resetReadingOrder() {
        readingOrder.current = [];
        drawingReadingOrder.current = true;
        drawing();
    }

    function detectReadingOrder() {
        let tempReadingOrder = [];
        const imageSize = [pageImage.data.naturalWidth, pageImage.data.naturalHeight];
        structTree.current.structTree.forEach(e => {
            let tempElement = {};
            tempElement['id'] = e.id;
            tempElement['element'] = e;
            const rect = transformRectangle({rect: e.rectangle, imageSize: imageSize});
            tempElement['rect'] = rect;
            if (rect.w < imageSize[0]*0.5) {
                if ((rect.x + rect.w/2) < imageSize[0]*0.4) {
                    tempElement['position'] = "left";
                }
                else {
                    tempElement['position'] = "right";
                }
            }
            else {
                tempElement['position'] = "both";
            }
            tempReadingOrder.push(tempElement);
        });
        tempReadingOrder.sort((a,b) => {
            return a.rect.y - b.rect.y;
        });
        let rightRow = [];
        readingOrder.current = [];
        tempReadingOrder.forEach(e => {
            if (e.position === "both") {
                readingOrder.current = readingOrder.current.concat(rightRow);
                rightRow = [];
                readingOrder.current.push(e.element);
            }
            else {
                if (e.position === "left") {
                    readingOrder.current.push(e.element);
                }
                else {
                    rightRow.push(e.element);
                }
            }
        });
        readingOrder.current = readingOrder.current.concat(rightRow);
        drawing();
        setRenderI(prevState => prevState + 1);
    }

    // Function to update list on drop
    function handleDrop(droppedItem) {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        // change order
        readingOrder.current.splice(droppedItem.destination.index, 0, readingOrder.current.splice(droppedItem.source.index, 1)[0]);
        requestAnimationFrame(drawing);
        setRenderI(prevState => prevState + 1);
    };

    function updateReadingOrder() {
        if (readingOrder.current != null) {
            let correction_i = 0;
            readingOrder.current.forEach((elem, i) => {
                if (elem.artifact) {
                    changeTagType({
                        structTree: structTree.current.structTree,
                        selectedStructElem: elem,
                        modifications: modifications.current,
                        newType: "Artifact"
                    });
                    correction_i += 1;
                }
                else {
                    modifications.current.push(moveTag({id: elem.id, parentId: null, listPosition: i - correction_i}));
                }
            });
        }
    }

    function changeArtifact(item) {
        item.artifact = !item.artifact;
        setRenderI(prevState => prevState + 1);
    }


    return (
        <div className="d-flex flex-row flex-fill" id="readingOrder"
             onMouseUp={() => startResizeValueMenu.current = null}
             onMouseMove={(e) => resizingX(e, startResizeValueMenu.current, setMenuSize)}
             style={startResizeValueMenu.current != null ? {userSelect: 'none'}: null}>
            <WarningNotComplete
                showWarning={showWarning} setShowWarning={setShowWarning}
                titleMessage={"Have you checked the reading order on all pages?"}
                bodyMessage={<>It seems that you have not checked the reading order on the following pages: {viewed.current.map((e, ei) => e ? null : ei + 1).filter(e => e != null).join(", ")} <br /> Do you want to check them before you continue?</>}
                currentTask={2}
                setTask={setStep}
                taskSelected={stepSelected}/>
            <div className="d-flex flex-column" id="menu" style={{width: menuSize + "px"}}
                 onMouseUp={() => startResizeValueInstruction.current = null}
                 onMouseMove={(e) => resizingY(e, startResizeValueInstruction.current, setInstructionSize)}>
                <div id="resizeHandleX" onMouseDown={(e) => startResizeValueMenu.current = startResizeX(e)}></div>
                <Instructions
                    title="Step 2: Define Reading Order"
                    bodyText="In this step, you can adjust the reading order for each page in the entire document.
                    The reading order sets the sequence in which a document's content is read by the screen reader.
                    The reading order is visualized on the right in the page view or below as a list.
                    You can modify the reading order, by drawing the reading order in the page view or by moving the regions in the list below."
                    buttons={[["Draw Reading Order", "Draw the reading order as a line on the page view."]]}
                    hints={[["Complete wrong reading order", "Draw the reading first and move single regions in the list if needed."],
                        ["Few regions wrong in the reading order", "Use the list below to move the wrong regions."],
                        ["Artefact", "Uncheck the checkbox is read aloud by screen reader to remove it from the reading flow."]]}
                    nextStep={nextStep}
                    instructionSize={instructionSize}
                    startResizeValueInstruction={startResizeValueInstruction}
                />
                {navigation({
                    callback: changePage,
                    length: pdfInfo.numberOfPages,
                    i: pageNum.current - 1,
                    title: "Pages",
                    viewed: viewed.current
                })}
                <div id="actionButtons" className="d-flex flex-row justify-content-evenly">
                    <Button onClick={() => detectReadingOrder()} id="drawReadingOrderButton" variant="light">
                        <MdOutlineAutoFixHigh size="1.5em"/><br/>
                        Detect Reading Order
                    </Button>
                    <Button onClick={() => resetReadingOrder()} id="drawReadingOrderButton" variant="light">
                        <MdOutlineDraw size="1.5em"/><br/>
                        Draw Reading Order
                    </Button>
                </div>
                <div id="readingOrderList" className="d-flex flex-column">
                    <div className="d-flex flex-row">
                        <h3 className="flex-fill">Reading Order</h3>
                        <p id="readAloudByScreenReader">is read aloud by <br/> screen reader</p>
                    </div>
                    <div id="readingOrderView">
                        <DragDropContext onDragEnd={handleDrop}>
                            <Droppable droppableId="list-container">
                                {(provided) => (
                                    <div
                                        className="list-container"
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {
                                            readingOrder.current.map((item, index) => (
                                                <Draggable key={String(item.id)} draggableId={String(item.id)}
                                                           index={index}>
                                                    {(provided) => (
                                                        <div
                                                            className="item-container"
                                                            ref={provided.innerRef}
                                                            {...provided.dragHandleProps}
                                                            {...provided.draggableProps}
                                                        >
                                                            <div id="readingOrderItem" style={item.artifact ? {
                                                                borderColor: colors["Artifact"],
                                                                backgroundColor: readingOrderConfig.artifactColor
                                                            } : {borderColor: colors[item.type]}} onMouseEnter={() => {
                                                                elementHighlighted.current = index;
                                                                requestAnimationFrame(drawing);
                                                            }} onMouseLeave={() => {
                                                                elementHighlighted.current = false;
                                                                requestAnimationFrame(drawing);
                                                            }} className="d-flex flex-row align-items-center">
                                                                {index + 1}:
                                                                <b>{tagTypes[item.type]}</b>
                                                                <div className="flex-fill" id="readingOrderText">
                                                                    {item.text.slice(0, 50)}
                                                                </div>
                                                                <OverlayTrigger
                                                                    placement="right"
                                                                    overlay={
                                                                        <Popover>
                                                                            <PopoverHeader>
                                                                                {item.artifact ? "Undo Artifact" : "Make Artifact"}
                                                                            </PopoverHeader>
                                                                            <PopoverBody>
                                                                                {item.artifact ? "Is read aloud with screen reader" : "Not read aloud with screen reader"}
                                                                            </PopoverBody>
                                                                        </Popover>
                                                                    }>
                                                                    <Form>
                                                                        <Form.Check type="checkbox"
                                                                                    onChange={() => changeArtifact(item)}
                                                                                    defaultChecked={true}/>
                                                                    </Form>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </div>
            </div>
            <PageView
                pageNum={pageNum.current}
                canvasRef={canvasRef}
                onMouseDown={onMouseDown}
                onMouseMove={onMouseMove}
                onMouseUp={onMouseUp}
                showLabels={showLabels}
                setShowLabels={setShowLabels}
                zoomFactor={zoomFactor}
                setZoomFactor={setZoomFactor}
                isLoading={structTreeMutation.isLoading || pageImage.isLoading}
                showPageMenu={showPageMenu}
                setShowPageMenu={setShowPageMenu}
                pdf={pdf}
                pdfInfo={pdfInfo}
                updateStep={updateStep}
            />
        </div>
    );
};

export default ReadingOrder;
