import React, {useEffect, useRef, useState} from 'react';
import {getPageImagePdfJs, getTagType, updateTags} from "./APIcalls";
import {useQuery, useMutation, useQueryClient} from "react-query";
import Form from 'react-bootstrap/Form';
import {changeTagType, imageCropper, navigation} from "./Tools";
import {updateAltText} from "./StructTreeActions";
import {imageScaling} from "./Config";
import {drawFigures, loadingCanvas} from "./Drawing";
import Instructions from "./utils/Instructions";
import {errorDrawing, WarningNotComplete} from "./utils/ErrorMessages";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import {PopoverBody, PopoverHeader} from "react-bootstrap";
import {resizingX, resizingY, startResizeX} from "./utils/UtilsResize";
import PageView from "./PageView";

const Figures = ({pdf, pdfInfo, stepSelected, setStep, nextStep, menuSize, setMenuSize, instructionSize, setInstructionSize, showPageMenu, setShowPageMenu}) => {

    const figures = useRef([]);
    const [figureI, setFigureI] = useState(-1);
    const pageNum = useRef(1);
    const [imageReady, setImageReady] = useState(true);
    const modifications = useRef([]);
    const canvasRef = useRef(null);
    const ctx = useRef(null);

    const queryClient = useQueryClient();

    const viewed = useRef([true]);
    const [wordCount, setWordCount] = useState(0);
    const [showWarning, setShowWarning] = useState(false);
    const [isArtifact, setIsArtifact] = useState(false);

    const [zoomFactor, setZoomFactor] = useState(1);

    // resize start values
    const startResizeValueMenu = useRef(null);
    const startResizeValueInstruction = useRef(null);

    // changing step
    useEffect(() => {
        if (stepSelected !== -1) {
            const checkedAll = viewed.current.every(t => t || stepSelected < 6);
            updateStep(() => {
                queryClient.invalidateQueries(["Figures", pdfInfo.fileid]).then(() => {
                    if (checkedAll) setStep(stepSelected);
                    else setShowWarning(true);
                });
            });
        }
    }, [stepSelected]);

    // init canvas
    useEffect(() => {
        if (canvasRef.current) {
            ctx.current = canvasRef.current.getContext('2d');
        }
        figuresQuery.refetch();
        viewed.current[figureI] = true;
    }, []);

    // get the figures
    const figuresQuery = useQuery({
        queryKey: ["Figures", pdfInfo.fileid],
        queryFn: () => getTagType({pdfInfo: pdfInfo, tagType: "Figure", pdf: pdf}),
        staleTime: 1000 * 60 * 5, // less fetching,
        enabled: pdfInfo.fileid != null,
        placeholderData: null,  // placeholder image
        onSuccess: data => {
            figures.current = data;
            if (figures.current.length !== 0) {
                pageNum.current = figures.current[0].rectangle.page;
                if (viewed.current.length === 1) {
                    viewed.current = Array.from({length: figures.current.length}, _ => false);
                }
                viewed.current[0] = true;
                setWordCount(figures.current[0].altText.split(" ").filter(w => w !== "").length);
                setFigureI(0);
            }
        }
    });

    // get the image
    const pageImage = useQuery({
        queryKey: ["image", pdfInfo.fileid, pageNum.current],
        queryFn: () => getPageImagePdfJs({pdf: pdf, pageNum: pageNum.current, setImageReady: setImageReady}),
        staleTime: 1000 * 60 * 5, // less fetching,
        enabled: pdfInfo.fileid != null && pageNum.current > -1,
        placeholderData: null,  // placeholder image
    });

    // update tables
    const figuresMutation = useMutation({
        mutationFn: ({pdfInfo, modifications}) => updateTags({pdfInfo: pdfInfo, modifications: modifications})
    })

    function changeFigureI(i) {
        if (i !== figureI) {
            updateStep();
            pageNum.current = figures.current[i].rectangle.page;
            setFigureI(i);
            setWordCount(figures.current[i].altText.split(" ").filter(w => w !== "").length);
            if (figures.current[i].isArtifact) setIsArtifact(true);
            else setIsArtifact(false);
        }
    }

    /**
     * Helper function for updating the step
     */
    function updateStep(onSuccessFunction) {
        if (document.getElementById('figureAltText-' + figureI) == null) {
            onSuccessFunction();
            return;
        }
        const altText = document.getElementById('figureAltText-' + figureI).value;
        if (altText !== figures.current[figureI].altText) {
            if (!figures.current[figureI].isArtifact) modifications.current.push(updateAltText({
                id: figures.current[figureI].id,
                altText: altText
            }));
            figures.current[figureI].altText = altText;
        }
        return figuresMutation.mutate({pdfInfo: pdfInfo, modifications: modifications.current}, {
            onSuccess: () => {
                if (onSuccessFunction) {
                    return onSuccessFunction();
                }
            }
        });
    }


    /**
     * helper function for drawing
     */
    function drawing() {
        if (imageReady  && ctx.current != null && pageImage.data != null && !figuresQuery.isLoading) {
            try {
                canvasRef.current.height = pageImage.data.naturalHeight;
                canvasRef.current.width = pageImage.data.naturalWidth;
                drawFigures({ctx: ctx.current, figure: figures.current[figureI], image: pageImage.data});
            }
            catch (e) {
                errorDrawing({errorMessage: e});
            }
        }
        else {
            loadingCanvas({ctx: ctx.current, canvasRef: canvasRef.current});
        }
    }

    drawing();

    function showFigure() {
        drawing();
        // if there are figures
        if (imageReady  && ctx.current != null && pageImage.data != null && !figuresQuery.isLoading) {
            return <div className="d-flex flex-column" id="figure">
                {figures.current.length > 0 ? <>
                <h3>Figure {figureI + 1}/{figures.current.length}</h3>
                <div id="figureDiv">
                    <img src={imageCropper({
                        image: pageImage.data,
                        border: 5,
                        rectangle: {
                            x: figures.current[figureI].rectangle.llx * imageScaling,
                            y: pageImage.data.naturalHeight - figures.current[figureI].rectangle.ury * imageScaling,
                            w: figures.current[figureI].rectangle.urx * imageScaling - figures.current[figureI].rectangle.llx * imageScaling,
                            h: figures.current[figureI].rectangle.ury * imageScaling - figures.current[figureI].rectangle.lly * imageScaling
                        },
                        resize: 1})}/>
                </div>
                <Form className="flex-column" id="alternativeTextInput">
                    <Form.Group key={'figureAltText-' + figureI} controlId={'figureAltText-' + figureI}>
                        <Form.Label className="d-flex flex-row" id="altTextTitle">Alternative Text (<p
                            id={(50 - wordCount) > 10 ? "textLengthOk" : (50 - wordCount) > 0 ? "textLengthWarning" : "textLengthError"}>{(50 - wordCount)}</p> words
                            left)</Form.Label>
                        <Form.Control key={'figureAltText-' + figureI} type="alternative text" as="textarea" rows={5}
                                      defaultValue={figures.current[figureI].altText}
                                      onChange={(e) => setWordCount(e.target.value.split(" ").filter(w => w !== "").length)}
                                      disabled={isArtifact}/>
                    </Form.Group>
                </Form>
                <OverlayTrigger
                    placement="right"
                    overlay={<Popover>
                        <PopoverHeader>{isArtifact ? <>Undo Artifact</> : <>Make Artifact</>}</PopoverHeader>
                        <PopoverBody>
                            {isArtifact ? <>Read aloud with screen reader</> : <>Not read aloud with screen reader</>}
                        </PopoverBody>
                    </Popover>
                    }>
                    <Form>
                        <Form.Check type="checkbox" label={<>is decorative</>} onChange={(e) => {
                            if (e.target.checked) {
                                figures.current[figureI].isArtifact = true;
                                changeTagType({
                                    structTree: figures.current,
                                    selectedStructElem: figures.current[figureI],
                                    modifications: modifications.current,
                                    newType: "Artifact"
                                });
                                setIsArtifact(true);
                            } else {
                                figures.current[figureI].isArtifact = false;
                                changeTagType({
                                    structTree: figures.current,
                                    selectedStructElem: figures.current[figureI],
                                    modifications: modifications.current,
                                    newType: "Figure"
                                });
                                setIsArtifact(false);
                            }
                        }} checked={isArtifact}>
                        </Form.Check>
                    </Form>
                </OverlayTrigger>
                </> : <h3>No figures found in the document</h3>}
            </div>
        }
    }

    return (
        <div className="d-flex flex-row flex-fill" id="figures"
             onMouseUp={() => startResizeValueMenu.current = null}
             onMouseMove={(e) => resizingX(e, startResizeValueMenu.current, setMenuSize)}
             style={startResizeValueMenu.current != null ? {userSelect: 'none'}: null}>
            <WarningNotComplete
                showWarning={showWarning}
                setShowWarning={setShowWarning}
                titleMessage={"Have you checked the all figures?"}
                bodyMessage={<>It seems that you have not checked the following figures: {viewed.current.map((e, ei) => e ? null : ei + 1).filter(e => e != null).join(", ")} <br /> Do you want to check them before you continue?</>}
                currentTask={6}
                setTask={setStep}
                taskSelected={stepSelected}
            />
            <div className="d-flex flex-column" id="menu" style={{width: menuSize + "px"}}
                 onMouseUp={() => startResizeValueInstruction.current = null}
                 onMouseMove={(e) => resizingY(e, startResizeValueInstruction.current, setInstructionSize)}>
                <div id="resizeHandleX" onMouseDown={(e) => startResizeValueMenu.current = startResizeX(e)}></div>
                <Instructions
                    title="Step 6: Edit Alternative Text of Figures"
                    bodyText="In this step, you can modify the alternative text for all figures in your document. An alternative text should not exceed 50 words."
                    hints={[["Artefact", "If a figure is only decorative select is decorative."]]}
                    nextStep={nextStep}
                    instructionSize={instructionSize}
                    startResizeValueInstruction={startResizeValueInstruction}
                />
                {navigation({
                    callback: changeFigureI,
                    length: figures.current.length,
                    i: figureI,
                    title: "Figures",
                    viewed: viewed.current
                })}
                {showFigure()}
            </div>
            <PageView
                pageNum={pageNum.current}
                canvasRef={canvasRef}
                zoomFactor={zoomFactor}
                setZoomFactor={setZoomFactor}
                isLoading={figuresQuery.isLoading || pageImage.isLoading}
                showPageMenu={showPageMenu}
                setShowPageMenu={setShowPageMenu}
                pdf={pdf}
                pdfInfo={pdfInfo}
                updateStep={updateStep}
            />
        </div>
    );
};

export default Figures;
