import {transformRectangle, mode} from "../Tools";
import {newTag} from "../StructTreeActions";

function getTableDimensions({table, imageSize}) {
    let rowDimensions = [];
    let columnDimensions = [];
    table.processedTable.forEach((row, rowI) => {
        rowDimensions.push({top: 9999999, bottom: 0});
        row.forEach((column, columnI) => {
            if (columnDimensions[columnI] == null) columnDimensions[columnI] = {left: 9999999, right: 0};
            column.elements.forEach(e => {
                const rect = transformRectangle({rect: e.rectangle, imageSize: imageSize});
                if (rect.x < columnDimensions[columnI].left) columnDimensions[columnI].left = rect.x;
                if (rect.x + rect.w > columnDimensions[columnI].right) columnDimensions[columnI].right = rect.x + rect.w;
                if (rect.y < rowDimensions[rowI].top) rowDimensions[rowI].top = rect.y;
                if (rect.y + rect.h > rowDimensions[rowI].bottom) rowDimensions[rowI].bottom = rect.y + rect.h;
            })
        })
    })
    return [rowDimensions, columnDimensions];
}

function fixTableDimensions(table) {
    let tableRowMax = 0;
    table.forEach(row => {
        if (tableRowMax < row.length) tableRowMax = row.length;
    });
    return table.map(row => {
        if (tableRowMax > row.length) {
            const type = row[row.length - 1].type;
            while (row.length < tableRowMax) {
                row.push({elements:[], type:type});
            }
        }
        return row;
    });
}

function prepareTable({tableArray, elements, modifications}) {
    let tableCell = [];
    let tableRow = [];
    let artifacts = [];
    elements.forEach(element => {
        if ((element.text === "f*" || element.text === " " || element.text === "SOperator") && element.type === "MCRef") {
            artifacts.push({id: element.id});
            return;
        }
        if (element.type === "TR") {
            if (tableRow.length > 0) {
                tableArray.push(tableRow);
                tableRow = [];
            }
            tableArray.push(prepareTable({tableArray: [], elements: element.children, modifications: modifications})[0])
        }
        else if (element.type === "TD") {
            if (tableCell.length > 0) {
                tableRow.push({type: "TD", elements: tableCell});
                tableCell = [];
            }
            tableRow.push({type: "TD", elements: element.children})
        }
        else if (element.type === "TH") {
            if (tableCell.length > 0) {
                tableRow.push({type: "TD", elements: tableCell});
                tableCell = [];
            }
            tableRow.push({type: "TH", elements: element.children})
        }
        else {
            tableCell.push(element)
        }
    })
    if (tableCell.length > 0) {
        tableRow.push({type: "TD", elements: tableCell});
    }
    if (tableRow.length > 0) {
        tableArray.push(tableRow);
    }
    if (artifacts.length > 0) {
        newTag({elem: {type: "Artifact", children: artifacts}, modifications: modifications, structTree: []});
    }
    if (mode(tableArray.map(column => column.type)) !== "TH" && mode(tableArray.map(column => column[0].type)) !== "TH") {
        tableArray.forEach(column => column.type = "TH");
    }
    return tableArray;
}

function checkHeaderCells({processedTable}) {
    let rowHeader = mode(processedTable[0].map(column => column.type));
    let columnHeader = mode(processedTable.map(column => column[0].type));
    processedTable.forEach((row, rowI) => {
        row.forEach((column, columnI) => {
            if ((rowI === 0 && rowHeader === "TH") || (columnI === 0 && columnHeader === "TH")) {
                column.type = "TH";
            }
            else {
                column.type = "TD";
            }
        });
    });
}

export {getTableDimensions, prepareTable, checkHeaderCells, fixTableDimensions};
