function newTag({elem, modifications, structTree}) {
    modifications.push({
        type: "NEW_STRUCT_ACTION",
        entries: {
            "S": {
                "type": 4,
                "data": elem.type
            }
        },
        annotation: null,
        tagType: null,
        selection: [elem.children.map(o => o.id)],
        position: {"listPosition":  null, "parentId":  null}
    });
    structTree.push(elem)
}

function updateTagChildren({elem, newChildren, modifications, structTree}) {
    // elem has no id
    if (elem.id === null) {
        // find elem and change the children
        modifications.some(mod => {
            if (mod.type === "NEW_STRUCT_ACTION") {
                if (mod.selection.toString() === elem.children.map(o => o.id).toString()) {
                    mod.selection = [newChildren.map(o => o.id)];
                    return true;
                }
            }
            return false;
        })
    }
    // elem has id
    else {
        elem.children.forEach(child => {
            if (child.type !== "MCRef") {
                modifications.push(moveTag({id: child.id, listPosition: null, parentId: null}));
                structTree.push(child);
            }
        })
        modifications.push({type: "DELETE_STRUCT_ACTION", id: elem.id});
        modifications.push({
            type: "NEW_STRUCT_ACTION",
            entries: {
                "S": {
                    "type": 4,
                    "data": elem.type
                }
            },
            annotation: null,
            tagType: null,
            selection: [newChildren.map(o => o.id)],
            position: {"listPosition": 1, "parentId": null}
        });
    }
    // update struct tree
    structTree.some((e, i) => {
        if (e === elem) {
            e.children = newChildren;
            e.text = "";
            e.rectangle = {llx: 99999, lly: 99999, urx: -1, ury: -1};
            newChildren.forEach(o => {
                e.text += o.text;
                if (e.rectangle.llx > o.rectangle.llx) e.rectangle.llx = o.rectangle.llx;
                if (e.rectangle.urx < o.rectangle.urx) e.rectangle.urx = o.rectangle.urx;
                if (e.rectangle.lly > o.rectangle.lly) e.rectangle.lly = o.rectangle.lly;
                if (e.rectangle.ury < o.rectangle.ury) e.rectangle.ury = o.rectangle.ury;
            })
            e.id = null;
            return true;
        }
        return false;
    })
}

function updateTagType({id, newType}) {
    return {
            type: "CHANGE_STRUCT_ACTION",
            entries: {
                "S": {
                    "type": 4,
                    "data": newType
                }
            },
            id: id,
            overwrite: false,
            annotation: null
        };
}

function updateAltText({id, altText}) {
    return {
        type: "CHANGE_ALTTEXT_ACTION",
        altText: altText,
        id: id
    };
}

function deleteTag({structTree, elem, modifications}) {
    structTree.forEach((structElem, index) => {
        if (structElem.type === "MCRef") {
            return;
        }
        if (structElem.children.map(o => o.id).toString() === elem.children.map(o => o.id).toString()) {
            if (elem.id !== null) {
                modifications.push({type: "DELETE_STRUCT_ACTION", id: elem.id});
            }
            else {
                modifications = modifications.filter((mod) => {
                    if (mod.type === "NEW_STRUCT_ACTION") {
                        return !(mod.selection.toString() === elem.children.map(o => o.id).toString());
                    }
                    return true;
                });
            }
            delete structTree[index];
        }
        else if (structElem.children !== null) {
            modifications = deleteTag({structTree: structElem.children, elem: elem, modifications: modifications})
        }
    })
    return modifications;
}

function moveTag({id, parentId, listPosition}) {
    return {
        "type": "REORDER_ACTION",
        "newPos": {
            "listPosition": listPosition,
            "parentId": parentId
        },
        "idToMove": id
    }
}

function changeTable({id, rows, columns, cellIds, cellTypes}) {
    return {
        "type": "CHANGE_TABLE_ACTION",
        "id": id,
        "rows": rows,
        "columns": columns,
        "cellIds": cellIds,
        "cellTypes": cellTypes,
    }
}

function prepareList({list}) {
    let preparedList = []
    if (list == null) {
        return preparedList;
    }
    list.children.forEach(li => {
        let listItem = {span: [[]], lbl: [[]], body: [[]], sublist: []}
        li.children.forEach(liE => {
            if (liE.type === "Span") {
                listItem.span[0] = listItem.span[0].concat(liE.children.filter(e => e.type !== "L").map(e => e.id));
            }
            if (liE.type === "Lbl") {
                listItem.lbl[0] = listItem.lbl[0].concat(liE.children.filter(e => e.type !== "L").map(e => e.id));
            }
            if (liE.type === "LBody") {
                listItem.body[0] = listItem.body[0].concat(liE.children.filter(e => e.type !== "L").map(e => e.id));
                listItem.sublist = prepareList({list: liE.children.filter(e => e.type === "L")[0]});
            }
        });
        preparedList.push(listItem);
    });
    return preparedList;
}

function changeList({list}) {
    return {
        "type": "CHANGE_LIST_ACTION",
        "id": list.id,
        "list": prepareList({list: list})
    }
}


export {newTag, updateTagType, deleteTag, moveTag, updateTagChildren, changeTable, updateAltText, changeList}
